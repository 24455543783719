<template>
  <div class="dropdownbutton">
    <ejs-dropdownbutton
      v-bind="dropdownButtonProps"
      @select="onDropdownButtonSelect"
    />
  </div>
</template>

<script>
export default {
  name: "EjsGridDropdownButtonColumn",
  props: {
    dropdownButtonItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      data: {},
    };
  },
  computed: {
    dropdownButtonProps() {
      return {
        items: this.dropdownButtonItems,
      };
    },
  },
  methods: {
    onDropdownButtonSelect(args) {
      this.$emit("select", args);
    },
  },
};
</script>

<style scoped></style>
