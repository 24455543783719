<template>
  <div>
    <ejs-dialog ref="dialog" v-bind="dialogProps" @close="onDialogClose">
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-main">
          <ejs-grid-wrapper
            ref="billListGrid"
            v-bind="gridProps"
            @queryCellInfo="queryCellInfo"
            @onGridDialogDoubleClickedOrEnterKeyed="onSlipsGridDialogDoubleClickedOrEnterKeyed"
          />
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {flex: 1; overflow: hidden;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
</style>

<script>
import { sumBy as _sumBy, maxBy as _maxBy } from "lodash";
import GolfERPService from "@/service/GolfERPService";
import moment from "moment";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import { flatten as _flatten } from "lodash";
import { TIME_FORMAT_hh_mm, TIME_FORMAT_hh_mm_ss } from "@/utils/time";
import {Aggregate, ForeignKey} from '@syncfusion/ej2-vue-grids';
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import Vue from 'vue';
import {numberWithCommas} from '@/utils/number';
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "BillListPopup",
  components: { EjsGridWrapper, ErpButton },
  props: {
    salesDate: {
      type: Date,
      required: true,
    },
    store: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visitor: {},
      bills: [],
    };
  },
  computed: {
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "Bills",
        width: 1160,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    gridProps() {
      const dataSource = _flatten(
        this.bills.map(({ id, number, storeTableUses, sales, isPartisionRow }) =>
          sales.map(
            ({
              productName,
              quantity,
              totalAmount,
              insertDateTime,
              insertUser,
            }, index) => ({
              id,
              number,
              tableName:
                _maxBy(storeTableUses || [], "insertDateTime")?.storeTable
                  ?.name || "N/A",
              productName,
              quantity,
              totalAmount,
              insertTime: insertDateTime
                ? moment(
                    insertDateTime,
                    `${DATE_FORMAT_YYYY_MM_DD} ${TIME_FORMAT_hh_mm_ss}`
                  ).format(TIME_FORMAT_hh_mm)
                : "",
              insertName: insertUser?.name || "",
              isPartisionRow: isPartisionRow && index < 1,
            })
          )
        )
      ).map((record, index) => ({ ...record, _rid: index + 1 }));

      return {
        aggregates: [
          {
            columns: [
              {
                field: "tableName",
                type: "Custom",
                footerTemplate() {
                  return {
                    template: Vue.component("footerTemplate", {
                      template: `<div style="text-align: center">합계</div>`,
                    }),
                  };
                },
              },
              {
                field: "quantity",
                type: "Custom",
                customAggregate: (data) => {
                  return _sumBy(
                      data.result,
                      ({ quantity = 0, deleted = false }) =>
                          deleted ? 0 : quantity
                  );
                },
                footerTemplate() {
                  return {
                    template: Vue.component("footerTemplate", {
                      template: `
                        <div style="text-align: right">{{ data.Custom | numberWithCommas }}</div>`,
                      filters: {
                        numberWithCommas,
                      },
                      data() {
                        return {
                          data: {},
                        };
                      },
                    }),
                  };
                },
              },
              {
                field: "totalAmount",
                type: "Custom",
                customAggregate: (data) => {
                  return _sumBy(
                      data.result,
                      ({ totalAmount = 0, deleted = false }) =>
                          deleted ? 0 : totalAmount
                  );
                },
                footerTemplate() {
                  return {
                    template: Vue.component("footerTemplate", {
                      template: `
                        <div style="text-align: right">{{ data.Custom | numberWithCommas }}</div>`,
                      filters: {
                        numberWithCommas,
                      },
                      data() {
                        return {
                          data: {},
                        };
                      },
                    }),
                  };
                },
              },
            ],
          },
        ],
        allowFiltering: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: [
          {
            field: "order",
            visible: false,
          },
          {
            field: "id",
            visible: false,
          },
          {
            allowEditing: false,
            field: "number",
            headerText: "전표번호",
            textAlign: "Center",
            width: 127,
          },
          {
            allowEditing: false,
            field: "tableName",
            headerText: "테이블",
            textAlign: "Center",
            width: 115,
          },
          {
            allowEditing: false,
            field: "productName",
            headerText: "상품명",
            textAlign: "Left",
            width: 115,
          },
          {
            allowEditing: false,
            field: "quantity",
            headerText: "수량",
            textAlign: "Center",
            type: "number",
            width: 104,
          },
          {
            allowEditing: false,
            field: "totalAmount",
            headerText: "금액",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
            width: 104,
          },
          {
            allowEditing: false,
            field: "insertTime",
            headerText: "시간",
            textAlign: "Center",
            width: 104,
          },
          {
            allowEditing: false,
            field: "insertName",
            headerText: "입력자",
            textAlign: "Left",
            width: 115,
          },
        ],
        dataSource,
        isInPopup: true,
        provides: [Aggregate, ForeignKey],
        useCommonStyles: false,
      };
    },
  },
  methods: {
    show({ visitor = {} } = {}) {
      this.visitor = visitor;
      this.fetchVisitorBills();
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onCloseClick() {
      this.hide();
    },
    queryCellInfo(args) {
      const {
        cell,
        data,
      } = args;
      if (data.isPartisionRow) {
        cell.style.borderTop = '1px solid #000';
      }
    },
    onSlipsGridDialogDoubleClickedOrEnterKeyed() {
      const [record] = this.$refs["billListGrid"].getSelectedRecords();
      if (record) {
        const slip = this.bills.find((bill) => bill.number === record.number);
        if (slip) {
          this.$emit("submit", { slip: { id: slip.id } });
          this.hide();
        }
      }
    },
    async fetchVisitorBills() {
      let beforeNumber = null;
      this.bills =
        (await GolfERPService.fetchVisitorBills(
          moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
          this.store.code || undefined,
          this.visitor.id,
          "G"
        )).map(item => {
          if (beforeNumber !== item.number) {
            item.isPartisionRow = !!beforeNumber;
            beforeNumber = item.number;
          }
          return item;
        }) || [];
    },
  },
};
</script>
