<template>
  <div>
    <ejs-dialog
        :animationSettings="{ effect: 'None' }"
        ref="dialog"
        v-bind="dialogProps"
        @close="onDialogClose"
    >
      <div
          class="typePos"
          :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-header">
          <div class="typePos-body">

          </div>
        </div>
        <div class="typePos-wrapper-main">
          <div class="typePos-main-box typePos-select">
            <ul class="typePos-body">
              <li>
                <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    @click="onApplyClick('ALL')"
                >
                  <div class="i-item">
                    전체 적용
                  </div>
                </erp-button>
              </li>
              <li>
                <erp-button
                    button-div="SAVE"
                    class="ui-button"
                    :use-syncfusion-component-style="false"
                    @click="onApplyClick('INDIV')"
                >
                  <div class="i-item">
                    개별 적용
                  </div>
                </erp-button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-header .typePos-body {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-header .typePos-body::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-header .typePos-body > li {position: relative; float: left; width: 50%; padding: 14px 0; color: #aaa; font-size: 16px; font-family: 'NanumSquare-Bold'; line-height: 24px; text-align: center; cursor: pointer;}
.typePos .typePos-wrapper-header .typePos-body > li:hover {color: #18b3c9;}
.typePos .typePos-wrapper-header .typePos-body > li::before {display: none; position: absolute; bottom: -1px; left: 0; width: 100%; height: 3px; background-color: #18b3c9; content: '';}
.typePos .typePos-wrapper-header .typePos-body > li.typePos-select {color: #18b3c9; cursor: default;}
.typePos .typePos-wrapper-header .typePos-body > li.typePos-select:hover {color: #18b3c9;}
.typePos .typePos-wrapper-header .typePos-body > li.typePos-select::before {display: block;}

.typePos .typePos-wrapper-main {display: flex; flex-direction: column; flex: 1; overflow: hidden;}
.typePos .typePos-wrapper-main .typePos-main-box {height: 100%;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-select {overflow: auto;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-select .typePos-body {list-style: none; padding: 15px 20px; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-select .typePos-body > li {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-select .typePos-body > li .ui-button {width: 100%; padding-top: 12px; padding-bottom: 12px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-select .typePos-body > li .ui-button .i-item {min-height: 24px; padding-left: 0; font-size: 16px; font-family: 'NanumSquare-Bold'; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-select .typePos-body > li .ui-button .i-item::before {display: none;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input {display: none;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-header {padding: 15px 20px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-header .typePos-filed {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-header .typePos-filed >>> .ejs-number input {width: 100%;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body {position: relative; width: 325px; height: 278px; border-top: 1px solid #e0e0e0; background-color: #f5f5f5;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li {position: absolute;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li button {display: block; box-sizing: border-box; box-shadow: 0 2px 2px 0 rgba(0, 0 , 0, 0.05); width: 72px; height: 45px; padding: 5px; margin: 0; border: none; border-radius: 4px; background-color: #fff; color: #666; font-size: 16px; font-family: 'NanumSquare-Bold'; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li button:hover {background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-1 {top: 116px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-2 {top: 116px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-3 {top: 116px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-4 {top: 68px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-5 {top: 68px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-6 {top: 68px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-7 {top: 20px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-8 {top: 20px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-9 {top: 20px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-0 {top: 164px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-00 {top: 164px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-clear {top: 164px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-back {top: 212px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-enter {top: 212px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-input {top: 20px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-lookup {top: 116px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-clear button {font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-back button {overflow: hidden; text-indent: -1000px; background-image: url('../../../../../assets/images/ui/icon-type09.png'); background-repeat: no-repeat; background-position: center center;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-enter button {width: 147px; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-input button {width: 60px; height: 93px; background-color: #4f5963; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-input button:hover {background-color: #333c44;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-lookup button {width: 60px; height: 141px; background-color: #18b3c9; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-lookup button:hover {background-color: #0f9eb8;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}

.typePos.typePos-input .typePos-wrapper-header .typePos-body > li.typePos-select {color: #aaa; cursor: pointer;}
.typePos.typePos-input .typePos-wrapper-header .typePos-body > li.typePos-select:hover {color: #18b3c9;}
.typePos.typePos-input .typePos-wrapper-header .typePos-body > li.typePos-select::before {display: none;}
.typePos.typePos-input .typePos-wrapper-header .typePos-body > li.typePos-input {color: #18b3c9; cursor: default;}
.typePos.typePos-input .typePos-wrapper-header .typePos-body > li.typePos-input:hover {color: #18b3c9;}
.typePos.typePos-input .typePos-wrapper-header .typePos-body > li.typePos-input::before {display: block;}
.typePos.typePos-input .typePos-wrapper-main .typePos-main-box.typePos-select {display: none;}
.typePos.typePos-input .typePos-wrapper-main .typePos-main-box.typePos-input {display: block;}
.select-button { border: 2px solid skyblue !important; }
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { numberWithCommas } from "@/utils/number";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "SettingApplySelectionPopup",
  components: {
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  data() {
    return {
      priceUnit:null,
    };
  },
  computed: {
    dialogProps() {
      return {
        header: "적용 구분",
        animationSettings: { effect: "None" },
        isModal: true,
        width: 300,
        height: 230,
        position: { x: "center", y: "center" },
        visible: false,
        allowDragging: true,
        showCloseIcon: true,
      };
    },
  },
  methods: {
    async show(priceUnit) {
      this.priceUnit = priceUnit;
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onApplyClick(div) {
      this.$emit("selectApply",this.priceUnit,div);
    },
    onCloseClick() {
      this.hide();
    },
  },
};
</script>
