<template>
  <div>
    <ejs-dialog ref="dialog" v-bind="dialogProps" @close="onDialogClose">
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
        }"
      >
        <div class="typePos-wrapper-header">
          <ul class="typePos-list" v-if="popupData.payDiv === 'CARD' || popupData.payDiv === 'EASY_PAY'">
            <li>
              <div class="typePos-title">거래종류</div>
              <div class="typePos-content">신용취소</div>
            </li>
            <li>
              <div class="typePos-title">영업일자</div>
              <div class="typePos-content">
                {{ popupData.payDate }}
              </div>
            </li>
            <li>
              <div class="typePos-title">취소일자</div>
              <div class="typePos-content">
                {{ today }}
              </div>
            </li>
            <li>
              <div class="typePos-title">카드번호</div>
              <div class="typePos-content">
                {{ payCardInfo.cardNo }}
              </div>
            </li>
            <li>
              <div class="typePos-title">카드회사</div>
              <div class="typePos-content">
                {{ payCardInfo.purcName }}
              </div>
            </li>
            <li>
              <div class="typePos-title">거래금액</div>
              <div class="typePos-content">
                {{ popupData.payAmt | numberWithCommas }}
              </div>
            </li>
            <li>
              <div class="typePos-title">할부개월</div>
              <div class="typePos-content">
                {{ payCardInfo.divideTerm }}
              </div>
            </li>
            <li>
              <div class="typePos-title">승인번호</div>
              <div class="typePos-content">
                {{ payCardInfo.approvalNo }}
              </div>
            </li>
          </ul>
          <ul
            class="typePos-list"
            v-if="
              popupData.salesDiv !== 'DEPOSIT' &&
              (popupData.payDiv === 'CASH' || popupData.payDiv === 'TRANS')
            "
          >
            <li>
              <div class="typePos-title">거래종류</div>
              <div class="typePos-content">
                {{ commonCodesGetComName("PAY_DIV", popupData.payDiv) }}취소
              </div>
            </li>
            <li>
              <div class="typePos-title">영업일자</div>
              <div class="typePos-content">
                {{ popupData.payDate }}
              </div>
            </li>
            <li>
              <div class="typePos-title">취소일자</div>
              <div class="typePos-content">
                {{ today }}
              </div>
            </li>
            <li v-if="popupData.cashId">
              <div class="typePos-title">증빙번호</div>
              <div class="typePos-content">
                {{ payCashInfo.proofNo }}
              </div>
            </li>
            <li v-if="popupData.cashId">
              <div class="typePos-title">증빙번호 확인</div>
              <div class="typePos-content proof-no-confirm">
                <input
                  type="text"
                  :disabled="payCashInfo.approvalDivision === 'T_OK' || payCashInfo.proofKind === 'VOL' || cardProof"
                  v-model="proofNo"
                />
              </div>
            </li>
            <li v-if="popupData.cashId && van === 'NICE'">
              <div class="typePos-title">카드 증빙</div>
              <div class="typePos-content">
                <input
                  type="checkbox"
                  :disabled="payCashInfo.proofKind === 'VOL'"
                  v-model="cardProof"
                  class="e-input"
                />
              </div>
            </li>
            <li v-if="popupData.cashId">
              <div class="typePos-title">거래구분</div>
              <div class="typePos-content">
                {{ proofKindName }}
              </div>
            </li>
            <li>
              <div class="typePos-title">거래금액</div>
              <div class="typePos-content">
                {{ popupData.payAmt | numberWithCommas }}
              </div>
            </li>
            <li v-if="popupData.cashId">
              <div class="typePos-title">승인번호</div>
              <div class="typePos-content">
                {{ payCashInfo.approvalNo }}
              </div>
            </li>
            <li v-if="popupData.cashId">
              <div class="typePos-title cancel-reason">취소사유</div>
              <div class="typePos-content cancel-reason">
                <div class="typePos-item">
                  <f-select
                    class="ui-select"
                    :items="proofCancelCodeList"
                    v-model="payCashProofCancelCode"
                  />
                </div>
              </div>
            </li>
          </ul>
          <ul class="typePos-list" v-if="popupData.payDiv === 'CREDIT'">
            <li>
              <div class="typePos-title">거래종류</div>
              <div class="typePos-content">미수취소</div>
            </li>
            <li>
              <div class="typePos-title">영업일자</div>
              <div class="typePos-content">
                {{ popupData.payDate }}
              </div>
            </li>
            <li>
              <div class="typePos-title">취소일자</div>
              <div class="typePos-content">
                {{ today }}
              </div>
            </li>
            <li>
              <div class="typePos-title">거래금액</div>
              <div class="typePos-content">
                {{ popupData.payAmt | numberWithCommas }}
              </div>
            </li>
          </ul>
          <ul
            class="typePos-list"
            v-if="
              popupData.payDiv === 'DEPOSIT' ||
              popupData.payDiv === 'PRECARD' ||
              popupData.payDiv === 'GIFT' ||
              popupData.payDiv === 'GROUP_GIFT'
            "
          >
            <li>
              <div class="typePos-title">거래종류</div>
              <div class="typePos-content">
                {{
                  commonCodesGetComName(
                    "PAY_DIV",
                    popupData.payDiv
                  )
                }}취소
              </div>
            </li>
            <li>
              <div class="typePos-title">영업일자</div>
              <div class="typePos-content">
                {{ popupData.payDate }}
              </div>
            </li>
            <li>
              <div class="typePos-title">취소일자</div>
              <div class="typePos-content">
                {{ today }}
              </div>
            </li>
            <li v-if="popupData.cashId">
              <div class="typePos-title">증빙번호</div>
              <div class="typePos-content">
                {{ payCashInfo.proofNo }}
              </div>
            </li>
            <li v-if="popupData.cashId">
              <div class="typePos-title">증빙번호 확인</div>
              <div class="typePos-content proof-no-confirm">
                <input
                  type="text"
                  :disabled="payCashInfo.proofKind === 'VOL' || cardProof"
                  v-model="proofNo"
                />
              </div>
            </li>
            <li v-if="popupData.cashId && van === 'NICE'">
              <div class="typePos-title">카드 증빙</div>
              <div class="typePos-content">
                <input
                  type="checkbox"
                  :disabled="payCashInfo.proofKind === 'VOL'"
                  v-model="cardProof"
                  class="e-input"
                />
              </div>
            </li>
            <li v-if="popupData.cashId">
              <div class="typePos-title">거래구분</div>
              <div class="typePos-content">
                {{ proofKindName }}
              </div>
            </li>
            <li>
              <div class="typePos-title">거래금액</div>
              <div class="typePos-content">
                {{ popupData.payAmt | numberWithCommas }}
              </div>
            </li>
            <li v-if="popupData.cashId">
              <div class="typePos-title">승인번호</div>
              <div class="typePos-content">
                {{ payCashInfo.approvalNo }}
              </div>
            </li>
            <li v-if="popupData.cashId">
              <div class="typePos-title cancel-reason">취소사유</div>
              <div class="typePos-content cancel-reason">
                <div class="typePos-item">
                  <f-select
                    class="ui-select"
                    :items="proofCancelCodeList"
                    v-model="payCashProofCancelCode"
                  />
                </div>
              </div>
            </li>
          </ul>
          <ul
            class="typePos-list"
            v-if="
              popupData.payDiv === 'RETURN' ||
              popupData.payDiv === 'YEAR' ||
              popupData.payDiv === 'ETC'
            "
          >
            <li>
              <div class="typePos-title">사용구분</div>
              <div class="typePos-content">
                {{ commonCodesGetComName("DEPOSIT_USE_DIV", popupData.payDiv) }}
              </div>
            </li>
            <li>
              <div class="typePos-title">사용일자</div>
              <div class="typePos-content">
                {{ popupData.payDate }}
              </div>
            </li>
            <li>
              <div class="typePos-title">취소일자</div>
              <div class="typePos-content">
                {{ today }}
              </div>
            </li>
            <li>
              <div class="typePos-title">거래금액</div>
              <div class="typePos-content">
                {{ popupData.payAmt | numberWithCommas }}
              </div>
            </li>
          </ul>
          <ul
            class="typePos-list"
            v-if="
              popupData.salesDiv === 'DEPOSIT' &&
              (popupData.payDiv === 'CASH' ||
                popupData.payDiv === 'PROMTN' ||
                popupData.payDiv === 'TRANS')
            "
          >
            <li>
              <div class="typePos-title">입금구분</div>
              <div class="typePos-content">
                {{ commonCodesGetComName("INCOM_DIV", popupData.payDiv) }}
              </div>
            </li>
            <li>
              <div class="typePos-title">입금일자</div>
              <div class="typePos-content">
                {{ popupData.payDate }}
              </div>
            </li>
            <li>
              <div class="typePos-title">취소일자</div>
              <div class="typePos-content">
                {{ today }}
              </div>
            </li>
            <li>
              <div class="typePos-title">거래금액</div>
              <div class="typePos-content">
                {{ popupData.payAmt | numberWithCommas }}
              </div>
            </li>
          </ul>
          <ul class="typePos-list" v-if="popupData.payDiv !== 'CREDIT'">
            <li>
              <div class="typePos-content">
                <div class="typePos-item typePos-check">
                  <ul class="ui-check">
                    <li>
                      <label>
                        <!-- 비활성(Attribute) : disabled -->
                        <input type="checkbox" v-model="printFlag" />
                        <div class="i-item">고객용 영수증 발행</div>
                      </label>
                    </li>
                    <li v-if="popupData.payDiv === 'CARD' && payCardInfo.approvalDiv === 'OK'">
                      <label>
                        <!-- 비활성(Attribute) : disabled -->
                        <input type="checkbox" v-model="telCancel" />
                        <div class="i-item">전화 취소</div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="typePos-wrapper-main">
          <div class="typePos-filed">
            <textarea
              placeholder="취소사유를 입력하세요."
              v-model="cancelReason"
            />
          </div>
          <div class="typePos-text">
            <strong>{{ popupData.visitName }}</strong
            >님의 {{ payTitle }}금액
            {{ popupData.payAmt | numberWithCommas }}원이 정산 취소처리
            됩니다.<br />
            계속하시겠습니까?
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                ref="payCancelButton"
                button-div="DELETE"
                class="ui-button i-typePrimary i-iconConfirm"
                :use-syncfusion-component-style="false"
                @click="payCancel"
              >
                <div class="i-item">확인</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="closePayCancelPopup"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-header .typePos-list {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-header .typePos-list > li {border-top: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-header .typePos-list > li:first-child {border-top: none;}
.typePos .typePos-wrapper-header .typePos-list > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-header .typePos-list > li .typePos-title {box-sizing: border-box; float: left; width: 120px; padding: 10px 15px 9px 20px; color: #222; font-size: 13px; font-family: 'NanumSquare-Bold'; line-height: 19px; background-color: #f5f5f5;}
.typePos .typePos-wrapper-header .typePos-list > li .typePos-title.cancel-reason {display: flex; align-items: center; height: 60px; border-bottom: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-header .typePos-list > li .typePos-content {box-sizing: border-box; float: left; width: calc(100% - 120px); padding: 10px 20px 9px 15px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-header .typePos-list > li .typePos-content.proof-no-confirm {padding: 5px 15px;}
.typePos .typePos-wrapper-header .typePos-list > li .typePos-content.proof-no-confirm > input {width: calc(100% - 12px); height: 24px; padding-left: 5px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; border: 1px solid #e0e0e0; border-radius: 4px;}
.typePos .typePos-wrapper-header .typePos-list > li .typePos-content.cancel-reason {border-bottom: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-header .typePos-list > li .typePos-content.typePos-number {text-align: right;}

.typePos .typePos-wrapper-main {flex: 1; padding: 15px 20px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-main .typePos-filed {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-filed textarea {box-sizing: border-box; box-shadow: none; width: 100%; height: 82px; padding: 12px 15px 11px 15px; margin: 0; border: 1px solid #e0e0e0; border-radius: 4px; background-color: #fff; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; resize: none;}
.typePos .typePos-wrapper-main .typePos-filed textarea::-ms-clear {display: none;}
.typePos .typePos-wrapper-main .typePos-filed textarea::placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-filed textarea::-webkit-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-filed textarea:-ms-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-filed textarea:focus {border-color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-filed textarea:disabled,
.typePos .typePos-wrapper-main .typePos-filed textarea:disabled:focus {background-color: #eee; color: #aaa; cursor: default;}
.typePos .typePos-wrapper-main .typePos-text {padding: 9px 0 1px 0; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-text strong {color: #18b3c9; font-weight: normal;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  commonCodesGetBizMstInfo,
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { getTodayNavigationDate } from "@/utils/date";
import {
  cancelPayInfo,
  getReceiptInfo,
  getTgPayCardByCardId,
} from "@/api/common";
import GolfERPService from "@/service/GolfERPService";
import { getStoreBsnMstInfo } from "@/api/bizCodeManagement";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { getDeviceInfo } from "@/utils/device";
import { openERPPay } from "@/utils/Pay";
import { numberWithCommas } from "@/utils/number";
import FSelect from "@/views/store-management/sales-registration/store-pos-renew/components/FSelect";
import { getFormattedTelNumber } from "@/utils/string";
import BillPrintUtil from "@/utils/billPrintUtil";
import { mapGetters } from "vuex";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "CancelPaymentPopup",
  components: { FSelect, ErpButton },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  async created() {
    this.today = await getTodayNavigationDate("YYYY-MM-DD");

    this.bizMstInfo = commonCodesGetBizMstInfo();

    this.bizMstInfo.bizNo =
      this.bizMstInfo.bizNo.substr(0, 3) +
      "-" +
      this.bizMstInfo.bizNo.substr(3, 2) +
      "-" +
      this.bizMstInfo.bizNo.substring(5);
  },
  data() {
    return {
      printFlag: getDeviceInfo().isBillPrinterUse,
      telCancel: false,
      receiptInfo: {},
      bizMstInfo: {},

      popupData: {},
      callback: null,

      today: null,
      cancelReason: ".",
      payCardInfo: {},
      payCashInfo: {},
      payCashProofCancelCode: "1",
      proofCancelCodeList: commonCodesGetCommonCode("PROOF_CANCEL_CODE").map(
        (commonCode) => ({
          name: commonCode.comName,
          value: commonCode.comCode,
        })
      ),
      message: "",
      cancelProcessing: false,
      payTitle: null,
      van: null,
      proofNo: null,
      cardProof: false,
      bsnInfo: null,
      storeCode: null,
    };
  },
  computed: {
    ...mapGetters("pos", ["visibleConfirmDialog"]),
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "정산취소",
        width: 425,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    proofKindName() {
      return commonCodesGetComName("PROOF_KIND", this.payCashInfo.proofKind);
    },
  },
  methods: {
    hide() {
      this.$refs["dialog"].hide();
    },

    onDialogClose() {
      this.$emit("close");
    },

    commonCodesGetComName,
    async showPayCancelPopup(popupData) {
      console.log('popupData.===>', popupData);
      try {
        const { payDiv, cardId, cashId, depositUseDiv } = popupData;
        await this.initialize(payDiv, cardId, cashId, depositUseDiv);
        this.callback = popupData.callback;
        this.popupData = popupData;
        this.$refs["dialog"].show();
      } catch (e) {
        console.error(e);
        if (e instanceof Error) {
          this.errorToast(e.message);
        } else {
          this.errorToast(e);
        }
        this.closePayCancelPopup();
      }
    },
    closePayCancelPopup() {
      this.hide();
    },
    async initialize(payDiv, cardId, cashId, depositUseDiv) {
      if (payDiv === "CARD" || payDiv === "EASY_PAY") {
        let { value: response } = await getTgPayCardByCardId(cardId);

        if (response.approvalDiv === "OK") {
          await this.setVan(response.storeCode);
        }

        this.payCardInfo = response;

        this.payTitle = "정산";
      } else if ((payDiv === "CASH" || payDiv === "TRANS" || payDiv === "DEPOSIT" || payDiv === "PRECARD") && cashId) {
        let cashReceipt = await GolfERPService.fetchCashReceipt(cashId);
        await this.setVan(cashReceipt.storeCode);
        this.payCashInfo = cashReceipt;
        this.cardProof = cashReceipt.cardProof;

        this.payTitle = "정산";
      } else {
        this.payTitle = commonCodesGetComName("DEPOSIT_USE_DIV", depositUseDiv);
      }
    },
    async setVan(storeCode) {
      this.storeCode = storeCode;

      const { value: bsnInfo } = await getStoreBsnMstInfo(storeCode);
      this.bsnInfo = bsnInfo;
      const { vanKind } = bsnInfo;
      this.van = vanKind;
    },
    startCancel() {
      if (this.cancelProcessing) {
        return;
      }
      this.cancelProcessing = true;

      this.$refs["dialog"].$el.nextElementSibling.style.zIndex = `${
        parseInt(this.$refs["dialog"].$el.nextElementSibling.style.zIndex) + 1
      }`;
    },
    endCancel() {
      if (!this.cancelProcessing) {
        return;
      }
      this.cancelProcessing = false;
      if (this.$refs["dialog"]) {
        this.$refs["dialog"].$el.nextElementSibling.style.zIndex = `${
          parseInt(this.$refs["dialog"].$el.nextElementSibling.style.zIndex) - 1
        }`;
      }
    },
    async payCancel() {
      // 일마감 여부 확인.
      try {
        await GolfErpAPI.fetchCloseCheck({
          bsnDateFrom: this.popupData.payDate,
          bsnDateTo: this.popupData.payDate,
          storeCode: this.popupData.storeCode,
        });
      } catch (e) {
        console.error(e);
        return;
      }

      if (!this.cancelReason) {
        return this.errorToast("취소사유를 입력해 주시기 바랍니다");
      }

      this.$refs.payCancelButton.blur();

      const task = async () => {
        const payCancelInfo = {
          payDiv: this.popupData.payDiv,
          salesDiv: this.popupData.salesDiv,
          bsnDate: this.popupData.bsnDate,
          chkinId: this.popupData.chkinId, // 체크인ID
          teamGrpFlag: this.popupData.teamGrpFlag,
          visitId: this.popupData.visitId, // 내장객ID
          payId: this.popupData.payId, // 정산ID
          cancelReason: this.cancelReason, // 취소사유,
          storeCode: this.popupData.storeCode,
          payDate: this.popupData.payDate,
          incomId: this.popupData.incomId,
          useId: this.popupData.useId,
          depositUseDiv: this.popupData.depositUseDiv,
          isOnlyCardCancel: this.popupData.isOnlyCardCancel,
          isOnlyPayDataCancel: this.popupData.isOnlyPayDataCancel,
          isPos: this.popupData.isPos,
        };

        if (payCancelInfo.teamGrpFlag) {
          payCancelInfo.grpNo = this.popupData.grpNo;
        }

        // 기승인 취소 플래그
        // 기승인 취소 되었을 경우 정산 완료 시 팝업 메시지가 달라져야함
        switch (this.popupData.payDiv) {
          case "CARD":
            payCancelInfo.cardId = this.popupData.cardId; // 카드ID(정산구분이 카드일경우)

            if (this.payCardInfo.approvalDiv === "OK" && !this.telCancel) {
              const payTransactionRequest = this.createPayTransactionRequestByPayCardInfo(
                this.payCardInfo
              );
              const { tid, status, message } = await this.payVan(payTransactionRequest);
              if (status === "ALREADY_CANCELED") {
                this.infoToast(
                  this.$t("vanModule.popupMessage.alreadyCanceled")
                );
              } else if (status === "SELFCANCEL") {
                this.message = message;
                this.endCancel();
                return;
              }

              payCancelInfo.tid = tid;
            }
            break;
          case "EASY_PAY":
            payCancelInfo.cardId = this.popupData.cardId; // 카드ID(정산구분이 카드일경우)
            if (this.payCardInfo.approvalDiv === "OK" && !this.telCancel) {
              const payTransactionRequest = this.createPayTransactionRequestByPayEASYInfo(
                  this.payCardInfo
              );
              const { tid, status, message } = await this.payVan(payTransactionRequest);
              if (status === "ALREADY_CANCELED") {
                this.infoToast(
                    this.$t("vanModule.popupMessage.alreadyCanceled")
                );
              } else if (status === "SELFCANCEL") {
                this.message = message;
                this.endCancel();
                return;
              }
              payCancelInfo.tid = tid;
            }
            break;
          case "CASH":
            break;
          case "CREDIT":
            break;
          case "DEPOSIT":
          case "PRECARD":
          case "GIFT":
          case "GROUP_GIFT":
            break;
          case "RETURN":
          case "YEAR":
          case "ETC":
          case "PROMTN":
          case "TRANS":
            break;
          default:
            this.errorToast(this.$t("vanModule.popupMessage.invalidPayDiv"));
            return;
        }

        // // 정산 취소 API 호출 및 호출 성공 시 팝업 닫기
        // try {
        //   if (this.popupData.useId) {
        //     const depositUseCancel = {
        //       useId: payCancelInfo.useId,
        //       cancelReason: payCancelInfo.cancelReason,
        //       payId: payCancelInfo.payId,
        //       salesDiv: payCancelInfo.salesDiv,
        //       useDate: payCancelInfo.payDate,
        //       storeCode: payCancelInfo.storeCode,
        //       isPos: this.popupData.isPos,
        //     };
        //     await GolfErpAPI.deleteDepositUse(depositUseCancel);
        //   } else {
        //     const { value: cancelCardId } = await cancelPayInfo(payCancelInfo);
        //
        //     if (this.popupData.payDiv === "CARD") {
        //       if (cancelCardId) {
        //         this.print(cancelCardId);
        //       } else {
        //         this.print(payCancelInfo.cardId);
        //       }
        //     }
        //   }
        // } catch (e) {
        //   if (e.response.data.status !== "CONFIRM") {
        //     this.errorToast(
        //       this.$t("vanModule.popupMessage.cancelSuccessButServerFail")
        //     );
        //   }
        //   return;
        // }

        if (
          (this.popupData.payDiv === "CASH" ||
            this.popupData.payDiv === "TRANS" ||
            this.popupData.payDiv === "DEPOSIT" ||
            this.popupData.payDiv === "PRECARD") &&
          this.popupData.cashId
        ) {
          let tid = 0;
          let status = null;
          if (this.payCashInfo.approvalDivision === "OK") {
            const payTransactionRequest = this.createPayTransactionRequestByPayCashInfo(
              this.payCashInfo
            );
            const {tid: tidByVan, status: statusByVan} = await this.payVan(payTransactionRequest);

            tid = tidByVan;
            status = statusByVan;
          }

          const cancelCashId = await GolfERPService.patchPayCashReceipt(
            this.popupData.cashId,
            tid
          );

          if (status === "ALREADY_CANCELED") {
            this.errorToast(this.$t("vanModule.popupMessage.alreadyCanceled"));
          } else {
            this.infoToast(this.$t("vanModule.popupMessage.cancelSuccess"));
            this.popupData.isPayCash = true;
            this.print(this.popupData.payDiv === "DEPOSIT" ? this.popupData.useId : cancelCashId);
          }
        } else {
          if (
              this.popupData.payDiv !== "CASH" &&
              this.popupData.payDiv !== "CREDIT" &&
              this.popupData.payDiv !== "DEPOSIT" &&
              this.popupData.payDiv !== "PRECARD" &&
              this.popupData.payDiv !== "GIFT" &&
              this.popupData.payDiv !== "GROUP_GIFT"
          ) {
            this.infoToast(this.$t("vanModule.popupMessage.cancelSuccess"));
          }
        }

        // 정산 취소 API 호출 및 호출 성공 시 팝업 닫기
        try {
          if (this.popupData.useId) {
            const depositUseCancel = {
              useId: payCancelInfo.useId,
              cancelReason: payCancelInfo.cancelReason,
              payId: payCancelInfo.payId,
              salesDiv: payCancelInfo.salesDiv,
              useDate: payCancelInfo.payDate,
              storeCode: payCancelInfo.storeCode,
              isPos: this.popupData.isPos,
            };
            await GolfErpAPI.deleteDepositUse(depositUseCancel);
          } else {
            const { value: cancelCardId } = await cancelPayInfo(payCancelInfo);

            if (this.popupData.payDiv === "CARD" || this.popupData.payDiv === "EASY_PAY") {
              if (cancelCardId) {
                this.print(cancelCardId);
              } else {
                this.print(payCancelInfo.cardId);
              }
            } else if (this.popupData.payDiv === "CREDIT") {
              this.infoToast(this.$t("vanModule.popupMessage.cancelSuccess"));
            }
          }
          if (
              this.popupData.payDiv === "DEPOSIT" ||
              this.popupData.payDiv === "PRECARD" ||
              this.popupData.payDiv === "GIFT" ||
              this.popupData.payDiv === "GROUP_GIFT"
          ) {
            this.infoToast(this.$t("vanModule.popupMessage.cancelSuccess"));
            this.print(
                ["GIFT", "GROUP_GIFT"].includes(this.popupData.payDiv) ? (this.popupData.useId ? this.popupData.useId : this.popupData.payId) : this.popupData.useId,
                null,
                this.popupData.payDiv === "GIFT" ? !!this.popupData.useId : false
            );
          } else if (this.popupData.payDiv === "CASH" && !this.popupData.cashId) {
            this.infoToast(this.$t("vanModule.popupMessage.cancelSuccess"));
            this.print(
                this.popupData.salesDiv === "CREDIT"
                    ? this.popupData.returnId
                    : this.popupData.salesDiv === "DEPOSIT"
                        ? this.popupData.incomId
                        : this.popupData.payId
            );
          }
        } catch (e) {
          if (e.response.data.status !== "CONFIRM") {
            this.errorToast(
              this.$t("vanModule.popupMessage.cancelSuccessButServerFail")
            );
          }
          return;
        }

        if (this.callback) {
          this.callback();
        }

        this.$emit("close", { isReload: true });
      };

      this.confirm({
        message: this.telCancel ? "전화취소! 처리 하시겠습니까?" : "정산을 취소하시겠습니까?",
        callback: async (confirmed) => {
          if (!confirmed) {
            return;
          }

          // 디밍 처리
          this.startCancel();

          try {
            await task();
          } catch (e) {
            if (e instanceof Error) {
              this.pinnedToast({
                message: this.$t("vanModule.popupMessage.approveCancelFail") +
                  "<br><br>" +
                  e.message
              });
              this.message = e.message;
            } else {
              this.pinnedToast({
                message: this.$t("vanModule.popupMessage.approveCancelFail") + "<br><br>" + e
              });
              this.message = e;
            }
          } finally {
            this.endCancel();
          }
        },
      });
    },
    createPayTransactionRequestByPayCardInfo(payCardInfo) {
      return {
        van: this.van,
        payDivision: "CARD",
        approvalDivision: "CANCEL",
        totalAmount: payCardInfo.approvalAmt,
        taxAmount: payCardInfo.taxAmt,
        vatAmount: payCardInfo.vatAmt,
        notaxAmount: payCardInfo.notaxAmt,
        serviceAmount: payCardInfo.svcAmt,
        divideTerm: payCardInfo.divideTerm,
        approvalNo: payCardInfo.approvalNo,
        approvalDateTime: payCardInfo.approvalDt,
        storeCode: this.storeCode,
        businessId: this.payCardInfo.bsnId,
        posNo: getDeviceInfo().posNo,
        termId: this.payCardInfo.termId,
        payDate: this.popupData.payDate,
        visitId: this.popupData.visitId
      };
    },
    createPayTransactionRequestByPayEASYInfo(payEasyInfo) {
      return {
        van: this.van,
        payDivision: "EASY_PAY",
        approvalDivision: "CANCEL",
        totalAmount: payEasyInfo.approvalAmt,
        taxAmount: payEasyInfo.taxAmt,
        vatAmount: payEasyInfo.vatAmt,
        notaxAmount: payEasyInfo.notaxAmt,
        serviceAmount: payEasyInfo.svcAmt,
        divideTerm: payEasyInfo.divideTerm,
        approvalNo: payEasyInfo.approvalNo,
        approvalDateTime: payEasyInfo.approvalDt,
        storeCode: this.storeCode,
        businessId: this.payCardInfo.bsnId,
        posNo: getDeviceInfo().posNo,
        termId: this.payCardInfo.termId,
        payDate: this.popupData.payDate,
        visitId: this.popupData.visitId
      };
    },
    createPayTransactionRequestByPayCashInfo(payCashInfo) {
      return {
        van: this.van,
        payDivision: "CASH",
        approvalDivision: "CANCEL",
        totalAmount: payCashInfo.approvalAmount,
        taxAmount: payCashInfo.taxAmount,
        vatAmount: payCashInfo.vatAmount,
        notaxAmount: payCashInfo.notaxAmount,
        serviceAmount: payCashInfo.serviceAmount,
        proofKind: payCashInfo.proofKind,
        proofNo: this.proofNo,
        cardProof: this.cardProof,
        proofCancelCode: this.payCashProofCancelCode,
        approvalNo: payCashInfo.approvalNo,
        approvalDateTime: payCashInfo.approvalDateTime,
        storeCode: this.storeCode,
        businessId: this.payCashInfo.businessId,
        posNo: getDeviceInfo().posNo,
        termId: this.payCashInfo.termId,
        payDate: this.popupData.payDate,
        visitId: this.popupData.visitId
      };
    },
    async payVan(addTransactionRequest) {
      const { tid, status, message } = await openERPPay(addTransactionRequest);

      switch (status) {
        case "APPROVED":
        case "CANCELED":
        case "ALREADY_CANCELED":
          return { tid, status, message };
        case "READY":
        case "REQUEST":
          throw new Error("결제가 취소되었습니다.");
        case "FAIL":
        case "ROLLBACK":
        case "SELFCANCEL":
          throw new Error(message);
      }
    },
    async print(id, signYN, isDepositGift) {
      try {
        if (
          !this.printFlag ||
          this.popupData.payDiv === "CREDIT" ||
          (this.popupData.payDiv === "TRANS" && !this.popupData.cashId)
        ) {
          return;
        }

        if (id === null) {
          return;
        }

        // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.

        const receiptCode =
          (this.popupData.payDiv.toUpperCase() === "PRECARD" ||
            (this.popupData.payDiv.toUpperCase() === "GIFT" && isDepositGift)
            ? "DEPOSIT"
            : (this.popupData.payDiv === "GROUP_GIFT"
                  ? "GIFT"
                  : this.popupData.payDiv === "EASY_PAY"
                    ? "CARD"
                    : this.popupData.payDiv
              ).toUpperCase()) + "_RECEIPT";

        let printSetData = null;

        if (
          this.popupData.payDiv.toUpperCase() === "DEPOSIT" ||
          this.popupData.payDiv.toUpperCase() === "PRECARD" ||
          (this.popupData.payDiv.toUpperCase() === "GIFT" && isDepositGift)
        ) {
          const depositUseReceipt = await GolfErpAPI.fetchDepositUseReceipt(id);

          let depositUseList = JSON.parse(
            JSON.stringify(depositUseReceipt.depositUseList)
          );

          depositUseList.forEach((data) => {
            data.depositUseDiv =
              data.depositUseDiv === undefined || data.depositUseDiv === null
                ? ""
                : commonCodesGetComName("DEPOSIT_USE_DIV", data.depositUseDiv);
          });

          printSetData = {
            printDt: depositUseReceipt.printDt,
            cardNo:
              depositUseReceipt.cardNo === undefined ||
              depositUseReceipt.cardNo === null
                ? ""
                : depositUseReceipt.cardNo,
            depositAmt: depositUseReceipt.depositAmt,
            sumUseAmt: depositUseReceipt.sumUseAmt,
            useAmt: depositUseReceipt.useAmt,
            balanceAmt: depositUseReceipt.balanceAmt,
            totalUseAmt: depositUseReceipt.totalUseAmt,
            useDate: depositUseReceipt.useDate,
            startCourse:
              depositUseReceipt.startCourse === undefined ||
              depositUseReceipt.startCourse === null
                ? ""
                : commonCodesGetComName(
                    "COURSE_CODE",
                    depositUseReceipt.startCourse
                  ),
            startTime:
              depositUseReceipt.startTime === undefined ||
              depositUseReceipt.startTime === null
                ? ""
                : depositUseReceipt.startTime,
            depositUseDiv:
              depositUseReceipt.depositUseDiv === undefined ||
              depositUseReceipt.depositUseDiv === null
                ? ""
                : commonCodesGetComName(
                    "DEPOSIT_USE_DIV",
                    depositUseReceipt.depositUseDiv
                  ),
            ccName: this.bizMstInfo.ccName,
            bizNo: this.bizMstInfo.bizNo,
            rprsntvName: this.bizMstInfo.rprsntvName,
            telNo: getFormattedTelNumber(this.bizMstInfo.telNo, ")"),
            faxNo: getFormattedTelNumber(this.bizMstInfo.faxNo, ")"),
            depositUseList: depositUseList,
            memberName: depositUseReceipt.memberName,
            contactTel: getFormattedTelNumber(depositUseReceipt.contactTel),
          };
        } else {
          await this.getReceiptInfo(id);

          printSetData = {
            storeName: this.commonCodesGetComName(
              "STORE_CODE",
              this.receiptInfo.storeCode
            ),
            bsnDate: this.receiptInfo.payDate,
            lockerNo: this.receiptInfo.lockerNo,
            slipNo: this.receiptInfo.slipNo,
            cardKind: this.receiptInfo.issuCompanyName,
            cardNo: this.receiptInfo.cardNo,
            validTerm: this.receiptInfo.validTerm,
            approvalDt: this.receiptInfo.approvalDt,
            purcName: this.receiptInfo.purcName,
            rePrintDt: null,
            totAmt: this.receiptInfo.approvalAmt,
            taxAmt: this.receiptInfo.taxAmt,
            noTaxAmt: this.receiptInfo.notaxAmt,
            svcAmt:
              this.receiptInfo.svcAmt === "0" ? null : this.receiptInfo.svcAmt,
            vatAmt: this.receiptInfo.vatAmt,
            approvalNo: this.receiptInfo.approvalNo,
            divideTerm: this.receiptInfo.divideTerm,
            merchantNo: this.receiptInfo.merchantNo,
            merchantName: this.receiptInfo.bizName,
            bizNo: this.receiptInfo.bizNo,
            rprsntvName: this.receiptInfo.rprsntvName,
            addr: this.receiptInfo.addr,
            telNo: getFormattedTelNumber(this.receiptInfo.telNo),
            faxNo: getFormattedTelNumber(this.receiptInfo.faxNo),
            proofName: this.receiptInfo.proofName,
            proofNo: this.receiptInfo.proofNo,
            cardTitle:
              this.receiptInfo.approvalDiv === "OK"
                ? "매출전표 (신용승인)"
                : "매출전표 (신용취소)",
            cashTitle:
              (this.receiptInfo.approvalDiv === "OK"
                ? "현금승인 "
                : "현금취소 ") +
              (this.receiptInfo.proofName
                ? "(" + this.receiptInfo.proofName + ")"
                : "(미발급)"),
            transTitle:
              (this.receiptInfo.approvalDiv === "OK"
                ? "계좌이체승인 "
                : "계좌이체취소 ") +
              (this.receiptInfo.proofName
                ? "(" + this.receiptInfo.proofName + ")"
                : "(미발급)"),
            signYn: signYN,
            giftNo: this.receiptInfo.giftNo,
            payAmt: this.receiptInfo.payAmt,
            memberName: this.receiptInfo.memberName,
            contactTel: getFormattedTelNumber(
              this.receiptInfo.contactTel
            ),
            printDt: this.receiptInfo.printDt,
            giftTitle: this.receiptInfo.approvalDiv === "OK" ? "상품권 사용 내역서" : "상품권 취소 내역서",
          };
        }
        const config = {
          "useBillPrinter": true,
          "useKitchenPrinter": false,
        };
        await BillPrintUtil.getTsConfReceiptInfo.call(this, receiptCode, true, printSetData, config);


      } catch (e) {
        if (e instanceof Error) {
          this.operationMessage += `\n[영수증 발급 에러] ${e.message}`;
        } else {
          this.operationMessage += `\n[영수증 발급 에러] ${e}`;
        }
        this.errorToast(this.$t("vanModule.popupMessage.printReceiptFail"));
      }
    },
    async getReceiptInfo(payId) {
      try {
        const {
          value: { receiptInfo },
        } = await getReceiptInfo(
          payId,
          (this.popupData.payDiv === "GROUP_GIFT" ? "GIFT" : this.popupData.payDiv === "EASY_PAY" ? "CARD" : this.popupData.payDiv),
          this.popupData.salesDiv,
          this.popupData.isPayCash,
          this.popupData.payDiv === "CASH" && !this.popupData.cashId ? true : false
        );

        if (
          this.popupData.payDiv === "CASH" ||
          this.popupData.payDiv === "TRANS"
        ) {
          receiptInfo.proofName = commonCodesGetComName(
            "PROOF_KIND",
            receiptInfo.proofKind
          );
        }

        this.receiptInfo = receiptInfo;
      } catch (e) {
        console.error("getReceiptInfo.err.===>", e);
        throw e;
      }
    },
    confirm({ message, callback }) {
      this.$EventBus.$emit("pos-confirm", {
        message,
        callback,
      });
    },
  },
};
</script>
