import request from "@/utils/request";
const qs = require("qs");

/**
 * @return Promise
 */
export function getSalesStatus(bsnDate, storeCode) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/sales_management/store-pos/sales-status",
    method: "get",
    params: {
      bsnDate,
      storeCode,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    }
  });
}

/**
 * @return Promise
 */
export function getProductList(
  storeCode,
  depth01Code,
  depth02Code,
  depth03Code,
  productNameCode,
  optnFlag,
  excludeRegisteredProduct
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/common/product-codes-pos",
    method: "get",
    params: {
      storeCode,
      depth01Code,
      depth02Code,
      depth03Code,
      productNameCode,
      optnFlag,
      excludeRegisteredProduct,
    },
  });
}

/**
 * @return Promise
 */
export function getSalesReceipt(salesId, isProductTotal, gcDiv, isDcListView) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/sales_management/store-pos/sales-receipt",
    method: "get",
    params: {
      salesId,
      isProductTotal,
      gcDiv,
      isDcListView,
    },
  });
}
