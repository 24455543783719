<template>
  <div>
    <ejs-dialog
      ref="posHistoryPopup"
      :header="`변경이력`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      @close="onPopupClose"
      width="480"
      height="600"
      :animationSettings="{ effect: 'None' }"
    >
      <div class="typePos">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section">
                  <div class="section-body" style="border-top-color: #000">
                    <div
                      v-for="(history, index) in $_histories"
                      v-bind:key="`histories-${index}`"
                    >
                      <div v-if="history.create">
                        <div style="border-bottom: 1px solid #e0e0e0">
                          <div
                            style="
                              background-color: #f9f9f9;
                              padding: 9px 18px 8px 18px;
                              color: #000;
                            "
                          >
                            ▷ 신규
                          </div>

                          <div style="padding: 6px 18px">
                            <div
                              style="
                                color: #000;
                                padding: 9px 0 8px 0;
                                border-bottom: 1px solid #e0e0e0;
                              "
                            >
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: left;
                                  padding-right: 6px;
                                "
                              >
                                {{ history.workerName }}
                              </div>
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: right;
                                  padding-left: 6px;
                                "
                              >
                                {{ history.timestamp }}
                              </div>
                              <div style="clear: both; height: 0"></div>
                            </div>
                            <div style="padding: 9px 0 8px 0">
                              <div v-if="history.create.Slip">
                                <div style="font-weight: bold">매출정보</div>
                                <div
                                  v-for="(
                                    slipHistory, slipHistoryIndex
                                  ) in history.create.Slip"
                                  v-bind:key="`histories-slip-create-${slipHistoryIndex}`"
                                >
                                  <div
                                    v-for="(
                                      slipHistoryChange, slipHistoryChangeIndex
                                    ) in slipHistory.changes"
                                    v-bind:key="`histories-slip-create-${slipHistoryIndex}-changes-${slipHistoryChangeIndex}`"
                                  >
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: 153px;
                                        color: #000;
                                        padding-right: 6px;
                                      "
                                    >
                                      &nbsp;
                                    </div>
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: 103px;
                                        color: #000;
                                        padding-right: 6px;
                                      "
                                    >
                                      {{
                                        getFieldName("Slip", slipHistoryChange)
                                      }}
                                    </div>
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: calc(100% - 256px);
                                        color: #666;
                                        padding-left: 6px;
                                      "
                                    >
                                      →
                                      {{
                                        getFieldNewValue(
                                          "Slip",
                                          slipHistoryChange
                                        )
                                      }}
                                    </div>
                                    <div style="clear: both; height: 0"></div>
                                  </div>
                                </div>
                              </div>
                              <div v-if="history.create.Sale">
                                <div style="font-weight: bold">
                                  매출정보상세
                                </div>
                                <div
                                  v-for="(
                                    saleHistory, saleHistoryIndex
                                  ) in history.create.Sale"
                                  v-bind:key="`histories-sale-create-${saleHistoryIndex}`"
                                >
                                  <div
                                    v-for="(
                                      saleHistoryChange, saleHistoryChangeIndex
                                    ) in saleHistory.changes"
                                    v-bind:key="`histories-sale-create-${saleHistoryIndex}-changes-${saleHistoryChangeIndex}`"
                                  >
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: 153px;
                                        color: #000;
                                        padding-right: 6px;
                                      "
                                    >
                                      <template
                                        v-if="saleHistoryChangeIndex === 0"
                                      >
                                        ☞
                                        {{ saleHistory.record["productName"] }}
                                      </template>
                                      <template v-else> &nbsp; </template>
                                    </div>
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: 103px;
                                        color: #000;
                                        padding-right: 6px;
                                      "
                                    >
                                      {{
                                        getFieldName("Sale", saleHistoryChange)
                                      }}
                                    </div>
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: calc(100% - 256px);
                                        color: #666;
                                        padding-left: 6px;
                                      "
                                    >
                                      →
                                      {{
                                        getFieldNewValue(
                                          "Sale",
                                          saleHistoryChange
                                        )
                                      }}
                                    </div>
                                    <div style="clear: both; height: 0"></div>
                                  </div>
                                </div>
                              </div>
                              <div v-if="history.create.SlipMemo">
                                <div style="font-weight: bold">
                                  매출정보메모
                                </div>
                                <div
                                  v-for="(
                                    slipMemoHistory, slipMemoHistoryIndex
                                  ) in history.create.SlipMemo"
                                  v-bind:key="`histories-slipMemo-create-${slipMemoHistoryIndex}`"
                                >
                                  <div
                                    v-for="(
                                      slipMemoHistoryChange,
                                      slipMemoHistoryChangeIndex
                                    ) in slipMemoHistory.changes"
                                    v-bind:key="`histories-slipMemo-create-${slipMemoHistoryIndex}-changes-${slipMemoHistoryChangeIndex}`"
                                  >
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: 153px;
                                        color: #000;
                                        padding-right: 6px;
                                      "
                                    >
                                      &nbsp;
                                    </div>
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: 103px;
                                        color: #000;
                                        padding-right: 6px;
                                      "
                                    >
                                      {{
                                        getFieldName(
                                          "SlipMemo",
                                          slipMemoHistoryChange
                                        )
                                      }}
                                    </div>
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: calc(100% - 256px);
                                        color: #666;
                                        padding-left: 6px;
                                      "
                                    >
                                      →
                                      {{
                                        getFieldNewValue(
                                          "SlipMemo",
                                          slipMemoHistoryChange
                                        )
                                      }}
                                    </div>
                                    <div style="clear: both; height: 0"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="history.update">
                        <div style="border-bottom: 1px solid #e0e0e0">
                          <div
                            style="
                              background-color: #f9f9f9;
                              padding: 9px 18px 8px 18px;
                              color: #000;
                            "
                          >
                            ▷ 변경
                          </div>

                          <div style="padding: 6px 18px">
                            <div
                              style="
                                color: #000;
                                padding: 9px 0 8px 0;
                                border-bottom: 1px solid #e0e0e0;
                              "
                            >
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: left;
                                  padding-right: 6px;
                                "
                              >
                                {{ history.workerName }}
                              </div>
                              <div
                                style="
                                  box-sizing: border-box;
                                  float: right;
                                  padding-left: 6px;
                                "
                              >
                                {{ history.timestamp }}
                              </div>
                              <div style="clear: both; height: 0"></div>
                            </div>
                            <div style="padding: 9px 0 8px 0">
                              <div v-if="history.update.Slip">
                                <div style="font-weight: bold">매출정보</div>
                                <div
                                  v-for="(
                                    slipHistory, slipHistoryIndex
                                  ) in history.update.Slip"
                                  v-bind:key="`histories-slip-update-${slipHistoryIndex}`"
                                >
                                  <div
                                    v-for="(
                                      slipHistoryChange, slipHistoryChangeIndex
                                    ) in slipHistory.changes"
                                    v-bind:key="`histories-slip-update-${slipHistoryIndex}-changes-${slipHistoryChangeIndex}`"
                                  >
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: 153px;
                                        color: #000;
                                        padding-right: 6px;
                                      "
                                    >
                                      &nbsp;
                                    </div>
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: 103px;
                                        color: #000;
                                        padding-right: 6px;
                                      "
                                    >
                                      {{
                                        getFieldName("Slip", slipHistoryChange)
                                      }}
                                    </div>
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: calc(100% - 256px);
                                        color: #666;
                                        padding-left: 6px;
                                      "
                                    >
                                      {{
                                        getFieldOldValue(
                                          "Slip",
                                          slipHistoryChange
                                        )
                                      }}
                                      →
                                      {{
                                        getFieldNewValue(
                                          "Slip",
                                          slipHistoryChange
                                        )
                                      }}
                                    </div>
                                    <div style="clear: both; height: 0"></div>
                                  </div>
                                </div>
                              </div>
                              <div v-if="history.update.Sale">
                                <div style="font-weight: bold">
                                  매출정보상세
                                </div>
                                <div
                                  v-for="(
                                    saleHistory, saleHistoryIndex
                                  ) in history.update.Sale"
                                  v-bind:key="`histories-sale-update-${saleHistoryIndex}`"
                                >
                                  <div
                                    v-for="(
                                      saleHistoryChange, saleHistoryChangeIndex
                                    ) in saleHistory.changes"
                                    v-bind:key="`histories-sale-update-${saleHistoryIndex}-changes-${saleHistoryChangeIndex}`"
                                  >
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: 153px;
                                        color: #000;
                                        padding-right: 6px;
                                      "
                                    >
                                      <template
                                        v-if="saleHistoryChangeIndex === 0"
                                      >
                                        ☞
                                        {{ saleHistory.record["productName"] }}
                                      </template>
                                      <template v-else> &nbsp; </template>
                                    </div>
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: 103px;
                                        color: #000;
                                        padding-right: 6px;
                                      "
                                    >
                                      {{
                                        getFieldName("Sale", saleHistoryChange)
                                      }}
                                    </div>
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: calc(100% - 256px);
                                        color: #666;
                                        padding-left: 6px;
                                      "
                                    >
                                      {{
                                        getFieldOldValue(
                                          "Sale",
                                          saleHistoryChange
                                        )
                                      }}
                                      →
                                      {{
                                        getFieldNewValue(
                                          "Sale",
                                          saleHistoryChange
                                        )
                                      }}
                                    </div>
                                    <div style="clear: both; height: 0"></div>
                                  </div>
                                </div>
                              </div>
                              <div v-if="history.update.SlipMemo">
                                <div style="font-weight: bold">
                                  매출정보메모
                                </div>
                                <div
                                  v-for="(
                                    slipMemoHistory, slipMemoHistoryIndex
                                  ) in history.update.SlipMemo"
                                  v-bind:key="`histories-slipMemo-update-${slipMemoHistoryIndex}`"
                                >
                                  <div
                                    v-for="(
                                      slipMemoHistoryChange,
                                      slipMemoHistoryChangeIndex
                                    ) in slipMemoHistory.changes"
                                    v-bind:key="`histories-slipMemo-update-${slipMemoHistoryIndex}-changes-${slipMemoHistoryChangeIndex}`"
                                  >
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: 153px;
                                        color: #000;
                                        padding-right: 6px;
                                      "
                                    >
                                      &nbsp;
                                    </div>
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: 103px;
                                        color: #000;
                                        padding-right: 6px;
                                      "
                                    >
                                      {{
                                        getFieldName(
                                          "SlipMemo",
                                          slipMemoHistoryChange
                                        )
                                      }}
                                    </div>
                                    <div
                                      style="
                                        box-sizing: border-box;
                                        float: left;
                                        width: calc(100% - 256px);
                                        color: #666;
                                        padding-left: 6px;
                                      "
                                    >
                                      {{
                                        getFieldOldValue(
                                          "SlipMemo",
                                          slipMemoHistoryChange
                                        )
                                      }}
                                      →
                                      {{
                                        getFieldNewValue(
                                          "SlipMemo",
                                          slipMemoHistoryChange
                                        )
                                      }}
                                    </div>
                                    <div style="clear: both; height: 0"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="history.delete"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos{display:flex;flex-direction:column;box-sizing:border-box;width:calc(100% + 36px);height:calc(100% + 18px);margin:0 -18px -18px;border-top:1px solid #bebebe}
.typePos .typePos-wrapper-main{flex:1;overflow:hidden}
.typePos .typePos-wrapper-footer{padding:15px;border-top:1px solid #bebebe}
.typePos .typePos-wrapper-footer::after{display:block;clear:both;height:0;font-size:0;line-height:0;content:''}
.typePos .typePos-wrapper-footer .typePos-button{float:right;list-style:none;padding:0;margin:0}
.typePos .typePos-wrapper-footer .typePos-button::after{display:block;clear:both;height:0;font-size:0;line-height:0;content:''}
.typePos .typePos-wrapper-footer .typePos-button > li{float:left;padding:0 5px}
</style>

<script>
import { commonCodesGetComName } from "@/utils/commonCodes";
import { interpretRawHistories } from "@/utils/history/HistoryUtil";
import { numberWithCommas } from "@/utils/number";
import ErpButton from "@/components/button/ErpButton";

const SLIP_FIELDS = {
  number: { name: "전표번호", type: "string", methods: ["create"] },
  storeTableName: {
    name: "테이블",
    type: "string",
    methods: ["create", "update"],
  },
  visitorName: {
    name: "내장객",
    type: "string",
    methods: ["create", "update"],
  },
  domesticCustomersCount: {
    name: "인원수",
    type: "number",
    methods: ["update"],
  },
  payPreOrFutureDivision: {
    name: "선불후불구분",
    type: "commonCode",
    groupCode: "PRPAY_FRPY_DIV",
    methods: ["update"],
  },
  isDeleted: { name: "삭제여부", type: "boolean", methods: ["update"] },
  isPaid: { name: "정산여부", type: "boolean", methods: ["update"] },
  deleteReason: { name: "삭제사유", type: "string", methods: ["update"] },
};

const SALE_FIELDS = {
  priceDivision: {
    name: "단가구분",
    type: "commonCode",
    groupCode: "PRICE_DIV",
    methods: ["update"],
  },
  quantity: { name: "판매수량", type: "number", methods: ["create", "update"] },
  price: { name: "판매단가", type: "number", methods: ["update"] },
  netAmount: { name: "공급가액", type: "number", methods: ["update"] },
  vatAmount: { name: "부가세금액", type: "number", methods: ["update"] },
  amount: { name: "판매금액", type: "number", methods: ["update"] },
  discountAmount: { name: "할인금액", type: "number", methods: ["update"] },
  discountRate: { name: "할인율", type: "number", methods: ["update"] },
  isCaddieUsed: {
    name: "캐디사용여부",
    type: "boolean",
    methods: ["create", "update"],
  },
  noTaxCode: {
    name: "면세",
    type: "commonCode",
    groupCode: "NOTAX_CODE",
    methods: ["create", "update"],
  },
  totalAmount: {
    name: "총금액",
    type: "number",
    methods: ["create", "update"],
  },
  voidId: { name: "취소매출ID", type: "number", methods: ["create"] },
  payerName: { name: "내장객", type: "string", methods: ["update"] },
  remarks: { name: "비고", type: "string", methods: ["update"] },
};

const SLIP_MEMO_FIELDS = {
  content: { name: "메모내용", type: "string", methods: ["create"] },
  isDeleted: { name: "삭제여부", type: "boolean", methods: ["update"] },
};

export default {
  name: "PosHistoryPopup",
  components: {
    ErpButton,
  },
  data() {
    return {
      histories: [],
    };
  },
  computed: {
    $_histories() {
      return this.histories
        .map((history) => {
          let filteredHistory;

          ["create", "update", "delete"].forEach((method) => {
            if (!history[method]) {
              return;
            }

            const { Slip, Sale, SlipMemo } = history[method];

            const slipHistory = Slip?.map((log) => ({
              ...log,
              changes: log.changes.filter(({ field }) =>
                SLIP_FIELDS[field]?.methods?.includes(method)
              ),
            }))?.filter(({ changes }) => changes.length !== 0);
            const saleHistory = Sale?.map((log) => ({
              ...log,
              changes: log.changes.filter(({ field }) =>
                SALE_FIELDS[field]?.methods?.includes(method)
              ),
            }))?.filter(({ changes }) => changes.length !== 0);

            const slipMemoHistory = SlipMemo?.map((log) => ({
              ...log,
              changes: log.changes.filter(({ field }) =>
                SLIP_MEMO_FIELDS[field]?.methods?.includes(method)
              ),
            }))?.filter(({ changes }) => changes.length !== 0);

            if (
              (slipHistory && 0 < slipHistory.length) ||
              (saleHistory && 0 < saleHistory.length) ||
              (slipMemoHistory && 0 < slipMemoHistory.length)
            ) {
              if (!filteredHistory) {
                filteredHistory = {};
              }
              if (!filteredHistory[method]) {
                filteredHistory[method] = {};
              }

              if (slipHistory && 0 < slipHistory.length) {
                filteredHistory[method]["Slip"] = slipHistory;
              }
              if (saleHistory && 0 < saleHistory.length) {
                filteredHistory[method]["Sale"] = saleHistory;
              }
              if (slipMemoHistory && 0 < slipMemoHistory.length) {
                filteredHistory[method]["SlipMemo"] = slipMemoHistory;
              }
            }
          });

          if (!filteredHistory) {
            return;
          }

          return {
            ...history,
            ...filteredHistory,
          };
        })
        .filter((history) => !!history);
    },
  },
  methods: {
    commonCodesGetComName,
    show({ rawHistories }) {
      this.histories = interpretRawHistories(rawHistories);
      this.$refs.posHistoryPopup.show();
    },
    hide() {
      this.$refs.posHistoryPopup.hide();
    },

    getField(type, field) {
      switch (type) {
        case "Slip":
          return SLIP_FIELDS[field];
        case "Sale":
          return SALE_FIELDS[field];
        case "SlipMemo":
          return SLIP_MEMO_FIELDS[field];
      }

      return null;
    },
    getFieldName(type, change) {
      return this.getField(type, change.field)?.name;
    },
    getFieldOldValue(type, change) {
      const field = this.getField(type, change.field);
      if (!field) {
        return "";
      }

      if (change.oldValue === undefined || change.oldValue === null) {
        return "";
      }

      if (field.type === "commonCode") {
        return commonCodesGetComName(field.groupCode, change.oldValue);
      } else if (field.type === "boolean") {
        return `${change.oldValue}` === "true" ? "예" : "아니요";
      } else if (field.type === "number") {
        return numberWithCommas(change.oldValue);
      } else {
        return change.oldValue || "";
      }
    },
    getFieldNewValue(type, change) {
      const field = this.getField(type, change.field);
      if (!field) {
        return "";
      }

      if (change.newValue === undefined || change.newValue === null) {
        return "";
      }

      if (field.type === "commonCode") {
        return commonCodesGetComName(field.groupCode, change.newValue);
      } else if (field.type === "boolean") {
        return `${change.newValue}` === "true" ? "예" : "아니요";
      } else if (field.type === "number") {
        return numberWithCommas(change.newValue);
      } else {
        return change.newValue || "";
      }
    },

    onPopupClose() {
      this.$emit("close");
    },
    onCloseClick() {
      this.hide();
    },
  },
};
</script>

<style scoped></style>
