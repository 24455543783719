<template>
  <div>
    <ejs-dialog ref="dialog" v-bind="dialogProps" @close="onDialogClose">
      <div
          class="typePos"
          :style="{
          minWidth: `${dialogProps.width}px`,
        }"
      >
        <div class="typePos-wrapper-main typePos-end">
          <div class="typePos-body">
            <ul class="typePos-list">
              <li class="typePos-billCommunication">
                <div class="typePos-title">시간 간격</div>
                <div class="typePos-content">
                  <div class="typePos-item typePos-interval">
                    <div class="ui-input">
                      <!-- 비활성(Attribute) : disabled -->
                      <input
                          type="number"
                          placeholder="초 단위"
                          v-model="interval"
                      />
                    </div>
                  </div>
                  <div class="typePos-item typePos-label">
                    초 간격
                  </div>
                </div>
              </li>
              <li>
                <div class="typePos-title">효과 선택</div>
                <div class="typePos-content">
                  <f-select
                      class="ui-select"
                      :items="effectsForSelect"
                      v-model="effect"
                  />
                </div>
              </li>
              <li>
                <div class="typePos-title">패턴 선택</div>
                <div class="typePos-content">
                  <f-select
                      class="ui-select"
                      :items="imagePatternsForSelect"
                      v-model="imagePattern"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li class="typePos-save">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                class="ui-button i-typePrimary"
                :is-shortcut-button="true"
                :visible-shortcut-string="false"
                :use-syncfusion-component-style="false"
                @click="onSaveClick"
              >
                <div class="i-item">
                  저장 ({{ saveShortcutButtonShortcutString }})
                </div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {display: flex; flex-direction: column;}
.typePos .typePos-wrapper-main .typePos-caption {padding: 10px 20px 5px 20px; border-bottom: 1px solid #e0e0e0; color: #222; font-size: 16px; font-family: 'NanumSquare-Regular'; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-body {border-bottom: 1px solid #bebebe;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list {list-style: none; padding: 5px 13px; margin: 0;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-title,
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content {padding: 0 7px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-title {position: relative; box-sizing: border-box; float: left; width: 92px; padding-top: 10px; padding-bottom: 11px; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content {box-sizing: border-box; float: left; width: calc(100% - 92px);}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .typePos-item {box-sizing: border-box; float: left; padding-left: 10px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .typePos-item:first-child {padding-left: 0;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .typePos-hr {display: block; clear: both; height: 10px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .typePos-item.typePos-text {padding-top: 10px; padding-bottom: 11px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .typePos-item.typePos-select .ui-select {width: 160px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .ui-input {width: 100%;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content .ui-input input {width: 100%;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content >>> .ejs-number input {width: 180px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea {box-sizing: border-box; box-shadow: none; width: 100%; height: 82px; padding: 12px 15px 11px 15px; margin: 0; border: 1px solid #e0e0e0; border-radius: 4px; background-color: #fff; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; resize: none;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea::-ms-clear {display: none;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea::placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea::-webkit-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea:-ms-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea:focus {border-color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea:disabled,
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-content textarea:disabled:focus {background-color: #eee; color: #aaa; cursor: default;}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-item.typePos-interval {width: calc(100% - 160px);}
.typePos .typePos-wrapper-main .typePos-body .typePos-list > li .typePos-item.typePos-label {display: flex; align-items: center; width: 160px; height: 38px;}
.typePos .typePos-wrapper-main.typePos-end {flex: 1;}
.typePos .typePos-wrapper-main.typePos-end .typePos-body {flex: 1; border-bottom: none;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-save .ui-button .i-item::before {background-position-x: calc(-20px * 8);}
</style>

<script>
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from '@/views/layout/mixin/commonMixin';
import {commonCodesGetCommonCodeByIdxAttrb} from '@/utils/commonCodes';
import {getDeviceInfo} from '@/utils/device';
import {getSaveButtonShortcut, toShortcutString} from '@/utils/KeyboardUtil';
import FSelect from '@/views/store-management/sales-registration/store-pos-renew/components/FSelect';

export default {
  name: 'PosClientMonitorConfigPopup',
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ErpButton,
    FSelect,
  },
  data() {
    return {
      deviceSettingInfo: getDeviceInfo(),
      interval: 10,
      effect: 'FADE',
      imagePattern: null,
      imageList: [],
    };
  },
  computed: {
    saveShortcutButtonShortcutString() {
      return toShortcutString(getSaveButtonShortcut());
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: '고객 모니터 상세 설정',
        width: 425,
        allowDragging: true,
        animationSettings: {effect: 'None'},
        isModal: true,
        position: {x: 'center', y: 'center'},
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = 'dialog-non-height';
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    effectsForSelect() {
      return [
        {name: 'N/A', value: 'NONE'},
        {name: '페이드', value: 'FADE'},
        {name: '슬라이드', value: 'SLIDE'},
      ];
    },
    imagePatternsForSelect() {
      return commonCodesGetCommonCodeByIdxAttrb(
          'IMAGE_DIV',
          1,
          'MONTR',
      )?.map(item => ({
        name: item.comName,
        value: item.comCode,
      }));
    },
  },
  methods: {
    show(interval, effect, imagePattern) {
      this.interval = interval;
      this.effect = effect;
      this.imagePattern = imagePattern;
      this.$refs['dialog'].show();
    },
    hide() {
      this.$refs['dialog'].hide();
    },
    onDialogClose() {
      this.$emit('close');
    },
    async onSaveClick() {
      this.$emit('popupConfirmed', {
        interval: this.interval,
        effect: this.effect,
        imagePattern: this.imagePattern,
        imageList: this.imageList,
      });
      this.closePopup();
    },
    onCloseClick() {
      this.closePopup();
    },
    closePopup() {
      this.hide();
      this.$emit('popupClosed');
    },
  },
};
</script>
