<template>
  <div>
    <ejs-dialog ref="dialog" v-bind="dialogProps" @close="onDialogClose">
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-main">
          <ejs-grid-wrapper
            ref="memosGrid"
            v-bind="memosGridProps"
            :allowExcelExport="true"
            @queryCellInfo="onPosMemoCellInfo"
            @dataBound="onSlipListGridDataBound"
          />
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li class="print">
              <erp-button
                  button-div="FILE"
                  class="ui-button"
                  :use-syncfusion-component-style="false"
                  @click="onClickExcel"
              >
                <div class="i-item">Excel</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos{display:flex;flex-direction:column;box-sizing:border-box;width:calc(100% + 36px);height:calc(100% + 18px);margin:0 -18px -18px;border-top:1px solid #bebebe}
.typePos .typePos-wrapper-main{flex:1;overflow:hidden}
.typePos .typePos-wrapper-footer{padding:15px;border-top:1px solid #bebebe}
.typePos .typePos-wrapper-footer::after{display:block;clear:both;height:0;font-size:0;line-height:0;content:''}
.typePos .typePos-wrapper-footer .typePos-button{float:right;list-style:none;padding:0;margin:0}
.typePos .typePos-wrapper-footer .typePos-button::after{display:block;clear:both;height:0;font-size:0;line-height:0;content:''}
.typePos .typePos-wrapper-footer .typePos-button > li{float:left;padding:0 5px}
</style>

<script>
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import moment from "moment";
import {DATE_FORMAT_YYYY_MM_DD} from "@/utils/date";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Aggregate, ForeignKey, Resize, Sort ,ExcelExport} from "@syncfusion/ej2-vue-grids";
import {mapActions, mapState} from "vuex";
import {
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
} from "@/utils/commonCodes";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "PosMemoPopup",
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  props: {
    salesDate: {
      type: Date,
      required: true
    },
    storeCode: {
      type: String,
      required: true
    }
  },
  created() {
    this.fetchMemos();
  },
  data() {
    return {
      slip: {},
      memos: [],
      visibleSlipMemoListPopup: false
    };
  },
  computed: {
    ...mapState("memoView", [
      "memoViews",
      "currentView",
      "memoResveId",
      "memoInfo",
    ]),
    filteredMemoViews() {
      return this.delFlag
          ? this.memoViews
              ?.filter(item => !item.delFlag)
          : this.memoViews;
    },
    storeCodeIdx3(){
      return commonCodesGetCommonCodeAttrbByCodeAndIdx('STORE_CODE', this.storeCode, 3);
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "메모",
        width: 1160,
        height: 760,
        allowDragging: true,
        animationSettings: {effect: "None"},
        isModal: true,
        position: {x: "center", y: "center"},
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    memosGridProps() {
      return {
        allowFiltering: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: [
          {
            field: "memoId",
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "resveTime",
            headerText: "시간",
            textAlign: "Center",
            width: 104,
          },
          {
            groupCode: "COURSE_CODE",
            field: "resveCourse",
            isCommonCodeField: true,
            headerText: "코스",
            textAlign: "Center",
            width: 104,
          },
          {
            field: "resveName",
            headerText: "예약자",
            textAlign: "Center",
            width: 115,
          },
          {
            groupCode: "MEMO_DEPT",
            field: "sendDept",
            isCommonCodeField: true,
            headerText: "전달부서",
            textAlign: "Center",
            width: 115,
          },
          {
            field: "memoContents",
            headerText: "메모",
            textAlign: "Left",
            width:280,
          },
          {
            field: "memoCheck",
            headerText: "확인",
            textAlign: "Center",
            width:90,
            template: () => {
              const storeCodeIdx3 = this.storeCodeIdx3;
              const setCurrentView = this.setCurrentView;
              const setResveId = this.setResveId;
              const memoCheckClick = this.memoCheckClick;
              return {
                template: {
                  components: { ErpButton },
                  template: "<erp-button button-div='SAVE' class='ui-button' :use-syncfusion-component-style='false' :disabled='this.data.isMemoConfirm' @click='onButtonClicked'>확인</erp-button>",
                  data() {
                    return {
                      data: {},
                    };
                  },
                  methods: {
                    async onButtonClicked() {
                      await setCurrentView(storeCodeIdx3);
                      await setResveId(this.data.resveId);
                      await memoCheckClick(this, this.data);
                    },
                  },
                },
              };
            },
          },
          {
            field: "insertUserName",
            headerText: "등록자",
            textAlign: "Center",
            width: 115,
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            textAlign: "Center",
            width: 140,
          },
        ],
        dataSource: this.memos,
        isInPopup: true,
        provides: [Aggregate, ForeignKey, Resize, Sort, ExcelExport],
        selectionSettings: {type: "Single", enableToggle: false},
        useCommonStyles: false,
      };
    },
  },
  methods: {
    ...mapActions('memoView', ['setResveId','setCurrentView','clearMemo']),
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onSlipListGridDataBound() {
      if (this.memos && 0 < this.memos) {
        this.$refs["memosGrid"].selectRow(0);
      }
    },
    onSlipMemoListPopupClose() {
      this.visibleSlipMemoListPopup = false;
    },
    onClickExcel() {
      this.$refs.memosGrid.excelExport();
    },
    onCloseClick() {
      this.hide();
    },
    onPosMemoCellInfo(args){
      const {
        cell,
        column: {field},
        data,
      } = args;
      if(field === "memoContents"){
        cell.style.whiteSpace = "break-spaces";
        if(data.isMemoConfirm) {
          cell.style.color = "rgb(170, 170, 170)";
        }
      }
    },
    async memoCheckClick(bt, data) {
      const memoIds =
          await this.filteredMemoViews
              .filter(item =>
                  !item?.isTimeLine && // 타임라인은 제외.
                  ["%", this.currentView].includes(item?.recptnDept) && // 수신부서가 공통이거나 현재 부서.
                  (item?.sendDept !== this.currentView || (item?.sendDept === this.currentView && item?.insertId !== this.userId))) // 전달부서가 현재 부서는 제외.(현재 부서에서 공통으로 메모 입력한 경우를 위해)
              .map(item => item.memoId);

      if (!memoIds.includes(data.memoId)) {
        return;
      }

      await GolfErpAPI.reservationMemoConfirm({
        resveId: this.memoResveId,
        recptnDept: this.currentView,
        request: {
          memoIds: data.sendDept !== "ROOM" ? [data.memoId] : [],
          memoIdsByStay: data.sendDept === "ROOM" ? [data.memoId] : [],
        },
      });
      await this.fetchMemos();
      await this.$emit("fetchMemo");
    },
    // API
    async fetchMemos() {
      this.memos = (await GolfErpAPI.fetchStorePosMemo({
        storeCode: this.storeCode,
        bsnDate: moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD)
      })) || [];
    },
  },
};
</script>
