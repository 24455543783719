<template>
  <div>
    <div class="typePos-button typePos-prev">
      <!-- 비활성(Attribute) : disabled -->
      <erp-button
        button-div="GET"
        :use-syncfusion-component-style="false"
        :disabled="currentWindowIndex === 0"
        @click="currentWindowIndex = currentWindowIndex - 1"
      >
        이전
      </erp-button>
    </div>
    <div ref="window" class="typePos-list">
      <!-- style="width: 374px; margin-left: 0px;" -->
      <!-- width(Style) : 자식 li의 width를 모두 더한 값 -->
      <!-- margin-left(Style) : 이전 클릭시 플러스, 다음 클릭시 마이너스 누적 값 -->
      <ul class="typePos-wrap">
        <!-- 활성(Class) : typePos-active -->
        <template v-if="!initWindows">
          <li
            ref="categories"
            v-for="(category, index) in items"
            v-bind:key="`category-${uid}-${category.code}`"
            :id="`category-${uid}-${category.code}`"
            :class="{
              'typePos-active': index === selectedIndex,
            }"
            @click="onCategoryClick($event, { index, category })"
          >
            <div class="typePos-item">
              {{ category.name }}
            </div>
          </li>
        </template>
        <template v-else>
          <li
            v-for="({ category }, index) in windows[currentWindowIndex]"
            v-bind:key="`window-${uid}-${category.code}`"
            :class="{
              'typePos-active': category.code === items[selectedIndex].code,
            }"
            @click="onCategoryClick($event, { index, category })"
          >
            <div class="typePos-item">
              {{ category.name }}
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div class="typePos-button typePos-next">
      <!-- 비활성(Attribute) : disabled -->
      <erp-button
        button-div="GET"
        :use-syncfusion-component-style="false"
        :disabled="currentWindowIndex === windows.length - 1"
        @click="currentWindowIndex = currentWindowIndex + 1"
      >
        다음
      </erp-button>
    </div>
  </div>
</template>

<script>
import { v4 as createUUID } from "uuid";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "Categories",
  components: {
    ErpButton,
  },
  props: {
    value: {},
    items: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.uid = createUUID();
  },
  watch: {
    items() {
      this.initWindows = false;
      this.$nextTick(() => {
        this.setWindows();
      });
    },
  },
  data() {
    return {
      uid: "",

      // matrix
      initWindows: false,
      windows: [],
      currentWindowIndex: 0,
    };
  },
  computed: {
    selectedIndex() {
      const selectedIndex = this.items.findIndex(
        ({ code }) => code === this.value
      );

      return selectedIndex < 0 ? 0 : selectedIndex;
    },
  },
  methods: {
    setWindows() {
      const refWindow = this.$refs["window"];
      const refCategories = this.$refs["categories"];

      this.currentWindowIndex = 0;
      if (!refCategories || refCategories.length === 0) {
        this.windows = [];
      } else {
        const windowWidth = refWindow.getBoundingClientRect().width;
        let refWindows = [];
        let widthTemp = 0;
        let windowTemp = [];
        for (let i = 0; i < refCategories.length; i++) {
          const refCategory = refCategories[i];
          const categoryWidth = refCategory.getBoundingClientRect().width;

          if (windowWidth < widthTemp + categoryWidth) {
            widthTemp = categoryWidth;
            refWindows.push(windowTemp);
            windowTemp = [refCategory];
          } else {
            windowTemp.push(refCategory);
            widthTemp = widthTemp + categoryWidth;
          }

          if (i + 1 === refCategories.length && windowTemp.length !== 0) {
            refWindows.push(windowTemp);
          }
        }

        this.windows = refWindows.map((refCategories) =>
          refCategories.map((refCategory) => ({
            ref: refCategory,
            category: this.items.find(
              ({ code }) =>
                code === refCategory.id.replace(`category-${this.uid}-`, "")
            ),
          }))
        );
        this.initWindows = true;
      }
    },

    onCategoryClick(event, { index, category }) {
      this.$emit("input", category.code);
      this.$emit("select", event, { index, category });
    },
  },
};
</script>

<style scoped></style>
