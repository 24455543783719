var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ejs-dialog',_vm._b({ref:"dialog",attrs:{"animationSettings":{ effect: 'None' }},on:{"close":_vm.onDialogClose}},'ejs-dialog',_vm.dialogProps,false),[_c('div',{staticClass:"typePos",style:({
        minWidth: ((_vm.dialogProps.width) + "px"),
        minHeight: ((_vm.dialogProps.height - 66) + "px"),
      })},[_c('div',{staticClass:"typePos-wrapper-caption"},[_c('div',{staticClass:"typePos-header"},[_c('div',{staticClass:"typePos-filed"},[_c('div',{staticClass:"typePos-filed-content"},[_c('div',{staticClass:"typePos-item"},[_c('div',{staticClass:"typePos-content"},[_vm._v(" "+_vm._s(_vm.currentTimeLocaleString)+" ")])])])])]),_c('div',{staticClass:"typePos-footer"},[_c('div',{staticClass:"typePos-filed"},[_c('div',{staticClass:"typePos-filed-content"},[_c('div',{staticClass:"typePos-item"},[_c('div',{staticClass:"typePos-content"},[_c('em',[_vm._v(_vm._s(_vm.currentStoreTableName)+"번")]),_vm._v(" 테이블 이동 ")])])])])])]),_c('div',{staticClass:"typePos-wrapper-main"},[_c('ul',{staticClass:"typePos-main-body"},_vm._l((_vm.storeTables),function(storeTable,index){return _c('li',{key:("pos-storeTables-" + (storeTable.id) + "-" + index),class:{
              'typePos-active':
                _vm.selectStoreTable && storeTable.id === _vm.selectStoreTable.id,
            },style:({ width: ("calc(100% / " + _vm.maxColIndex + ")") })},[_c('erp-button',{class:[!storeTable.isUse ? 'typePos-unused' : ''],attrs:{"button-div":"SAVE","disabled":!storeTable.isUse ||
                storeTable.id === _vm.currentStoreTableId ||
                (storeTable.slips && 0 < storeTable.slips.length),"use-syncfusion-component-style":false},on:{"click":function($event){return _vm.onStoreTableClick($event, { storeTable: storeTable })}}},[_c('div',{staticClass:"typePos-header"},[(storeTable.isUse)?[_vm._v(" "+_vm._s(storeTable.name)+" ")]:_vm._e()],2),_c('div',{staticClass:"typePos-body",style:({
                  backgroundColor: storeTable.isUse
                    ? storeTable.colorValue
                    : undefined,
                })},[_c('div',{staticClass:"typePos-state"},[(!storeTable.isUse)?void 0:(
                      storeTable.slips && 0 < storeTable.slips.length
                    )?[_vm._v(" 사용중 ")]:[_vm._v(" 이동 ")]],2)])])],1)}),0)]),_c('div',{staticClass:"typePos-wrapper-footer"},[_c('ul',{staticClass:"typePos-button"},[_c('li',[_c('erp-button',{staticClass:"ui-button",attrs:{"button-div":"SAVE","use-syncfusion-component-style":false,"disabled":_vm.slip.payPreOrFutureDivision !== '1' ? true : false},nativeOn:{"click":function($event){return _vm.onTableActivation($event)}}},[_c('div',{staticClass:"i-item"},[_vm._v("테이블 복귀")])])],1),_c('li',[_c('erp-button',{staticClass:"ui-button i-iconClose",attrs:{"button-div":"SAVE","use-syncfusion-component-style":false,"is-custom-shortcut-button":true,"shortcut":_vm.refreshShortcutButtonProps.shortcut,"shortcut-key":_vm.refreshShortcutButtonProps.shortcutKey,"visible-shortcut-string":false},nativeOn:{"click":function($event){return _vm.onRefreshClick($event)}}},[_c('div',{staticClass:"i-item"},[_vm._v(" 새로고침 ("+_vm._s(_vm.toShortcutString(_vm.refreshShortcutButtonProps.shortcut))+") ")])])],1),_c('li',[_c('erp-button',{staticClass:"ui-button i-iconClose",attrs:{"button-div":"CLOSE","use-syncfusion-component-style":false},on:{"click":_vm.onCloseClick}},[_c('div',{staticClass:"i-item"},[_vm._v("닫기")])])],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }