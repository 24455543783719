<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-caption">
          <div class="typePos-header">
            <div class="typePos-filed">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="typePos-content">
                    {{ currentTimeLocaleString }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="typePos-footer">
            <div class="typePos-filed">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="typePos-content">
                    <em>{{ currentStoreTableName }}번</em> 테이블 이동
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-main">
          <ul class="typePos-main-body">
            <!-- 활성(Class) : typePos-active -->
            <!-- 가로크기(Style) : 100% / 가로나열 개수 -->
            <li
              v-for="(storeTable, index) in storeTables"
              v-bind:key="`pos-storeTables-${storeTable.id}-${index}`"
              :style="{ width: `calc(100% / ${maxColIndex})` }"
              :class="{
                'typePos-active':
                  selectStoreTable && storeTable.id === selectStoreTable.id,
              }"
            >
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                :class="[!storeTable.isUse ? 'typePos-unused' : '']"
                :disabled="
                  !storeTable.isUse ||
                  storeTable.id === currentStoreTableId ||
                  (storeTable.slips && 0 < storeTable.slips.length)
                "
                :use-syncfusion-component-style="false"
                @click="onStoreTableClick($event, { storeTable })"
              >
                <div class="typePos-header">
                  <template v-if="storeTable.isUse">
                    {{ storeTable.name }}
                  </template>
                </div>
                <div
                  class="typePos-body"
                  :style="{
                    backgroundColor: storeTable.isUse
                      ? storeTable.colorValue
                      : undefined,
                  }"
                >
                  <div class="typePos-state">
                    <template v-if="!storeTable.isUse" />
                    <template
                      v-else-if="
                        storeTable.slips && 0 < storeTable.slips.length
                      "
                    >
                      사용중
                    </template>
                    <template v-else> 이동 </template>
                  </div>
                </div>
              </erp-button>
            </li>
          </ul>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <erp-button
                button-div="SAVE"
                class="ui-button"
                :use-syncfusion-component-style="false"
                :disabled="slip.payPreOrFutureDivision !== '1' ? true : false"
                @click.native="onTableActivation"
              >
                <div class="i-item">테이블 복귀</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                :is-custom-shortcut-button="true"
                :shortcut="refreshShortcutButtonProps.shortcut"
                :shortcut-key="refreshShortcutButtonProps.shortcutKey"
                :visible-shortcut-string="false"
                @click.native="onRefreshClick"
              >
                <div class="i-item">
                  새로고침 ({{
                    toShortcutString(refreshShortcutButtonProps.shortcut)
                  }})
                </div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-caption {padding: 10px; border-bottom: 1px solid #bebebe;}
.typePos .typePos-wrapper-caption::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed,
.typePos .typePos-wrapper-caption .typePos-lookup {float: left;}
.typePos .typePos-wrapper-caption .typePos-filed {padding: 0 3px;}
.typePos .typePos-wrapper-caption .typePos-lookup {padding: 0 10px;}
.typePos .typePos-wrapper-caption .typePos-filed::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content {float: left; padding: 0 7px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title {padding-top: 10px; padding-bottom: 11px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item {float: left; padding-left: 10px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item:first-child {padding-left: 0;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content {float: left; padding-top: 10px; padding-bottom: 11px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title {padding-right: 4px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content strong {color: #18b3c9; font-weight: normal;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content em {color: #df2929; font-style: normal;}
.typePos .typePos-wrapper-caption .typePos-lookup >>> .ui-button .i-item::before {background-position-x: calc(-20px * 7);}
.typePos .typePos-wrapper-caption .typePos-header {float: left;}
.typePos .typePos-wrapper-caption .typePos-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-footer {float: right;}
.typePos .typePos-wrapper-caption .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}

.typePos .typePos-wrapper-main {flex: 1; overflow: auto;}
.typePos .typePos-wrapper-main .typePos-main-body {box-sizing: border-box; list-style: none; padding: 13px; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-body::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-body > li {box-sizing: border-box; float: left; padding: 7px;}
.typePos .typePos-wrapper-main .typePos-main-body > li >>> button {display: flex; flex-direction: column; box-sizing: border-box; box-shadow: none; width: 100%; height: 101px; padding: 0; margin: 0; border: 1px solid #e0e0e0; border-radius: 4px; background-color: #fff;}
.typePos .typePos-wrapper-main .typePos-main-body > li >>> button:hover {background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-body > li >>> button:disabled {opacity: 0.5;}
.typePos .typePos-wrapper-main .typePos-main-body > li >>> button:disabled:hover {background-color: #fff;}
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active >>> button,
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active >>> button:hover {background-color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active >>> button:disabled,
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active >>> button:disabled:hover {background-color: #fff;}
.typePos .typePos-wrapper-main .typePos-main-body > li >>> button .typePos-header {width: 100%; min-height: 19px; padding: 8px 0 9px 0; border-bottom: 1px solid #e0e0e0; border-radius: 3px 3px 0 0; color: #666; font-size: 13px; font-family: 'NanumSquare-Bold'; line-height: 19px; background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-body > li >>> button .typePos-body {flex: 1; overflow: hidden; position: relative; width: 100%;}
.typePos .typePos-wrapper-main .typePos-main-body > li >>> button .typePos-body .typePos-state {position: absolute; top: 50%; left: 0; width: 100%; margin-top: -10px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; text-align: center;}
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active >>> button .typePos-body .typePos-state {color: #fff;}
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-active >>> button:disabled .typePos-body .typePos-state {color: #666;}
.typePos .typePos-wrapper-main .typePos-main-body > li.typePos-chit >>> button .typePos-body .typePos-state {display: none;}
.typePos .typePos-wrapper-main .typePos-main-body > li >>> button.typePos-unused .typePos-header {display: none;}
.typePos .typePos-wrapper-main .typePos-main-body > li >>> button.typePos-unused .typePos-body {height: 100%; background-color: #f9f9f9;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
</style>

<script>
import GolfERPService from "@/service/GolfERPService";
import moment from "moment";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import { orderBy as _orderBy, maxBy as _maxBy } from "lodash";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { mapGetters } from "vuex";
import { SHORTCUT_KEYS, toShortcutString } from "@/utils/KeyboardUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

function toLocaleStringDayOfWeek(dayOfWeek) {
  switch (dayOfWeek) {
    case 1:
      return "월";
    case 2:
      return "화";
    case 3:
      return "수";
    case 4:
      return "목";
    case 5:
      return "금";
    case 6:
      return "토";
    case 7:
      return "일";
  }

  return "";
}

export default {
  name: "ChangeTablePopup",
  components: { ErpButton },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      slip: {},
      callback: () => {},

      storeTables: [],

      selectStoreTable: null,
    };
  },
  computed: {
    ...mapGetters("pos", ["salesDate", "store"]),
    refreshShortcutButtonProps() {
      return {
        shortcutKey: "ChangeTablePopup.shortcuts.refresh",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F2,
        },
        visibleShortcutString: false,
      };
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "테이블 이동",
        width: 760,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    currentTimeLocaleString() {
      const salesDateMoment = moment(this.salesDate);
      return salesDateMoment.format(
        `YYYY년 MM월 DD일 (${toLocaleStringDayOfWeek(
          salesDateMoment.isoWeekday()
        )})`
      );
    },
    currentStoreTableId() {
      return this.slip?.storeTableId;
    },
    currentStoreTableName() {
      return (
        this.storeTables.find(({ id }) => id === this.currentStoreTableId)
          ?.name || "N/A"
      );
    },
    maxRowIndex() {
      return _maxBy(this.storeTables, "rowIndex")?.rowIndex || 1;
    },
    maxColIndex() {
      return _maxBy(this.storeTables, "columnIndex")?.columnIndex || 1;
    },
  },
  methods: {
    show({ currentSlip, callback }) {
      this.slip = currentSlip;
      this.callback = callback;

      this.fetchPosStoreTables();
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },

    toShortcutString,

    onDialogClose() {
      this.$emit("close");
    },
    onStoreTableClick(event, { storeTable }) {
      this.selectStoreTable = storeTable;
      this.$EventBus.$emit("pos-confirm", {
        message: `${this.currentStoreTableName}번 -> ${storeTable.name}번 테이블 이동하시겠습니까?`,
        callback: async (confirmed) => {
          if (confirmed) {
            try {
              this.callback({ storeTable });
              this.hide();
            } catch (e) {
              console.error(e);
              this.errorToast("Error");
            } finally {
              this.selectStoreTable = null;
            }
          } else {
            this.selectStoreTable = null;
          }
        },
      });
    },
    onRefreshClick() {
      this.fetchPosStoreTables();
    },
    onCloseClick() {
      this.hide();
    },

    // API
    async fetchPosStoreTables() {
      this.storeTables = _orderBy(
        (await GolfERPService.fetchPosTables(
          this.store.code,
          moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD)
        )) || [],
        "order"
      );
    },
    async onTableActivation() {
      await GolfErpAPI.patchTableActivation(this.slip.id);

      this.infoToast("테이블 복귀 작업이 완료되었습니다");

      this.slip.payPreOrFutureDivision = "2";

      this.onRefreshClick();
    }
  },
};
</script>
