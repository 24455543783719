<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-main">
          <div class="typePos-main-header">
            <div class="typePos-header-body">
              <ejs-grid-wrapper
                ref="visitorsOfTeamsGrid"
                v-bind="visitorsOfTeamsGridProps"
                @queryCellInfo="onVisitorsOfTeamsGridQueryCellInfo"
                @recordClick="onVisitorsOfTeamsGridRecordClick"
                @rowSelected="onVisitorsOfTeamsGridRowSelected"
                @onGridDialogDoubleClickedOrEnterKeyed="
                  onVisitorsOfTeamsGridRecordDoubleClickOrEnterKey
                "
                @dataBound="onVisitorsOfTeamsGridDataBound"
              />
            </div>
            <div
              class="typePos-header-footer"
              :class="{ 'typePos-open': visibleDetailsLayer }"
            >
              <div class="typePos-header">
                <erp-button
                  button-div="GET"
                  :use-syncfusion-component-style="false"
                  @click="onCollapseClick"
                >
                  열기 / 닫기
                </erp-button>
              </div>
              <div class="typePos-body">
                <!-- width(Style) : 자식 li의 크기를 모두 더한 값 -->
                <ul v-if="selectRow" class="typePos-list" style="width: 226px">
                  <li>
                    <div class="typePos-card">
                      <div
                        class="typePos-photo"
                        :style="{
                          backgroundImage:
                            selectRow.membership &&
                            selectRow.membership.member &&
                            selectRow.membership.member.imageFile &&
                            selectRow.membership.member.imageFile.path
                              ? `url('${selectRow.membership.member.imageFile.path}')`
                              : undefined,
                        }"
                      >
                        사진
                      </div>
                      <div class="typePos-name">
                        {{ selectRow.name ? selectRow.name : ""
                        }}{{
                          selectRow.membership && selectRow.membership.no
                            ? `(${selectRow.membership.no})`
                            : ""
                        }}
                      </div>
                      <div class="typePos-content">
                        {{
                          selectRow.memberDivision
                            ? getMemberDivisionName(selectRow.memberDivision)
                            : ""
                        }}
                        /
                        {{
                          selectRow.membership &&
                          selectRow.membership.corporateDivision
                            ? getCorporateDivisionName(
                                selectRow.membership.corporateDivision
                              )
                            : ""
                        }}<br />
                        {{
                          selectRow.membership &&
                          selectRow.membership.corporationName
                            ? selectRow.membership.corporationName
                            : ""
                        }}
                      </div>
                      <div class="typePos-contact">
                        {{
                          selectRow.membership &&
                          selectRow.membership.member &&
                          selectRow.membership.member.phoneNumber
                            ? selectRow.membership.member.phoneNumber
                            : ""
                        }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="typePos-main-footer">
            <div class="typePos-header">
              <div class="typePos-filed">
                <div class="ui-input">
                  <!-- 비활성(Attribute) : disabled -->
                  <input
                    ref="input-q"
                    type="text"
                    placeholder="내장객/락카/카트/캐디명/단체명"
                    v-model="q"
                    @keypress.enter="onSearchVisitorsClick"
                  />
                </div>
              </div>
              <div class="typePos-filed">
                <ul class="typePos-check">
                  <li>
                    <label>
                      <input type="radio" v-model="isCheckOut" :value="false" />
                      <div class="i-item">체크인</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="radio" v-model="isCheckOut" :value="true" />
                      <div class="i-item">체크아웃</div>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="radio" v-model="isCheckOut" :value="null" />
                      <div class="i-item">전체</div>
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="typePos-body">
              <ul class="typePos-list">
                <li class="typePos-1">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '1' })"
                  >
                    1
                  </erp-button>
                </li>
                <li class="typePos-2">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '2' })"
                  >
                    2
                  </erp-button>
                </li>
                <li class="typePos-3">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '3' })"
                  >
                    3
                  </erp-button>
                </li>
                <li class="typePos-4">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '4' })"
                  >
                    4
                  </erp-button>
                </li>
                <li class="typePos-5">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '5' })"
                  >
                    5
                  </erp-button>
                </li>
                <li class="typePos-6">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '6' })"
                  >
                    6
                  </erp-button>
                </li>
                <li class="typePos-7">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '7' })"
                  >
                    7
                  </erp-button>
                </li>
                <li class="typePos-8">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '8' })"
                  >
                    8
                  </erp-button>
                </li>
                <li class="typePos-9">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '9' })"
                  >
                    9
                  </erp-button>
                </li>
                <li class="typePos-0">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '0' })"
                  >
                    0
                  </erp-button>
                </li>
                <li class="typePos-00">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: '00' })"
                  >
                    00
                  </erp-button>
                </li>
                <li class="typePos-clear">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: 'Clear' })"
                  >
                    CL
                  </erp-button>
                </li>
                <li class="typePos-back">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: 'Backspace' })"
                  >
                    뒤로
                  </erp-button>
                </li>
                <li class="typePos-enter">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, { value: 'Enter' })"
                  >
                    Enter
                  </erp-button>
                </li>
                <li class="typePos-input">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onInputClick"
                  >
                    입력
                  </erp-button>
                </li>
                <li class="typePos-lookup">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    :ignore="isPopupOpened"
                    :is-shortcut-button="true"
                    :visible-shortcut-string="false"
                    @click="onSearchVisitorsClick"
                  >
                    <div class="i-item">
                      조회<br />({{ searchShortcutButtonShortcutString }})
                    </div>
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                class="ui-button i-typePrimary i-iconConfirm"
                :use-syncfusion-component-style="false"
                @click="onSubmitClick"
              >
                <div class="i-item">확인</div>
              </erp-button>
            </li>
            <li class="typePos-refresh">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button"
                :use-syncfusion-component-style="false"
                @click="onRefreshClick"
              >
                <div class="i-item">새로고침</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <bill-list-popup
      ref="bill-list-popup"
      v-if="visibleBillListPopup"
      :sales-date="salesDate"
      :store="store"
      @submit="onBillListPopupSubmit"
      @close="onBillListPopupClose"
    />
    <memo-view-popup
        ref="memoViewPopup"
        v-if="visibleMemoViewPopup"
        @close="onMemoViewPopupClose"
        @memoChange="fetchTeams"
    />
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {display: flex; flex-direction: row; flex: 1; overflow: hidden;}
.typePos .typePos-wrapper-main::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header {display: flex; flex-direction: column; flex: 1; float: left; overflow: hidden;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-body {flex: 1; overflow: hidden;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer {position: relative; padding-top: 21px; border-top: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer::before {display: block; position: absolute; top: 20px; left: 0; width: 100%; height: 1px; background-color: #bebebe; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-header {position: absolute; top: 6px; left: 50%; margin-left: -7px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-header button {box-shadow: none; position: relative; overflow: hidden; width: 41px; height: 15px; padding: 0; margin: 0; border: none; background: transparent url('../../../../../assets/images/ui/arrow-type07.png') no-repeat left top; text-indent: -1000px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-header button:hover {background-position-y: bottom;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-body {box-sizing: border-box; position: relative; min-height: 20px; overflow: auto;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-body .typePos-list {display: none; list-style: none; padding: 20px 15px; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-body .typePos-list::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-body .typePos-list > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-body .typePos-list > li .typePos-card {overflow: hidden; position: relative; box-sizing: border-box; width: 216px; height: 97px; border: 1px solid #e0e0e0; border-radius: 4px; background-color: #f5f5f5;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-body .typePos-list > li .typePos-card .typePos-photo {overflow: hidden; box-sizing: border-box; position: absolute; top: 10px; left: 10px; width: 56px; height: 75px; border: 1px solid #e0e0e0; border-radius: 4px; background-color: #fff; background-repeat: no-repeat; background-position: center center; background-size: cover; text-indent: -1000px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-body .typePos-list > li .typePos-card .typePos-name,
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-body .typePos-list > li .typePos-card .typePos-content,
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-body .typePos-list > li .typePos-card .typePos-contact {position: absolute; left: 81px; color: #666; font-size: 12px; font-family: 'NanumSquare-Regular'; line-height: 18px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-body .typePos-list > li .typePos-card .typePos-name {top: 13px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-body .typePos-list > li .typePos-card .typePos-content {top: 31px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer .typePos-body .typePos-list > li .typePos-card .typePos-contact {bottom: 10px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer.typePos-open .typePos-header button {background-position-x: right;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer.typePos-open .typePos-body {min-height: 137px;}
.typePos .typePos-wrapper-main .typePos-main-header .typePos-header-footer.typePos-open .typePos-body .typePos-list {display: block;}
.typePos .typePos-wrapper-main .typePos-main-footer {display: flex; flex-direction: column; float: left; width: 325px; border-left: 1px solid #bebebe;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header {padding: 15px 20px; border-bottom: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li {float: left; width: calc((100% / 3) + 1px); margin-left: -1px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li:first-child {width: calc(100% / 3); margin-left: 0; border-radius: 4px 0 0 4px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label {display: block; position: relative;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input {box-shadow: none; position: absolute; top: 0; left: 0; width: 100%; height: 100%; padding: 0; margin: 0; border-radius: 0; opacity: 0; z-index: 10;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input + .i-item {min-height: 19px; padding: 9px 0 10px 0; border: 1px solid #e0e0e0; background-color: #fff; color: #999; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; text-align: center;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input:hover + .i-item {background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input:checked + .i-item,
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input:checked:hover + .i-item {background-color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input:checked + .i-item {color: #fff; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li:first-child  label input + .i-item {border-radius: 4px 0 0 4px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li:last-child  label input + .i-item {border-radius: 0 4px 4px 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body {flex: 1; position: relative; width: 325px; background-color: #f5f5f5;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li {position: absolute;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li button {display: block; box-sizing: border-box; box-shadow: 0 2px 2px 0 rgba(0, 0 , 0, 0.05); width: 72px; height: 45px; padding: 5px; margin: 0; border: none; border-radius: 4px; background-color: #fff; color: #666; font-size: 16px; font-family: 'NanumSquare-Bold'; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li button:hover {background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-1 {top: 116px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-2 {top: 116px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-3 {top: 116px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-4 {top: 68px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-5 {top: 68px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-6 {top: 68px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-7 {top: 20px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-8 {top: 20px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-9 {top: 20px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-0 {top: 164px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-00 {top: 164px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-clear {top: 164px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-back {top: 212px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-enter {top: 212px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-input {top: 20px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-lookup {top: 116px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-clear button {font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-back button {overflow: hidden; text-indent: -1000px; background-image: url('../../../../../assets/images/ui/icon-type09.png'); background-repeat: no-repeat; background-position: center center;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-enter button {width: 147px; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-input button {width: 60px; height: 93px; background-color: #4f5963; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-input button:hover {background-color: #333c44;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-lookup button {width: 60px; height: 141px; background-color: #18b3c9; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-lookup button:hover {background-color: #0f9eb8;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-refresh .ui-button .i-item::before {background-position-x: calc(-20px * 6);}
</style>

<script>
import { flatten as _flatten, orderBy as _orderBy } from "lodash";
import GolfERPService from "@/service/GolfERPService";
import moment from "moment";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {
  commonCodesGetColorValue, commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetComName, commonCodesGetSortNo,
} from "@/utils/commonCodes";
import { TIME_FORMAT_hh_mm } from "@/utils/time";
import { ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import BillListPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/BillListPopup";
import { mapActions, mapGetters,  mapState } from "vuex";
import {
  getSearchButtonShortcut,
  toShortcutString,
} from "@/utils/KeyboardUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import memoViewPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/MemoViewPopup";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "SearchVisitorsPopup",
  components: { BillListPopup, EjsGridWrapper,memoViewPopup, ErpButton },
  mixins: [commonMixin, confirmDialogMixin],
  props: {
    salesDate: {
      type: Date,
      required: true,
    },
    store: {
      type: Object,
      required: true,
    },
  },
  watch: {
    isCheckOut() {
      this.fetchTeams();
    },
  },
  data() {
    return {
      teams: [],

      selectRow: null,
      visibleMemoViewPopup:false,
      q: "",
      isCheckOut: false,
      callback: () => {},

      visibleDetailsLayer: false,
      visibleBillListPopup: false,
    };
  },
  computed: {
    ...mapGetters("pos", [
      "slip",
    ]),
    ...mapState("memoView", [
      "memoViews",
      "currentView",
      "memoResveId",
      "memoInfo",
    ]),
    isPopupOpened() {
      return this.visibleBillListPopup;
    },
    searchShortcutButtonShortcutString() {
      return toShortcutString(getSearchButtonShortcut());
    },
    storeCodeIdx3(){
      return commonCodesGetCommonCodeAttrbByCodeAndIdx('STORE_CODE', this.store.value, 3);
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "내장객 찾기",
        width: 1160,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    visitorsOfTeamsGridProps() {
      const dataSource = _orderBy(
        _flatten(
          this.teams.map((team) => {
            team.visitors.forEach((visitor) => {
              visitor.team = team;
              visitor.courseCodeSortNo = commonCodesGetSortNo("COURSE_CODE", team.course);
            });

            return team.visitors;
          })
        ).map(
          (
            {
              id,
              name,
              lockerNumber,
              memberDivision,
              team: {
                id: teamId,
                time,
                course,
                checkOutDateTime,
                isCheckOut,
                isGalleryTeam,
                isPaying,
                payingId,
                groupName,
                teamCaddies,
                memoCount,
                recptnMemoCount,
                memoConfirmCount,
                reservationId,
              },
              slips,
              courseCodeSortNo,
            },
            index
          ) => ({
            _rid: index + 1,
            id,
            teamId,
            name,
            isPaying,
            payingId,
            memoCount,
            recptnMemoCount,
            memoConfirmCount,
            reservationId,
            lockerNo: lockerNumber || "",
            memberDiv: memberDivision || "",
            billCount: slips?.length || 0,
            startTime: time || "",
            startCourse: course,
            courseCodeSortNo,
            groupName: groupName || "",
            caddieNames:
              teamCaddies?.map(({ caddie: { name } }) => name).join(", ") || "",
            cartNo:
              teamCaddies
                ?.map(({ cartNumber }) => cartNumber)
                .filter((cartNo) => !!cartNo)
                .join(", ") || "",
            checkoutTime: checkOutDateTime
              ? moment(checkOutDateTime).format(TIME_FORMAT_hh_mm)
              : "",
            isCheckOut,
            isGalleryTeam
          })
        ) || [],
        ["isGalleryTeam", "startTime", "courseCodeSortNo", "id"],
          ["desc", "asc", "asc", "asc"]
      );

      dataSource.forEach((visitor, index) => {
        if (index === 0) {
          return;
        }

        const { teamId: beforeTeamId } = dataSource[index - 1];
        if (visitor.teamId !== beforeTeamId) {
          visitor.isPartisionRow = true;
        }
      });

      return {
        allowFiltering: true,
        allowGrouping: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: [
          {
            field: "teamId",
            headerText: "팀",
            visible: false,
          },
          {
            field: "id",
            visible: false,
          },
          {
            field: "isPaying",
            visible: false,
          },
          {
            field: "payingId",
            visible: false,
          },
          {
            field: "isCheckOut",
            visible: false,
          },
          {
            field: "name",
            headerText: "내장객명",
            textAlign: "Centor",
            width: 107,
          },
          {
            field: "memo",
            headerText: "메모",
            textAlign: "Centor",
            width: 70,
          },
          {
            field: "billCount",
            headerText: "Bill",
            textAlign: "Center",
            width: 76,
          },
          {
            field: "lockerNo",
            headerText: "락카",
            textAlign: "Center",
            width: 64,
          },
          {
            field: "startTime",
            headerText: "시간",
            textAlign: "Center",
            width: 84,
          },
          {
            groupCode: "COURSE_CODE",
            field: "startCourse",
            isCommonCodeField: true,
            headerText: "코스",
            textAlign: "Center",
            width: 104,
          },
          {
            field: "groupName",
            headerText: "단체명",
            textAlign: "Left",
            width: 115,
          },
          {
            field: "caddieNames",
            headerText: "캐디",
            textAlign: "Left",
            width: 104,
          },
          {
            field: "cartNo",
            headerText: "카트",
            textAlign: "Center",
            width: 104,
          },
          {
            groupCode: "MEMBER_DIV",
            field: "memberDiv",
            isCommonCodeField: true,
            headerText: "회원구분",
            textAlign: "Center",
            width: 127,
          },
          {
            field: "checkoutTime",
            headerText: "C/O",
            textAlign: "Center",
            width: 100,
          },
        ],
        dataSource,
        isInPopup: true,
        isSelectedRowRetain: false,
        provides: [ForeignKey, Resize],
        selectionSettings: { type: "Single", enableToggle: false },
        useCommonStyles: false,
      };
    },
  },
  methods: {
    ...mapActions('memoView', ['setResveId','setCurrentView','clearMemo']),
    show({ lockerNumber = "", visitorName = "", callback = () => {} } = {}) {
      if (lockerNumber) {
        this.q = lockerNumber;
      } else if (visitorName) {
        this.q = visitorName;
      }

      this.callback = callback;

      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },

    getMemberDivisionName(memberDivision) {
      return commonCodesGetComName("MEMBER_DIV", memberDivision) || "";
    },
    getCorporateDivisionName(corporateDivision) {
      return commonCodesGetComName("CPR_DIV", corporateDivision) || "";
    },
    async selectRecord({ id: visitorId, teamId }) {
      const team = this.teams.find((team) => team.id === teamId);
      const visitor = team.visitors.find((visitor) => visitor.id === visitorId);

      if (
        team.isPaying &&
        team.payingId &&
        `${team.payingId}` !== `${this.userId}`
      ) {
        this.errorToast(
          "현재 프론트 정산 중입니다. 프론트에 연락 후 LOCK 해제 요청바랍니다. "
        );
        return;
      } else {
        await GolfErpAPI.fetchPayLock(team.id, true);
      }

      this.callback({
        visitor: {
          ...visitor,
          team,
        },
      });
    },

    onDialogClose() {
      this.$emit("close");
    },
    onNumpadButtonClick(event, { value }) {
      switch (value) {
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case "0":
        case "00":
          this.q = `${this.q}${value}`;
          break;
        case "Clear":
          this.q = "";
          break;
        case "Backspace":
          if (this.q) {
            this.q = this.q.substr(0, this.q.length - 1);
          }
          break;
        case "Enter":
          this.fetchTeams();
          break;
      }
    },
    onInputClick() {
      this.$refs["input-q"].focus();
    },
    onSearchVisitorsClick() {
      this.fetchTeams();
    },
    onVisitorsOfTeamsGridQueryCellInfo(args) {
      if (args.column.field === "startTime") {
        if (
          args.data.isPaying &&
          args.data.payingId &&
          `${args.data.payingId}` !== `${this.userId}`
        ) {
          args.cell.style.backgroundColor = commonCodesGetColorValue(
            "CHKIN_STATUS",
            "PAYING"
          );
        } else if (args.data.isCheckOut) {
          args.cell.style.backgroundColor = commonCodesGetColorValue(
            "CHKIN_STATUS",
            "CHKOUT"
          );
        }
      } else if (args.column.field === "billCount") {
        if (args.data.billCount > 0) {
          args.cell.style.cursor = "pointer";
          args.cell.style.textDecoration = "underline";
          // args.cell.classList.add(this.$t("className.grid.clickArea")); // 클릭영역 셀 처리
        } else {
          args.cell.innerText = '';
        }
      } else if(args.column.field === "memo") {
        const {
          data:{
            memoCount,
            recptnMemoCount,
            memoConfirmCount,
          }
        } = args;
        if (memoCount > 0) {
          if (recptnMemoCount > memoConfirmCount) {
            args.cell.classList.add("new-memo-img");
          } else {
            args.cell.classList.add("memo-img");
          }
        }
      }

      if (args.data.isPartisionRow) {
        args.cell.style.borderTop = "1px solid #000";
        // args.cell.classList.add(this.$t("className.grid.devReservationPartDivLine"));
      }
    },
    onVisitorsOfTeamsGridRowSelected(args) {
      const { id: visitorId, teamId } = args.data;
      const visitor = _flatten(
        this.teams
          .filter((team) => team.id === teamId)
          .map((team) => team.visitors)
      ).find((visitor) => visitor.id === visitorId);

      this.selectRow = visitor;
    },
    onVisitorsOfTeamsGridRecordClick(args) {
      if (args.column.field === "billCount") {
        if (0 < args.rowData.billCount) {
          this.visibleBillListPopup = true;
          this.$nextTick(() => {
            this.$refs["bill-list-popup"].show({ visitor: args.rowData });
          });
        }
      } else if(args.column.field === "memo"){
        const { rowData:{reservationId} } = args ;
        if(reservationId){
          this.clearMemo();
          this.setCurrentView(this.storeCodeIdx3);
          this.setResveId(reservationId);
          this.visibleMemoViewPopup = true;
        }
      }
    },
    onVisitorsOfTeamsGridRecordDoubleClickOrEnterKey() {
      const selectedRecords =
        this.$refs["visitorsOfTeamsGrid"].getSelectedRecords() || [];
      if (selectedRecords.length > 0) {
        const { isCheckOut } = selectedRecords[0];
        if (isCheckOut) {
          this.errorToast("체크아웃된 내장객은 선택 하실 수 없습니다.");
          return;
        }

        this.selectRecord(selectedRecords[0]);
      }
    },
    onVisitorsOfTeamsGridDataBound() {
      if (this.teams.length > 0) {
        const gridRef = this.$refs["visitorsOfTeamsGrid"];
        if (this.q) {
          const findIdx = gridRef
            .getBatchCurrentViewRecords()
            .findIndex((record) =>
              record.name.includes(this.q) ||
              record.lockerNo === this.q ||
              record.cartNo === this.q ||
              record.caddieNames.includes(this.q)
            );

          if (findIdx < 0) {
            gridRef.selectRow(0);
          } else {
            gridRef.selectRow(findIdx);
          }
        } else {
          if (this.selectRow) {
            const {id} = this.selectRow;

            const targetIndex = gridRef
              .getBatchCurrentViewRecords()
              .findIndex((record) => record.id === id);

            if (targetIndex < 0) {
              gridRef.selectRow(0);
            } else {
              gridRef.selectRow(targetIndex);
            }
          } else {
            gridRef.selectRow(0);
          }
        }
      } else {
        this.selectRow = null;
        this.$refs["input-q"].focus();
      }
    },
    onCollapseClick() {
      this.visibleDetailsLayer = !this.visibleDetailsLayer;
    },
    onSubmitClick() {
      const selectedItem = (this.$refs[
        "visitorsOfTeamsGrid"
      ].getSelectedRecords() || [])[0];
      if (!selectedItem) {
        this.errorToast("내장객을 선택해주세요");
        return;
      }

      const { isCheckOut } = selectedItem;
      if (isCheckOut) {
        this.errorToast("체크아웃된 내장객은 선택 하실 수 없습니다.");
        return;
      }

      this.selectRecord(selectedItem);
    },
    onRefreshClick() {
      this.fetchTeams();
    },
    onCloseClick() {
      this.hide();
    },
    onBillListPopupClose() {
      this.visibleBillListPopup = false;
    },
    onBillListPopupSubmit({ slip }) {
      this.$emit("submit", { slip });
      this.hide();
    },

    async fetchTeams() {
      this.teams =
        (await GolfERPService.fetchTeams(
          moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
          this.store.code,
          this.q || undefined,
          this.isCheckOut,
          undefined,
          undefined
        )) || [];
    },
    onMemoViewPopupClose() {
      this.visibleMemoViewPopup = false;
    }
  },
};
</script>
