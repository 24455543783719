<template>
  <div>
    <ejs-dialog ref="dialog" v-bind="dialogProps" @close="onDialogClose">
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-main">
          <ejs-grid-wrapper
            ref="slipsGrid"
            v-bind="slipsGridProps"
            @queryCellInfo="onSlipListGridQueryCellInfo"
            @onGridDialogDoubleClickedOrEnterKeyed="
              onSlipsGridDialogDoubleClickedOrEnterKeyed
            "
            @recordClick="onSlipListGridRecordClick"
            @dataBound="onSlipListGridDataBound"
          />
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li class="typePos-check">
              <ul class="ui-check">
                <li>
                  <label>
                    <!-- 비활성(Attribute) : disabled -->
                    <input type="checkbox" v-model="isDeletedSlip"/>
                    <div class="i-item">삭제전표제외</div>
                  </label>
                </li>
              </ul>
            </li>
            <li class="typePos-refresh">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button"
                :is-custom-shortcut-button="true"
                :shortcut-key="refreshShortcutButtonProps.shortcutKey"
                :shortcut="refreshShortcutButtonProps.shortcut"
                :visible-shortcut-string="false"
                :use-syncfusion-component-style="false"
                @click.native="onRefreshClick"
              >
                <div class="i-item">
                  새로고침 ({{
                    toShortcutString(refreshShortcutButtonProps.shortcut)
                  }})
                </div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button i-typePrimary i-iconConfirm"
                :use-syncfusion-component-style="false"
                @click.native="onSubmitClick"
              >
                <div class="i-item">확인</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click.native="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <!-- 전표메모 팝업 -->
    <slip-memo-list-popup
      ref="slip-memo-list-popup"
      v-if="visibleSlipMemoListPopup"
      :slip="slip"
      @close="onSlipMemoListPopupClose"
    />
  </div>
</template>

<style scoped>
.typePos{display:flex;flex-direction:column;box-sizing:border-box;width:calc(100% + 36px);height:calc(100% + 18px);margin:0 -18px -18px;border-top:1px solid #bebebe}
.typePos .typePos-wrapper-main{flex:1;overflow:hidden}
.typePos .typePos-wrapper-footer{padding:15px;border-top:1px solid #bebebe}
.typePos .typePos-wrapper-footer::after{display:block;clear:both;height:0;font-size:0;line-height:0;content:''}
.typePos .typePos-wrapper-footer .typePos-button{float:right;list-style:none;padding:0;margin:0}
.typePos .typePos-wrapper-footer .typePos-button::after{display:block;clear:both;height:0;font-size:0;line-height:0;content:''}
.typePos .typePos-wrapper-footer .typePos-button > li{float:left;padding:0 5px}
</style>

<script>
import GolfERPService from "@/service/GolfERPService";
import moment from "moment";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { commonCodesGetColorValue } from "@/utils/commonCodes";
import { Aggregate, ForeignKey, Resize, Sort } from "@syncfusion/ej2-vue-grids";
import SlipMemoListPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/SlipMemoListPopup";
import Vue from "vue";
import {SHORTCUT_KEYS, toShortcutString} from '@/utils/KeyboardUtil';
import { numberWithCommas } from "@/utils/number";
import { sumBy as _sumBy, maxBy as _maxBy } from "lodash";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "SlipListPopup",
  components: { EjsGridWrapper, SlipMemoListPopup, ErpButton },
  props: {
    salesDate: {
      type: Date,
      required: true,
    },
    store: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.fetchSlips();
  },
  data() {
    return {
      slip: {},
      slips: [],
      isDeletedSlip: true, // 삭제전표제외
      visibleSlipMemoListPopup: false
    };
  },
  computed: {
    refreshShortcutButtonProps() {
      return {
        shortcutKey: "SlipListPopup.shortcuts.refresh",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F2,
        },
        visibleShortcutString: false,
      };
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "전표 목록",
        width: 1160,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    slipsGridDataSource() {
      return this.slips.map(
          ({
            id,
            number,
            storeTableUses,
            sales,
            isPaid,
            payPreOrFutureDivision,
            visitor,
            memos,
            isDeleted,
            deleteReason,
            insertUser,
            insertDateTime,
          }) => ({
            id,
            number,
            storeTableName:
                _maxBy(storeTableUses || [], "insertDateTime")?.storeTable
                    ?.name || "N/A",
            groupProductName:
                !sales || sales.length === 0
                    ? ""
                    : sales.length === 1
                    ? `${sales[0].productName}`
                    : `${sales[0].productName} 외 ${sales.length - 1}건`,
            totalSalesAmount:
                sales
                    ?.map(({totalAmount}) => totalAmount || 0)
                    .reduce((acc, cur) => acc + cur, 0) || 0,
            paid: isPaid,
            payPreOrFutureDivision,
            visitorName: visitor?.name || "일반고객",
            visitorLockerNumber: visitor?.lockerNumber || "",
            courseTime: visitor?.team?.time || "",
            courseCode: visitor?.team?.course || "",
            groupName: visitor?.team?.groupName || "",
            checkOut: visitor?.team?.isCheckOut,
            checkOutDateTime: moment(visitor?.team?.checkOutDateTime).format("HH:mm"),
            memos: memos
                ?.filter(({isDeleted = false}) => !isDeleted).length,
            deleted: isDeleted,
            reasonDeleted: deleteReason,
            insertName: insertUser?.name || "",
            insertDate: insertDateTime,
          })
      );
    },
    slipsGridProps() {
      return {
        aggregates: [
          {
            columns: [
              {
                field: "groupProductName",
                type: "Custom",
                footerTemplate() {
                  return {
                    template: Vue.component("footerTemplate", {
                      template: `<div style="text-align: center">합계</div>`,
                    }),
                  };
                },
              },
              {
                field: "totalSalesAmount",
                type: "Custom",
                customAggregate: (data) => {
                  return _sumBy(
                    data.result,
                    ({ totalSalesAmount = 0, deleted = false }) =>
                      deleted ? 0 : totalSalesAmount
                  );
                },
                footerTemplate() {
                  return {
                    template: Vue.component("footerTemplate", {
                      template: `
                        <div style="text-align: right">{{ data.Custom | numberWithCommas }}</div>`,
                      filters: {
                        numberWithCommas,
                      },
                      data() {
                        return {
                          data: {},
                        };
                      },
                    }),
                  };
                },
              },
            ],
          },
        ],
        allowFiltering: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: [
          {
            field: "id",
            visible: false,
            isPrimaryKey: true,
          },
          {
            field: "number",
            headerText: "전표번호",
            textAlign: "Center",
            width: 127,
          },
          {
            field: "storeTableName",
            headerText: "테이블",
            textAlign: "Center",
            width: 115,
          },
          {
            field: "groupProductName",
            headerText: "상품명",
            textAlign: "Left",
            width: 115,
          },
          {
            field: "totalSalesAmount",
            headerText: "금액",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
            width: 104,
          },
          {
            displayAsCheckBox: true,
            editType: "booleanedit",
            field: "paid",
            headerText: "정산",
            textAlign: "Center",
            type: "boolean",
            width: 104,
          },
          {
            groupCode: "PRPAY_FRPY_DIV",
            field: "payPreOrFutureDivision",
            isCommonCodeField: true,
            headerText: "선/후불",
            textAlign: "Center",
            width: 120,
          },
          {
            field: "visitorName",
            headerText: "내장객",
            textAlign: "Left",
            width: 115,
          },
          {
            allowFiltering: false,
            field: "memo",
            headerText: "메모",
            textAlign: "Center",
            width: 38,
          },
          {
            field: "visitorLockerNumber",
            headerText: "락카",
            textAlign: "Center",
            width: 104,
          },
          {
            field: "courseTime",
            headerText: "시간",
            textAlign: "Center",
            width: 104,
          },
          {
            groupCode: "COURSE_CODE",
            field: "courseCode",
            isCommonCodeField: true,
            headerText: "코스",
            textAlign: "Center",
            width: 104,
          },
          {
            field: "groupName",
            headerText: "단체명",
            textAlign: "Center",
            width: 115,
          },
          {
            displayAsCheckBox: true,
            editType: "booleanedit",
            field: "deleted",
            headerText: "삭제",
            textAlign: "Center",
            type: "boolean",
            width: 104,
          },
          {
            field: "reasonDeleted",
            headerText: "삭제사유",
            textAlign: "Left",
            width: 127,
          },
          {
            field: "insertName",
            headerText: "등록자",
            textAlign: "Center",
            width: 115,
          },
          {
            field: "insertDate",
            headerText: "등록일시",
            textAlign: "Center",
            width: 127,
          },
        ],
        dataSource: this.isDeletedSlip
            ? this.slipsGridDataSource
                ?.filter(item => item.deleted !== this.isDeletedSlip)
            : this.slipsGridDataSource,
        isInPopup: true,
        provides: [Aggregate, ForeignKey, Resize, Sort],
        selectionSettings: { type: "Single", enableToggle: false },
        useCommonStyles: false,
      };
    },
  },
  methods: {
    toShortcutString,
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onSlipListGridQueryCellInfo(args) {
      const {
        cell,
        column: { field },
        data,
      } = args;

      if (field === "paid") {
        if (data[field]) {
          cell.style.backgroundColor = commonCodesGetColorValue(
            "PAY_FLAG",
            data[field] ? "1" : "0"
          );
        }
      } else if (data["memos"] && field === "memo") {
        cell.classList.add(this.$t("className.grid.posExistMemo"));
      } else if (field === "visitorName") {
        if (data.checkOut) {
          cell.style.backgroundColor = commonCodesGetColorValue(
              "CHKIN_STATUS",
              "CHKOUT"
          );
          cell.style.color = "#ffffff";
          cell.innerText = `${data.visitorName} (${data.checkOutDateTime})`;
        }
      }

      if (data["deleted"]) {
        cell.style.color = "red";
      }
    },
    onSlipsGridDialogDoubleClickedOrEnterKeyed() {
      this.onSubmitClick();
    },
    onSlipListGridRecordClick(args) {
      if (args.cellIndex === 9 && args.rowData.memos > 0) {
        this.slipMemoListPopupOpen(args.rowData.id);
      }
    },
    onSlipListGridDataBound() {
      if (this.slips && 0 < this.slips) {
        this.$refs["slipsGrid"].selectRow(0);
      }
    },
    async slipMemoListPopupOpen(slipId) {
      const memos = await GolfERPService.fetchSalesSlipMemos(slipId);

      this.slip = this.slips.find(item => item.id === slipId);
      this.visibleSlipMemoListPopup = true;
      this.$nextTick(() => {
        this.$refs["slip-memo-list-popup"].show({
          memos,
        });
      });
    },
    onSlipMemoListPopupClose() {
      this.visibleSlipMemoListPopup = false;
    },
    onRefreshClick() {
      this.fetchSlips();
    },
    onSubmitClick() {
      const [record] = this.$refs["slipsGrid"].getSelectedRecords();
      if (record) {
        const slip = this.slips.find((slip) => slip.id === record.id);
        if (slip) {
          this.$emit("submit", { slip });
          this.hide();
        }
      }
    },
    onCloseClick() {
      this.hide();
    },

    // API
    async fetchSlips() {
      const orders = [{direction: "asc", field: "number"}];
      this.slips =
        (await GolfERPService.fetchSlips(
          this.store.code,
          moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
          this.store.golfCabinDivision,
          null,
          orders,
            null,
            null
        )) || [];
    },
  },
};
</script>
