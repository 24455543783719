<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <!-- 단가입력 활성(Class) : typePos-input -->
      <div
        class="typePos"
        :class="{ 'typePos-input': !visiblePriceUnitSelect }"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-header">
          <ul class="typePos-body">
            <li class="typePos-select" @click="visiblePriceUnitSelect = true">
              단가선택
            </li>
            <li class="typePos-input" @click="visiblePriceUnitSelect = false">
              단가입력
            </li>
          </ul>
        </div>
        <div class="typePos-wrapper-main">
          <div class="typePos-main-box typePos-select">
            <ul class="typePos-body">
              <li
                v-for="(priceUnit,idx) in priceUnits"
                v-bind:key="`unitPrice-${priceUnit.priceDivision}`"
              >
                <erp-button
                  button-div="SAVE"
                  :use-syncfusion-component-style="false"
                  :class="['ui-button',selectbutton.idx === idx && 'select-button' ]"
                  @click="selectbutton = {priceUnit,idx}"
                  @dblclick="onPriceUnitClick($event, { priceUnit })"
                >
                  <div class="i-item">
                    {{ toPriceDivString(priceUnit.priceDivision) }} :
                    {{ priceUnit.price | numberWithCommas }}
                  </div>
                </erp-button>
              </li>
            </ul>
          </div>
          <div class="typePos-main-box typePos-input">
            <div class="typePos-header">
              <div class="typePos-filed">
                <input-number-unusual
                  ref="input-price"
                  :allow-minus="true"
                  :align="'right'"
                  v-model="inputPrice"
                />
              </div>
            </div>
            <div class="typePos-body">
              <ul class="typePos-list">
                <li class="typePos-1">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '1')"
                  >
                    1
                  </erp-button>
                </li>
                <li class="typePos-2">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '2')"
                  >
                    2
                  </erp-button>
                </li>
                <li class="typePos-3">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '3')"
                  >
                    3
                  </erp-button>
                </li>
                <li class="typePos-4">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '4')"
                  >
                    4
                  </erp-button>
                </li>
                <li class="typePos-5">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '5')"
                  >
                    5
                  </erp-button>
                </li>
                <li class="typePos-6">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '6')"
                  >
                    6
                  </erp-button>
                </li>
                <li class="typePos-7">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '7')"
                  >
                    7
                  </erp-button>
                </li>
                <li class="typePos-8">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '8')"
                  >
                    8
                  </erp-button>
                </li>
                <li class="typePos-9">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '9')"
                  >
                    9
                  </erp-button>
                </li>
                <li class="typePos-0">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '0')"
                  >
                    0
                  </erp-button>
                </li>
                <li class="typePos-00">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, '00')"
                  >
                    00
                  </erp-button>
                </li>
                <li class="typePos-clear">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, 'Clear')"
                  >
                    CL
                  </erp-button>
                </li>
                <li class="typePos-back">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onNumpadButtonClick($event, 'Backspace')"
                  >
                    뒤로
                  </erp-button>
                </li>
                <li class="typePos-enter">
                  <erp-button
                    button-div="SAVE"
                    :use-syncfusion-component-style="false"
                    @click="onSubmitClick"
                  >
                    Enter
                  </erp-button>
                </li>
                <li class="typePos-input">
                  <erp-button
                    button-div="GET"
                    :use-syncfusion-component-style="false"
                    @click="onInputClick"
                  >
                    입력
                  </erp-button>
                </li>
                <li class="typePos-lookup">
                  <erp-button
                    button-div="SAVE"
                    :use-syncfusion-component-style="false"
                    @click="onSubmitClick"
                  >
                    적용
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                :use-syncfusion-component-style="false"
                class="ui-button i-typePrimary i-iconConfirm"
                @click="onPriceUnitClick($event, selectbutton)"
              >
                <div class="i-item">확인</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <setting-apply-selection-popup
        ref="settingApplySelectionPopup"
        v-if="isSettingApplySelectionPopup"
        @close="settingApplySelectionPopupClose"
        @selectApply="onSelectApply"
    />
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-header {border-bottom: 1px solid #bebebe;}
.typePos .typePos-wrapper-header .typePos-body {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-header .typePos-body::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-header .typePos-body > li {position: relative; float: left; width: 50%; padding: 14px 0; color: #aaa; font-size: 16px; font-family: 'NanumSquare-Bold'; line-height: 24px; text-align: center; cursor: pointer;}
.typePos .typePos-wrapper-header .typePos-body > li:hover {color: #18b3c9;}
.typePos .typePos-wrapper-header .typePos-body > li::before {display: none; position: absolute; bottom: -1px; left: 0; width: 100%; height: 3px; background-color: #18b3c9; content: '';}
.typePos .typePos-wrapper-header .typePos-body > li.typePos-select {color: #18b3c9; cursor: default;}
.typePos .typePos-wrapper-header .typePos-body > li.typePos-select:hover {color: #18b3c9;}
.typePos .typePos-wrapper-header .typePos-body > li.typePos-select::before {display: block;}

.typePos .typePos-wrapper-main {display: flex; flex-direction: column; flex: 1; overflow: hidden;}
.typePos .typePos-wrapper-main .typePos-main-box {height: 100%;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-select {overflow: auto;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-select .typePos-body {list-style: none; padding: 15px 20px; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-select .typePos-body > li {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-select .typePos-body > li .ui-button {width: 100%; padding-top: 12px; padding-bottom: 12px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-select .typePos-body > li .ui-button .i-item {min-height: 24px; padding-left: 0; font-size: 16px; font-family: 'NanumSquare-Bold'; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-select .typePos-body > li .ui-button .i-item::before {display: none;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input {display: none;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-header {padding: 15px 20px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-header .typePos-filed {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-header .typePos-filed >>> .ejs-number input {width: 100%;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body {position: relative; width: 325px; height: 278px; border-top: 1px solid #e0e0e0; background-color: #f5f5f5;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li {position: absolute;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li button {display: block; box-sizing: border-box; box-shadow: 0 2px 2px 0 rgba(0, 0 , 0, 0.05); width: 72px; height: 45px; padding: 5px; margin: 0; border: none; border-radius: 4px; background-color: #fff; color: #666; font-size: 16px; font-family: 'NanumSquare-Bold'; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li button:hover {background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-1 {top: 116px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-2 {top: 116px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-3 {top: 116px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-4 {top: 68px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-5 {top: 68px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-6 {top: 68px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-7 {top: 20px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-8 {top: 20px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-9 {top: 20px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-0 {top: 164px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-00 {top: 164px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-clear {top: 164px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-back {top: 212px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-enter {top: 212px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-input {top: 20px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-lookup {top: 116px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-clear button {font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-back button {overflow: hidden; text-indent: -1000px; background-image: url('../../../../../assets/images/ui/icon-type09.png'); background-repeat: no-repeat; background-position: center center;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-enter button {width: 147px; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-input button {width: 60px; height: 93px; background-color: #4f5963; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-input button:hover {background-color: #333c44;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-lookup button {width: 60px; height: 141px; background-color: #18b3c9; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-box.typePos-input .typePos-body .typePos-list > li.typePos-lookup button:hover {background-color: #0f9eb8;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}

.typePos.typePos-input .typePos-wrapper-header .typePos-body > li.typePos-select {color: #aaa; cursor: pointer;}
.typePos.typePos-input .typePos-wrapper-header .typePos-body > li.typePos-select:hover {color: #18b3c9;}
.typePos.typePos-input .typePos-wrapper-header .typePos-body > li.typePos-select::before {display: none;}
.typePos.typePos-input .typePos-wrapper-header .typePos-body > li.typePos-input {color: #18b3c9; cursor: default;}
.typePos.typePos-input .typePos-wrapper-header .typePos-body > li.typePos-input:hover {color: #18b3c9;}
.typePos.typePos-input .typePos-wrapper-header .typePos-body > li.typePos-input::before {display: block;}
.typePos.typePos-input .typePos-wrapper-main .typePos-main-box.typePos-select {display: none;}
.typePos.typePos-input .typePos-wrapper-main .typePos-main-box.typePos-input {display: block;}
.select-button { border: 2px solid skyblue !important; }
</style>

<script>
import InputNumberUnusual from "@/components/common/InputNumberUnusual";
import GolfERPService from "@/service/GolfERPService";
import moment from "moment";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { numberWithCommas } from "@/utils/number";
import {commonCodesGetCommonCodeByIdxAttrb, commonCodesGetComName} from "@/utils/commonCodes";
import ErpButton from "@/components/button/ErpButton";
import SettingApplySelectionPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/SettingApplySelectionPopup";

export default {
  name: "SettingSaleAmountPopup",
  components: {
    InputNumberUnusual,
    ErpButton,
    SettingApplySelectionPopup
  },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  props: {
    salesDate: {
      type: Date,
      required: true,
    },
    store: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSettingApplySelectionPopup: false,
      productCode: "",
      callback: () => {},
      selectbutton:{},
      inputPrice: 0,

      priceUnits: [],

      visiblePriceUnitSelect: true,
    };
  },
  computed: {
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "단가 변경",
        width: 325,
        height: 553,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
  },
  methods: {
    show({ productCode, callback }) {
      this.productCode = productCode;
      this.callback = callback;
      this.fetchProductUnitPrice();
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onSelectApply(priceUnit,div) {
      this.callback(priceUnit,div);
      this.hide();
    },
    toPriceDivString(priceDiv) {
      return commonCodesGetComName("PRICE_DIV", priceDiv);
    },
    onDialogClose() {
      this.$emit("close");
    },
    onPriceUnitClick(event, { priceUnit }) {

      if(commonCodesGetCommonCodeByIdxAttrb('PRICE_DIV', 3, 'Y').map(({comCode}) => comCode).includes(priceUnit?.priceDivision)){
        this.isSettingApplySelectionPopup = true;
        this.$nextTick(() => {
          this.$refs["settingApplySelectionPopup"].show(priceUnit);
        });
      } else {
        this.callback(priceUnit);
        this.hide();
      }

    },
    onNumpadButtonClick(event, code) {
      if (this.inputPrice === 0 && !isNaN(Number(code))) {
        this.inputPrice = Number(code);
        return;
      }

      switch (code) {
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
        case "0":
        case "00":
          this.inputPrice = Number(`${this.inputPrice}${code}`);
          break;
        case "Clear":
          this.inputPrice = 0;
          break;
        case "Backspace":
          if (this.inputPrice < 10) {
            this.inputPrice = 0;
            return;
          }

          this.inputPrice = Number(
            `${this.inputPrice}`.substr(0, `${this.inputPrice}`.length - 1)
          );
          break;
      }
    },
    onInputClick() {
      this.$refs["input-price"].focus();
    },
    onSubmitClick() {
      this.callback({
        price: this.inputPrice,
      });
      this.hide();
    },
    onCloseClick() {
      this.hide();
    },

    async fetchProductUnitPrice() {
      this.priceUnits = (
        await GolfERPService.fetchProductPriceUnits(
          this.productCode,
          moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
          this.store.code
        )
      ).map((priceUnit) => ({
        priceDivision: priceUnit.division,
        price: priceUnit.price1,
      }));
    },
    settingApplySelectionPopupClose() {
      this.isSettingApplySelectionPopup = false;
    }
  },
};
</script>
