<template>
  <!-- 팝업 활성(Class) : i-active / 비활성(Class) : i-disabled -->
  <div
    ref="select"
    v-bind="inputAttributes"
    v-on="inputListeners"
    class="f-select"
    :class="{ 'i-active': active && visibleOptions, 'i-disabled': disabled }"
    @blur="onSelectBlur"
    @focus="onSelectFocus"
    @keydown="onSelectKeydown"
  >
    <div class="i-value" @click="onSelectClick">
      <input
        tabindex="-1"
        type="text"
        :placeholder="placeholder"
        :value="selectItemName"
        readonly
      />
    </div>
    <div class="i-list">
      <div class="i-wrap">
        <div class="i-header">
          <input tabindex="-1" type="text" placeholder="검색어를 입력하세요." />
        </div>
        <ul class="i-body">
          <li
            v-for="(item, index) in items"
            v-bind:key="`dropdown-${uid}-item-${item.value}`"
            @mousedown="onSelectItemMousedown($event, { item, index })"
          >
            <label>
              <input
                ref="select-options"
                type="radio"
                :value="item.value"
                v-model="selectValue"
              />
              <div class="i-item">
                {{ item.name }}
              </div>
            </label>
          </li>
        </ul>
        <ul class="i-footer">
          <li>
            <erp-button
              button-div="CLOSE"
              :use-syncfusion-component-style="false"
            >
              <div class="i-item">취소</div>
            </erp-button>
          </li>
          <li class="i-confirm">
            <erp-button
              button-div="CLOSE"
              :use-syncfusion-component-style="false"
            >
              <div class="i-item">확인</div>
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.f-select:focus {outline: none;}
.f-select:focus .i-value {border-color: #18b3c9;border-radius: 4px 4px 0 0;}
</style>

<script>
import activeComponentsMixin from "@/views/layout/mixin/activeComponentsMixin";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "FSelect",
  mixins: [activeComponentsMixin],
  components: {
    ErpButton,
  },
  props: {
    value: {},
    placeholder :{type:String, default:"선택"},
    items: {
      type: [Array],
      required: true,
      validator(items) {
        return items.reduce(
          (result, { value }) => result && value !== undefined,
          true
        );
      },
    },
  },
  watch: {
    active(active) {
      if (!active) {
        this.visibleOptions = false;
      }
    },
    value(value) {
      this.selectValue = value;
    },
  },
  data() {
    return {
      visibleOptions: false,

      defaultTabIndex: 0,

      inputListeners: Object.assign({}, this.$listeners),

      selectValue: this.value,
    };
  },
  computed: {
    disabled() {
      switch (this.$activeComponentAttrs.disabled) {
        case "":
        case true:
        case "true":
        case "disabled":
          return true;
      }

      return false;
    },
    inputAttributes() {
      return Object.assign({}, this.$activeComponentAttrs, {
        tabindex: this.$activeComponentAttrs.tabindex || this.defaultTabIndex,
      });
    },

    selectItem() {
      return this.items.find((item) => item.value === this.value);
    },
    selectItemName() {
      return this.selectItem?.name || "";
    },
  },
  methods: {
    focus() {
      if (this.disabled) {
        return;
      }

      this.$refs["select"].focus();
      this.activate();
    },
    onSelectBlur() {
      this.deactivate();
    },
    onSelectFocus() {
      this.activate();
    },
    onSelectKeydown(event) {
      if (event.key === "Enter") {
        if (!this.visibleOptions) {
          this.visibleOptions = true;
        } else {
          const index =
            (this.items.findIndex((item) => item.value === this.selectValue) +
              this.items.length) %
            this.items.length;
          const item = this.items[index];
          this.visibleOptions = false;
          this.$emit("select", event, { item, index });
        }
      } else if (event.key === "ArrowUp") {
        const index =
          (this.items.findIndex((item) => item.value === this.selectValue) +
            this.items.length -
            1) %
          this.items.length;
        const item = this.items[index];

        if (!this.visibleOptions) {
          this.$emit("select", event, { item, index });
        } else {
          this.selectValue = item.value;
          this.$refs["select-options"][index].scrollIntoView({
            block: "nearest",
          });
        }
      } else if (event.key === "ArrowDown") {
        const index =
          (this.items.findIndex((item) => item.value === this.selectValue) +
            this.items.length +
            1) %
          this.items.length;
        const item = this.items[index];

        if (!this.visibleOptions) {
          this.$emit("select", event, { item, index });
        } else {
          this.selectValue = item.value;
          this.$refs["select-options"][index].scrollIntoView({
            block: "nearest",
          });
        }
      }
    },
    onSelectClick() {
      if (this.disabled) {
        return;
      }

      this.visibleOptions = !this.visibleOptions;
      this.activate();
    },
    onSelectItemMousedown(event, { item, index }) {
      this.visibleOptions = false;
      this.$emit("select", event, { item, index });
      this.$emit("input", item.value);
    },
  },
};
</script>
