<template>
  <!-- 팝업 활성(Class) : i-active / 비활성(Class) : i-disabled -->
  <div
    v-bind="inputAttributes"
    v-on="inputListeners"
    class="f-shortcut"
    :class="{ 'i-active': active && visibleOptions, 'i-disabled': disabled }"
  >
    <!-- 비활성(Attribute) : disabled -->
    <erp-button
      button-div="CLOSE"
      class="ui-button"
      :use-syncfusion-component-style="false"
      @click="onSelectClick"
    >
      <div class="i-item">
        <slot />
      </div>
    </erp-button>
    <div class="i-list">
      <div class="i-wrap">
        <div class="i-header">
          <input type="text" placeholder="검색어를 입력하세요." />
        </div>
        <ul class="i-body">
          <li
            v-for="(item, index) in items"
            v-bind:key="`dropdown-${uid}-item-${item.value}`"
            @click="onSelectItem($event, { item, index })"
          >
            <label>
              <input
                type="radio"
                :value="item.value"
                :disabled="item.disabled"
              />
              <div class="i-item">
                {{ item.name }}
              </div>
            </label>
          </li>
        </ul>
        <ul class="i-footer">
          <li>
            <erp-button
              button-div="CLOSE"
              :use-syncfusion-component-style="false"
            >
              <div class="i-item">취소</div>
            </erp-button>
          </li>
          <li class="i-confirm">
            <erp-button
              button-div="CLOSE"
              :use-syncfusion-component-style="false"
            >
              <div class="i-item">확인</div>
            </erp-button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.f-shortcut:focus {outline: none;}
</style>

<script>
import activeComponentsMixin from "@/views/layout/mixin/activeComponentsMixin";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "Shortcut",
  mixins: [activeComponentsMixin],
  components: {
    ErpButton,
  },
  props: {
    items: {
      type: [Array],
      required: true,
      validator(items) {
        return items.reduce(
          (result, { value }) => result && value !== undefined,
          true
        );
      },
    },
  },
  watch: {
    active(active) {
      if (!active) {
        this.visibleOptions = false;
      }
    },
  },
  data() {
    return {
      visibleOptions: false,

      defaultTabIndex: 0,

      inputListeners: Object.assign({}, this.$listeners),
    };
  },
  computed: {
    disabled() {
      switch (this.$activeComponentAttrs.disabled) {
        case "":
        case true:
        case "true":
        case "disabled":
          return true;
      }

      return false;
    },

    inputAttributes() {
      return Object.assign({}, this.$activeComponentAttrs, {
        tabindex: this.$activeComponentAttrs.tabindex || this.defaultTabIndex,
      });
    },
  },
  methods: {
    onSelectClick() {
      if (this.disabled) {
        return;
      }

      this.visibleOptions = !this.visibleOptions;
      this.activate();
    },
    onSelectItem(event, { item, index }) {
      this.visibleOptions = false;
      this.$emit("select", event, { item, index });
    },
  },
};
</script>

<style scoped></style>
