<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-caption">
          <div class="typePos-header">
            <div class="typePos-filed typePos-groupType">
              <div class="typePos-filed-title">단체종류</div>
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <f-select
                    class="ui-select"
                    :items="groupKinds"
                    v-model="groupKindCode"
                  />
                </div>
              </div>
            </div>
            <div class="typePos-filed typePos-groupName">
              <div class="typePos-filed-title">단체명</div>
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input
                      ref="input-q"
                      type="text"
                      placeholder="입력하세요."
                      v-model="q"
                      @keypress.enter="onKeypressEnter"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="typePos-filed">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <ul class="ui-check">
                    <li>
                      <label>
                        <!-- 비활성(Attribute) : disabled -->
                        <input type="checkbox" v-model="useFlag" />
                        <div class="i-item">사용여부</div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="typePos-lookup">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button i-typePrimary"
                :use-syncfusion-component-style="false"
                :is-shortcut-button="true"
                :visible-shortcut-string="false"
                @click="onSearchClick"
              >
                <div class="i-item">
                  조회 ({{ searchShortcutButtonShortcutString }})
                </div>
              </erp-button>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-main">
          <ejs-grid-wrapper
            ref="groupsGrid"
            v-bind="groupsGridProps"
            @rowSelected="groupRowSelected"
            @queryCellInfo="groupQueryCellInfo"
            @onGridDialogDoubleClickedOrEnterKeyed="
              groupGridDialogDoubleClickedOrEnterKeyed
            "
            @dataBound="onGroupGridDataBound"
          />
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                :use-syncfusion-component-style="false"
                class="ui-button i-typePrimary i-iconConfirm"
                @click="onSubmitClick"
              >
                <div class="i-item">확인</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-caption {padding: 10px; border-bottom: 1px solid #bebebe;}
.typePos .typePos-wrapper-caption::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed,
.typePos .typePos-wrapper-caption .typePos-lookup {float: left;}
.typePos .typePos-wrapper-caption .typePos-filed {padding: 0 3px;}
.typePos .typePos-wrapper-caption .typePos-lookup {padding: 0 10px;}
.typePos .typePos-wrapper-caption .typePos-filed::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content {float: left; padding: 0 7px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title {padding-top: 10px; padding-bottom: 11px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item {float: left; padding-left: 10px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item:first-child {padding-left: 0;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content {float: left; padding-top: 10px; padding-bottom: 11px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title {padding-right: 4px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content strong {color: #18b3c9; font-weight: normal;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content em {color: #df2929; font-style: normal;}
.typePos .typePos-wrapper-caption .typePos-lookup >>> .ui-button .i-item::before {background-position-x: calc(-20px * 7);}
.typePos .typePos-wrapper-caption .typePos-header {float: left;}
.typePos .typePos-wrapper-caption .typePos-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-groupType .typePos-filed-content .typePos-item .ui-select {width: 180px;}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-groupName .typePos-filed-content .typePos-item .ui-input {width: 180px;}
.typePos .typePos-wrapper-caption .typePos-footer {float: right;}
.typePos .typePos-wrapper-caption .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}

.typePos .typePos-wrapper-main {flex: 1; overflow: hidden;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
</style>

<script>
import { orderBy as _orderBy } from "lodash";
import { getGroupList } from "@/api/group";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { getFormattedPhoneNumber } from "@/utils/string";
import { ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import FSelect from "@/views/store-management/sales-registration/store-pos-renew/components/FSelect";
import {
  getSearchButtonShortcut,
  toShortcutString,
} from "@/utils/KeyboardUtil";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "SearchGroupsPopup",
  components: { FSelect, EjsGridWrapper, ErpButton },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      groupKindCode: "",
      q: "",
      useFlag: true,
      bsnDate: null,
      tempGrpName: null,

      groups: [],
      selectedGroup: {},

      callback: () => {},

      isSubmit: false,
    };
  },
  computed: {
    searchShortcutButtonShortcutString() {
      return toShortcutString(getSearchButtonShortcut());
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "골프단체 검색",
        width: 1160,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    groupKinds() {
      const groupKinds = _orderBy(
        commonCodesGetCommonCode("GRP_KIND").map((commonCode) => ({
          ...commonCode,
          name: commonCode.comName,
          value: commonCode.comCode,
        })),
        "sortNo"
      );

      groupKinds.unshift({
        name: "전체",
        value: "",
      });

      return groupKinds;
    },
    groupsGridProps() {
      return {
        allowResizing: true,
        columns: [
          {
            field: "grpKind",
            headerText: "단체종류",
            type: "string",
            isCommonCodeField: true,
            groupCode: "GRP_KIND",
            width: 128,
          },
          {
            field: "grpNo",
            headerText: "단체번호",
            isPrimaryKey: true,
            type: "string",
            width: 128,
          },
          {
            field: "resveFlagColumn",
            headerText: "등록",
            type: "string",
            width: 104,
          },
          {
            field: "grpName",
            headerText: "단체명",
            type: "string",
            width: 115,
          },
          {
            field: "teamCnt",
            headerText: "팀수",
            type: "string",
            width: 104,
          },
          {
            field: "areaCode",
            headerText: "지역",
            type: "string",
            isCommonCodeField: true,
            groupCode: "AREA_CODE",
            width: 104,
          },
          {
            field: "generName",
            headerText: "총무",
            type: "string",
            width: 104,
          },
          {
            field: "generContactTel",
            headerText: "총무 연락처",
            type: "string",
            width: 150,
            valueAccessor: this.telNumberValueAccess,
          },
          {
            field: "grpCharct",
            headerText: "비고",
            type: "string",
            width: 128,
          },
        ],
        dataSource: this.groups,
        isInPopup: true,
        provides: [Resize, ForeignKey],
        useCommonStyles: false,
      };
    },
  },
  methods: {
    show({ q, callback, bsnDate, grpKind, tempGrpName }) {
      this.q = q;
      this.bsnDate = bsnDate;
      this.groupKindCode = grpKind || "%";
      this.grpKind = grpKind;
      this.tempGrpName = tempGrpName;
      this.callback = callback;
      this.$refs["dialog"].show();
      if (this.q || grpKind === "TEMP") {
        this.$nextTick(() => {
          this.fetchGroups();
        });
      }
    },
    hide() {
      this.$refs["dialog"].hide();
    },

    telNumberValueAccess(field, data) {
      let tel = data[field];
      if (tel != null) {
        tel = getFormattedPhoneNumber(tel);
      }
      return tel;
    },

    onDialogClose() {
      this.$emit("close", this.isSubmit);
    },
    onKeypressEnter() {
      this.fetchGroups();
    },
    onSearchClick() {
      this.fetchGroups();
    },
    groupQueryCellInfo(args) {
      if (args.column.field === "resveFlagColumn" && args.data.resveFlag) {
        // 등록 cell style
        args.cell.style.background = "#ED1C24";
      }
    },
    groupGridDialogDoubleClickedOrEnterKeyed() {
      this.onSubmitClick();
    },
    onGroupGridDataBound() {
      if (this.groups.length > 0) {
        this.$refs["groupsGrid"].selectRow(0);
      } else {
        this.$refs["input-q"].focus();
      }
    },
    onSubmitClick() {
      const [ selectedGroup ] = this.$refs[
        "groupsGrid"
      ].getSelectedRecords();
      if (this.tempGrpName && selectedGroup.tempGrpName && selectedGroup.tempGrpName !== this.tempGrpName) {
        this.errorToast("이미 다른 단체명으로 사용 중인 단체 입니다");
        return;
      }
      selectedGroup.grpName = selectedGroup.tempGrpName ? selectedGroup.tempGrpName : (this.tempGrpName ? this.tempGrpName : selectedGroup.grpName);

      const group = {
        name: selectedGroup.grpName,
        kind: selectedGroup.grpKind,
        number: selectedGroup.grpNo,
      };

      this.isSubmit = true;

      this.callback(group);
      this.hide();
    },
    onCloseClick() {
      this.hide();
    },

    async fetchGroups() {
      const {
        value: { groupInfoList },
      } = await getGroupList({
        resveDate: this.bsnDate,
        grpKind: this.groupKindCode,
        searchValue: this.q || undefined,
        useFlag: this.useFlag,
      });
      this.groups = groupInfoList || [];
    },
    groupRowSelected(args) {
      const {
        data,
        rowIndex,
        previousRowIndex,
        previousRow,
      } = args;
      this.selectedGroup = data;

      if (!data["tempGrpName"] && this.tempGrpName && previousRow !== undefined) {
        this.$refs.groupsGrid.setCellValue(data["grpNo"], "grpName", data["grpName"] + "(" + this.tempGrpName + ")");
      }

      if (previousRowIndex !== undefined && !(previousRowIndex < 0)) {
        if (rowIndex !== previousRowIndex) {
          const preGrpNo = previousRow.cells[2].textContent;
          const preGrpName = this.groups.filter(item => item.grpNo === preGrpNo)[0].grpName;
          this.$refs.groupsGrid.setCellValue(preGrpNo, "grpName", preGrpName);
        }
      }
    }
  },
};
</script>
