export function has(node, target) {
  if (node === target) {
    return true;
  } else {
    let result = false;
    node?.childNodes?.forEach((child) => {
      result = result || has(child, target);
    });

    return result;
  }
}
