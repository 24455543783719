<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-main">
          <div class="typePos-wrap">
            <div
              class="typePos-box"
              v-if="paymentMethod === 'GIFT'"
            >
              <div class="typePos-header">COUNTRY CLUB</div>
              <div class="typePos-caption">
                상품권 {{receiptInfo.approvalDiv === "OK" ? "사용" : "취소"}} 내역서
              </div>
              <ul class="typePos-aside">
                <li>
                  <div class="typePos-title">발행일시</div>
                  <div class="typePos-content">
                    {{ receiptInfo.printDt }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">거래일시</div>
                  <div class="typePos-content">
                    {{ receiptInfo.approvalDt }}
                  </div>
                </li>
              </ul>
              <div class="typePos-body">
                <ul class="typePos-list typePos-first">
                  <li>
                    <div class="typePos-name">사용자명</div>
                    <div class="typePos-price">
                      {{ receiptInfo.memberName }}
                    </div>
                  </li>
                  <li v-if="receiptInfo.contactTel">
                    <div class="typePos-name">연락처</div>
                    <div class="typePos-price">
                      {{ receiptInfo.contactTel }}
                    </div>
                  </li>
                </ul>
                <ul class="typePos-list">
                  <li>
                    <div class="typePos-name">사용금액</div>
                    <div class="typePos-price">
                      {{ numberWithCommas(receiptInfo.payAmt) }}
                    </div>
                  </li>
                  <li v-if="receiptInfo.giftNo">
                    <div class="typePos-name">상품권 번호</div>
                    <div class="typePos-price">
                      {{ receiptInfo.giftNo }}
                    </div>
                  </li>
                </ul>
              </div>
              <ul class="typePos-aside">
                <li>
                  <div class="typePos-title">가명점명</div>
                  <div class="typePos-content">
                    {{ receiptInfo.bizName }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">사업자번호</div>
                  <div class="typePos-content">
                    {{ receiptInfo.bizNo }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">대표자명</div>
                  <div class="typePos-content">
                    {{ receiptInfo.rprsntvName }}
                  </div>
                </li>
              </ul>
              <div class="typePos-footer">
                {{ receiptInfo.addr }}<br />
                TEL : {{ receiptInfo.telNo }} / FAX : {{ receiptInfo.faxNo }}
              </div>
            </div>
            <div
              class="typePos-box"
              v-else-if="paymentMethod === 'CASH' || paymentMethod === 'TRANS'"
            >
              <div class="typePos-header">COUNTRY CLUB</div>
              <div class="typePos-caption">
                {{
                  salesDivision === "DEPOSIT"
                    ? "영수증(" +
                      commonCodesGetComName("PAY_DIV", depositPaymentMethod) +
                      ")"
                    : (receiptInfo.approvalDiv === "OK" ||
                       receiptInfo.approvalDiv === "T_OK"
                        ? commonCodesGetComName("PAY_DIV", paymentMethod) + "승인 "
                        : commonCodesGetComName("PAY_DIV", paymentMethod) + "취소 ") +
                      (!!receiptInfo.proofName
                        ? "(" + receiptInfo.proofName + ")"
                        : "(미발급)")
                }}
              </div>
              <ul class="typePos-aside">
                <li>
                  <div class="typePos-title">거래일시</div>
                  <div class="typePos-content">
                    {{ receiptInfo.approvalDt }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">재발행일시</div>
                  <div class="typePos-content">
                    {{ receiptInfo.rePrintDt }}
                  </div>
                </li>
              </ul>
              <div class="typePos-body">
                <ul class="typePos-list typePos-first">
                  <li>
                    <div class="typePos-name">판매금액</div>
                    <div class="typePos-price">
                      {{ receiptInfo.taxAmt }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">면세</div>
                    <div class="typePos-price">
                      {{ receiptInfo.notaxAmt }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">부가세</div>
                    <div class="typePos-price">
                      {{ receiptInfo.vatAmt }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">봉사료</div>
                    <div class="typePos-price">
                      {{ receiptInfo.svcAmt }}
                    </div>
                  </li>
                </ul>
                <ul class="typePos-list">
                  <li>
                    <div class="typePos-name">합계</div>
                    <div class="typePos-price">
                      {{ receiptInfo.approvalAmt }}
                    </div>
                  </li>
                </ul>
              </div>
              <ul class="typePos-aside">
                <li v-if="receiptInfo.proofNo">
                  <div class="typePos-title">고객 확인</div>
                  <div class="typePos-content">
                    {{ receiptInfo.proofNo }}
                  </div>
                </li>
                <li v-if="receiptInfo.approvalNo">
                  <div class="typePos-title">승인 번호</div>
                  <div class="typePos-content">
                    {{ receiptInfo.approvalNo }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">가명점명</div>
                  <div class="typePos-content">
                    {{ receiptInfo.bizName }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">사업자번호</div>
                  <div class="typePos-content">
                    {{ receiptInfo.bizNo }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">대표자명</div>
                  <div class="typePos-content">
                    {{ receiptInfo.rprsntvName }}
                  </div>
                </li>
              </ul>
              <div class="typePos-footer">
                {{ receiptInfo.addr }}<br />
                TEL : {{ receiptInfo.telNo }} / FAX : {{ receiptInfo.faxNo }}
                <template v-if="receiptInfo.proofName">
                  <br />
                  국세청 세미래콜센터 국번없이 126
                </template>
              </div>
            </div>
            <div class="typePos-box" v-else>
              <div class="typePos-header">카드사 제출용</div>
              <div class="typePos-caption">
                {{
                  receiptInfo.approvalDiv === "OK"
                    ? "매출전표 (신용승인)"
                    : "매출전표 (신용취소)"
                }}
              </div>
              <ul class="typePos-aside">
                <li>
                  <div class="typePos-title">매 장 명</div>
                  <div class="typePos-content">
                    {{ receiptInfo.storeName }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">영업 일자</div>
                  <div class="typePos-content">
                    {{ receiptInfo.payDate }}
                  </div>
                </li>
              </ul>
              <div class="typePos-body">
                <ul class="typePos-list typePos-first">
                  <li>
                    <div class="typePos-name">락커 번호</div>
                    <div class="typePos-price">
                      {{ receiptInfo.lockerNo }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">전표 번호</div>
                    <div class="typePos-price">
                      {{ receiptInfo.slipNo }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">카드 종류</div>
                    <div class="typePos-price">
                      {{ receiptInfo.issuCompanyName }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">카드 번호</div>
                    <div class="typePos-price">
                      {{ receiptInfo.cardNo }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">유효 기간</div>
                    <div class="typePos-price">
                      {{ receiptInfo.validTerm }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">승인 일시</div>
                    <div class="typePos-price">
                      {{ receiptInfo.approvalDt }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">전표매입사</div>
                    <div class="typePos-price">
                      {{ receiptInfo.purcName }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">재발행일시</div>
                    <div class="typePos-price">
                      {{ receiptInfo.rePrintDt }}
                    </div>
                  </li>
                </ul>
              </div>
              <ul class="typePos-aside">
                <li>
                  <div class="typePos-title">과 세</div>
                  <div class="typePos-content">
                    {{ receiptInfo.taxAmt }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">면 세</div>
                  <div class="typePos-content">
                    {{ receiptInfo.notaxAmt }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">부 가 세</div>
                  <div class="typePos-content">
                    {{ receiptInfo.vatAmt }}
                  </div>
                </li>
                <li
                  v-if="receiptInfo.svcAmt === '0' ? null : receiptInfo.svcAmt"
                >
                  <div class="typePos-title">봉 사 료</div>
                  <div class="typePos-content">
                    {{ receiptInfo.svcAmt }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">합 계</div>
                  <div class="typePos-content">
                    {{ receiptInfo.approvalAmt }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">승인 번호</div>
                  <div class="typePos-content">
                    {{ receiptInfo.approvalNo }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">할부 개월</div>
                  <div class="typePos-content">
                    {{ receiptInfo.divideTerm }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">가맹점번호</div>
                  <div class="typePos-content">
                    {{ receiptInfo.merchantNo }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">가맹점 명</div>
                  <div class="typePos-content">
                    {{ receiptInfo.bizName }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">사업자번호</div>
                  <div class="typePos-content">
                    {{ receiptInfo.bizNo }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">대표자 명</div>
                  <div class="typePos-content">
                    {{ receiptInfo.rprsntvName }}
                  </div>
                </li>
              </ul>
              <div class="typePos-footer">
                {{ receiptInfo.addr }}<br />
                TEL : {{ receiptInfo.telNo }}<br />
                결제취소시 영수증을 지참해 주시기 바랍니다.
              </div>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li class="typePos-print">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="PRINT"
                class="ui-button i-typePrimary"
                :use-syncfusion-component-style="false"
                @click="print"
              >
                <div class="i-item">출력</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {overflow: hidden; flex: 1; box-sizing: border-box; padding: 20px;}
.typePos .typePos-wrapper-main .typePos-wrap {overflow: auto; height: 100%; border: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-box {padding: 16px 20px 20px 20px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-header {color: #222; font-size: 16px; font-family: '돋움', Dotum; font-weight: bold; line-height: 24px; text-align: center;}
.typePos .typePos-wrapper-main .typePos-box .typePos-caption {padding-top: 12px; margin-bottom: -5px; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-aside {list-style: none; padding: 12px 0 10px 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-box .typePos-aside > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-box .typePos-aside > li .typePos-title {float: left; width: 40%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-aside > li .typePos-content {float: left; width: 60%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body {border-top: 1px solid #222; border-bottom: 1px solid #222;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list {list-style: none; padding: 12px 0 10px 0; margin: 0; border-top: 1px dashed #222;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list.typePos-first {border-top: none;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list > li .typePos-name {float: left; width: 60%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list > li .typePos-count {float: left; width: 20%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px; text-align: right;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list > li .typePos-price {float: right; width: 20%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px; text-align: right;}
.typePos .typePos-wrapper-main .typePos-box .typePos-footer {margin-top: -3px; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-print .ui-button .i-item::before {background-position-x: calc(-20px * 11);}
</style>

<script>
import { getReceiptInfo } from "@/api/common";
import { getTodayDateTime } from "@/utils/date";
import { commonCodesGetComName } from "@/utils/commonCodes";
import BillPrintUtil from "@/utils/billPrintUtil";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { numberWithCommas } from "@/utils/number";
import {getFormattedTelNumber} from "@/utils/string";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ReIssueReceiptPopup",
  components: {
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      receiptInfo: {},

      paymentId: "",
      salesDivision: "",
      paymentMethod: "",
      depositPaymentMethod: "",
      isPayCash: false,
      isCashCancel: false
    };
  },
  computed: {
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "영수증 재발행",
        width: 425,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
  },
  methods: {
    commonCodesGetComName,
    numberWithCommas,
    show({ paymentId, paymentMethod, salesDivision, isPayCash, isCashCancel }) {
      /*
      paymentId = PAY_ID
      paymentMethod = PAY_DIV
      salesDivision = SALES_DIV
       */
      console.log('paymentMethod.===>', paymentMethod);
      this.paymentId = paymentId;
      this.salesDivision = salesDivision;
      if (
        !["EASY_PAY","CARD", "CASH", "TRANS", "GIFT", "GROUP_GIFT"].includes(paymentMethod)
      ) {
        this.paymentMethod = "CASH";
      } else {
        this.paymentMethod = paymentMethod === "GROUP_GIFT" ? "GIFT" : paymentMethod;
      }
      this.depositPaymentMethod = paymentMethod;
      this.isPayCash = isPayCash;
      this.isCashCancel = isCashCancel;

      this.fetchReceiptInfo();
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    async print() {
      // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.

      const receiptCode = this.paymentMethod.toUpperCase() + "_RECEIPT";



      const printSetData = {
        storeName: this.receiptInfo.storeName,
        bsnDate: this.receiptInfo.payDate,
        lockerNo: this.receiptInfo.lockerNo,
        slipNo: this.receiptInfo.slipNo,
        cardKind: this.receiptInfo.issuCompanyName,
        cardNo: this.receiptInfo.cardNo,
        validTerm: this.receiptInfo.validTerm,
        approvalDt: this.receiptInfo.approvalDt,
        purcName: this.receiptInfo.purcName,
        rePrintDt: this.receiptInfo.rePrintDt,
        totAmt: this.receiptInfo.approvalAmt,
        taxAmt: this.receiptInfo.taxAmt,
        noTaxAmt: this.receiptInfo.notaxAmt,
        svcAmt:
          this.receiptInfo.svcAmt === "0" ? null : this.receiptInfo.svcAmt,
        vatAmt: this.receiptInfo.vatAmt,
        approvalNo: this.receiptInfo.approvalNo,
        divideTerm: this.receiptInfo.divideTerm,
        merchantNo: this.receiptInfo.merchantNo,
        merchantName: this.receiptInfo.bizName,
        bizNo: this.receiptInfo.bizNo,
        rprsntvName: this.receiptInfo.rprsntvName,
        addr: this.receiptInfo.addr,
        telNo: this.receiptInfo.telNo,
        faxNo: this.receiptInfo.faxNo,
        proofName: this.receiptInfo.proofName,
        proofNo: this.receiptInfo.proofNo,
        cardTitle:
          this.receiptInfo.approvalDiv === "OK"
            ? "매출전표 (신용승인)"
            : "매출전표 (신용취소)",
        cashTitle:
          (
            this.receiptInfo.approvalDiv === "OK" ||
            this.receiptInfo.approvalDiv === "T_OK"
              ? "현금승인 "
              : "현금취소 "
          ) +
          (this.receiptInfo.proofName
            ? "(" + this.receiptInfo.proofName + ")"
            : "(미발급)"),
        transTitle:
          (this.receiptInfo.approvalDiv === "OK"
            ? "계좌이체승인 "
            : "계좌이체취소 ") +
          (this.receiptInfo.proofName
            ? "(" + this.receiptInfo.proofName + ")"
            : "(미발급)"),
        giftNo: this.receiptInfo.giftNo,
        payAmt: this.receiptInfo.payAmt,
        memberName: this.receiptInfo.memberName,
        contactTel: getFormattedTelNumber(
          this.receiptInfo.contactTel
        ),
        printDt: this.receiptInfo.printDt,
        giftTitle: this.receiptInfo.approvalDiv === "OK" ? "상품권 사용 내역서" : "상품권 취소 내역서",
      };

      const config = {
        "useBillPrinter": true,
        "useKitchenPrinter": false,
      };
      await BillPrintUtil.getTsConfReceiptInfo.call(this, receiptCode, true, printSetData, config);


    },
    onCloseClick() {
      this.hide();
    },
    setPhoneNumber(phoneNumber) {
      if (phoneNumber !== null && phoneNumber !== undefined) {
        if (phoneNumber.length > 3 && phoneNumber.length < 8) {
          phoneNumber =
            phoneNumber.substring(0, 3) + "-" + phoneNumber.substring(3);
        } else if (phoneNumber.length >= 8) {
          if (phoneNumber.length === 10) {
            phoneNumber =
              phoneNumber.substring(0, 3) +
              "-" +
              phoneNumber.substring(3, 6) +
              "-" +
              phoneNumber.substring(6);
          } else {
            phoneNumber =
              phoneNumber.substring(0, 3) +
              "-" +
              phoneNumber.substring(3, 7) +
              "-" +
              phoneNumber.substring(7);
          }
        }
      } else {
        phoneNumber = "";
      }
      return phoneNumber;
    },
    onDialogClose() {
      this.$emit("close");
    },

    async fetchReceiptInfo() {
      const {
        value: { receiptInfo },
      } = await getReceiptInfo(
        this.paymentId,
        this.paymentMethod,
        this.salesDivision,
        this.isPayCash,
        this.isCashCancel
      );

      receiptInfo.rePrintDt = await getTodayDateTime();
      receiptInfo.telNo = this.setPhoneNumber(receiptInfo.telNo);
      receiptInfo.faxNo = this.setPhoneNumber(receiptInfo.faxNo);
      receiptInfo.storeName = commonCodesGetComName(
        "STORE_CODE",
        receiptInfo.storeCode
      );
      receiptInfo.proofName = commonCodesGetComName(
        "PROOF_KIND",
        receiptInfo.proofKind
      );
      this.receiptInfo = receiptInfo;
    },
  },
};
</script>
