<template>
  <div
    :class="[
      'ejs-date',
      { focus: active },
      { disabled: disabled },
      'dev-edit-component',
    ]"
    :id="_componentUUID"
    @focusout="onFocusout"
    @focusin="onFocusin"
  >
    <div class="ejs-date-calendar">
      <ejs-datepicker
        ref="datepicker"
        v-if="_usingDatepicker"
        cssClass="dev-edit-component"
        :disabled="disabled"
        :enabled="!disabled"
        :value="calendarValue"
        @open="onDatepickerOpen"
        @close="onDatepickerClose"
        @change="onDatepickerChange"
        @renderDayCell="onRenderDayCell"
        @navigated="onNavigated"
      />
    </div>
    <div class="ejs-date-input">
      <input
        ref="input"
        type="text"
        autocomplete="off"
        :disabled="disabled"
        :name="name"
        v-model="text"
        @click="onInputTextClick"
        @keydown.delete="onKeydownDelete"
        @keydown.48="onKeydownNumber"
        @keydown.49="onKeydownNumber"
        @keydown.50="onKeydownNumber"
        @keydown.51="onKeydownNumber"
        @keydown.52="onKeydownNumber"
        @keydown.53="onKeydownNumber"
        @keydown.54="onKeydownNumber"
        @keydown.55="onKeydownNumber"
        @keydown.56="onKeydownNumber"
        @keydown.57="onKeydownNumber"
        @keydown.96="onKeydownNumber"
        @keydown.97="onKeydownNumber"
        @keydown.98="onKeydownNumber"
        @keydown.99="onKeydownNumber"
        @keydown.100="onKeydownNumber"
        @keydown.101="onKeydownNumber"
        @keydown.102="onKeydownNumber"
        @keydown.103="onKeydownNumber"
        @keydown.104="onKeydownNumber"
        @keydown.105="onKeydownNumber"
        @keydown.left="onKeydownArrow"
        @keydown.right="onKeydownArrow"
        @keydown="onKeydown"
      />
      <input type="hidden" :id="id" :value="resultValue" />
    </div>
    <div v-if="this.text !== '0000-00-00'" class="ejs-date-clear">
      <button
        v-if="!required"
        type="button"
        @click="onClearClick"
        :disabled="disabled"
      >
        Clear
      </button>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { isDateFormatString } from "@/utils/string";
import {
  DATE_FORMAT_YYYY,
  DATE_FORMAT_YYYY_MM,
  DATE_FORMAT_YYYY_MM_DD,
  getCalendarInfoAndSetCalendarContentElementDayClassNew,
  getCurrentSelectedDateMomentInDateTimePicker,
  setIdInDatePickerSpan,
  setDateElementCssClassOnRenderCell,
} from "@/utils/date";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

const DATE_FORMATS = [
  DATE_FORMAT_YYYY_MM_DD,
  DATE_FORMAT_YYYY_MM,
  DATE_FORMAT_YYYY,
];

const EMPTY_OF_FORMAT = {
  "YYYY-MM-DD": "0000-00-00",
  "YYYY-MM": "0000-00",
  YYYY: "0000",
};

function replaceRange(target, string, startIndex, endIndex) {
  return `${target.substr(0, startIndex)}${string}${target.substr(endIndex)}`;
}

export default {
  name: "InputDateUnusual",
  mixins: [confirmDialogMixin],
  props: {
    id: String,
    name: {
      type: String,
      default: "name",
    },
    value: {
      type: String,
      validator(value) {
        return !value || isDateFormatString(value);
      },
    },
    format: {
      type: String,
      validator(format) {
        return !(DATE_FORMATS.indexOf(format) < 0);
      },
      default: () => DATE_FORMAT_YYYY_MM_DD,
    },
    disabled: {
      type: [String, Boolean],
      validator(disabled) {
        return `${disabled}` === "true" || `${disabled}` === "false";
      },
      default: () => false,
    },
    usingDatepicker: {
      type: [String, Boolean],
      validator(disabled) {
        return `${disabled}` === "true" || `${disabled}` === "false";
      },
      default: () => true,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    notClear: {
      type: Boolean,
      default: () => false,
    },
    isGetDateInfo: {
      type: Boolean,
      default: () => false,
    },
    /**
     * true : 값이 없을 때 "" 를 binding
     * false : 값이 없을 때 undefined를 binding
     */
    isBindingEmptyString: {
      type: Boolean,
      default: () => false,
    },
  },
  created() {
    this.text = this.value || EMPTY_OF_FORMAT[this.format];
  },
  watch: {
    async value() {
      this.text = this.value || EMPTY_OF_FORMAT[this.format];
      if (this.isGetDateInfo) {
        const { data } = await GolfErpAPI.fetchCalenderInfo(this.text, false);
        this.bsnCode = data.bsnCode;
        this.dwCode = data.dwCode;
      }
      this.$emit("change", {
        data: this.value,
        bsnCode: this.bsnCode,
        dwCode: this.dwCode,
      });
    },
    active(active) {
      if (!active) {
        this.renewalValueByEmitInput();
      }
    },
    text(newText, oldText) {
      if (/[ㄱ-ㅎㅏ-ㅣ|가-힣]/gi.test(newText)) {
        this.text = oldText;
      }
    },
  },
  data() {
    return {
      active: false,
      text: "",
      calendarListMap: new Map(),
      bsnCode: null,
      dwCode: null,
    };
  },
  computed: {
    _componentUUID() {
      return uuidv4();
    },
    _usingDatepicker() {
      return Boolean(this.usingDatepicker);
    },

    resultValue() {
      if (this.text === EMPTY_OF_FORMAT[this.format]) {
        return undefined;
      }

      return moment(this.text, this.format).isValid() ? this.text : this.value;
    },
    calendarValue() {
      const valueMoment = moment(this.value, this.format);
      const textMoment = moment(this.text, this.format);

      if (textMoment.isValid()) {
        return textMoment.toDate();
      } else if (textMoment.isValid()) {
        return valueMoment.toDate();
      } else {
        return null;
      }
    },
  },
  methods: {
    onFocusin() {
      this.active = true;
    },
    onFocusout(event) {
      if (!event.relatedTarget) {
        this.active = false;

        return;
      }

      if (
        this.containsDevEditComponentElement(
          event.relatedTarget,
          this._componentUUID
        )
      ) {
        return;
      }

      this.active = false;
    },
    onInputTextClick() {
      this.$nextTick(() => {
        this.$refs["input"].focus();
      });
    },
    onKeydownDelete(event) {
      event.preventDefault();

      const {
        keyCode,
        target: { selectionStart, selectionEnd },
      } = event;

      const deleteStartIndex =
        selectionStart === selectionEnd
          ? selectionStart + (keyCode === 8 ? -1 : 0)
          : selectionStart;
      const deleteEndIndex =
        selectionStart === selectionEnd
          ? selectionStart + (keyCode === 8 ? 0 : 1)
          : selectionEnd;

      const subString = event.target.value.substring(
        deleteStartIndex,
        deleteEndIndex
      );
      const maskString = subString.replace(/[0-9]/gi, "0");
      const result = replaceRange(
        event.target.value,
        maskString,
        deleteStartIndex,
        deleteEndIndex
      );

      this.text = result;
      this.$nextTick(() => {
        this.$refs["input"].setSelectionRange(
          deleteStartIndex,
          deleteStartIndex
        );
      });
    },
    onKeydownNumber(event) {
      event.preventDefault();

      const {
        key,
        target: { selectionStart },
      } = event;

      const keyValue = `${key}`;

      const deleteStartIndex = selectionStart;
      const deleteEndIndex = selectionStart + 1;
      const subString = event.target.value.substring(
        deleteStartIndex,
        deleteEndIndex
      );
      const maskString = subString.replace(/[0-9]/gi, keyValue);
      const result = replaceRange(
        event.target.value,
        maskString,
        deleteStartIndex,
        deleteEndIndex
      );

      this.text = result;
      let nextCursorIndex = selectionStart + 1;
      if (this.isHyphenIndex(nextCursorIndex)) {
        nextCursorIndex++;
      }

      this.$nextTick(() => {
        this.$refs["input"].setSelectionRange(nextCursorIndex, nextCursorIndex);
      });
    },
    onKeydownArrow(event) {
      event.preventDefault();

      const {
        key,
        target: { selectionStart, selectionEnd },
      } = event;

      let nextCursorIndex = selectionStart;

      if (key === "ArrowLeft") {
        if (selectionStart === selectionEnd) {
          nextCursorIndex = selectionStart - 1;
        } else {
          nextCursorIndex = selectionStart;
        }

        if (this.isHyphenIndex(nextCursorIndex)) {
          nextCursorIndex--;
        }
      } else if (key === "ArrowRight") {
        if (selectionStart === selectionEnd) {
          nextCursorIndex = selectionEnd + 1;
        } else {
          nextCursorIndex = selectionEnd;
        }

        if (this.isHyphenIndex(nextCursorIndex)) {
          nextCursorIndex++;
        }
      }

      this.$nextTick(() => {
        this.$refs["input"].setSelectionRange(nextCursorIndex, nextCursorIndex);
      });
    },
    onKeydown(event) {
      const { key } = event;

      if (key === "Tab") {
        return;
      }

      event.preventDefault();
    },
    onClearClick() {
      this.text = EMPTY_OF_FORMAT[this.format];
      this.$nextTick(() => {
        this.setSelectRangeFrontAndFocus();
      });
    },
    async onDatepickerOpen() {
      this.active = true;

      // map 초기화
      this.calendarListMap = new Map();

      // keyboard event 처리
      const datePickerInstance = this.$refs.datepicker.ej2Instances;
      datePickerInstance.contentElement.addEventListener("keyup", async () => {
        if (datePickerInstance.popupObj) {
          const focustDateMoment = getCurrentSelectedDateMomentInDateTimePicker(
            datePickerInstance
          );
          await getCalendarInfoAndSetCalendarContentElementDayClassNew(
            datePickerInstance.contentElement,
            focustDateMoment,
            this.calendarListMap
          );
        }
      });

      // calendar data loading & calendar dom setting
      let openDatePickerDate;
      if (this.value && this.value !== "0000-00-00") {
        openDatePickerDate = moment(this.value);
      } else {
        openDatePickerDate = moment();
      }
      if (openDatePickerDate.isValid()) {
        await getCalendarInfoAndSetCalendarContentElementDayClassNew(
          datePickerInstance.contentElement,
          openDatePickerDate,
          this.calendarListMap
        );
      }
    },
    onDatepickerClose() {
      this.active = false;

      this.renewalValueByEmitInput();
    },
    onDatepickerChange(args) {
      const valueMoment = moment(args.value);
      if (valueMoment.isValid()) {
        this.text = valueMoment.format(this.format);
        this.$nextTick(() => {
          this.$refs["input"].focus();
        });
      }
    },
    onTextFocusIn() {
      if (this.text) {
        this.$refs.input.setSelectionRange(0, this.text.length);
      }
    },
    setSelectRangeFrontAndFocus() {
      this.$refs["input"].setSelectionRange(0, 0);
      this.$refs["input"].focus();
    },
    isHyphenIndex(index) {
      return index === 4 || index === 7;
    },

    isDevEditComponentElement(element, id) {
      return element && element.dataset && element.dataset.id === id;
    },
    containsDevEditComponentElement(element, id) {
      if (this.isDevEditComponentElement(element, id)) {
        return true;
      } else {
        if (element.parentElement) {
          return this.containsDevEditComponentElement(
            element.parentElement,
            id
          );
        } else {
          return false;
        }
      }
    },
    onRenderDayCell(args) {
      const date = moment(args.date).format(DATE_FORMAT_YYYY_MM_DD);
      const spanElement = args.element.firstElementChild;

      // 모든 날짜 span element에 오늘 날짜로 ID 설정
      setIdInDatePickerSpan(date, spanElement);

      // calendarListMap 에 데이터가 있으면 표시해주기
      setDateElementCssClassOnRenderCell(
        args.date,
        spanElement.parentElement,
        this.calendarListMap
      );
    },
    async onNavigated(args) {
      if (args.view === "Month") {
        await getCalendarInfoAndSetCalendarContentElementDayClassNew(
          this.$refs.datepicker.ej2Instances.contentElement,
          moment(args.date),
          this.calendarListMap
        );
      }
    },
    // emit("input") 을 실행하여 value 갱신
    // focus out 될 때 / datepicker에서 날짜 선택 시 호출한다
    renewalValueByEmitInput() {
      if (!this.required && this.text === EMPTY_OF_FORMAT[this.format]) {
        if (this.notClear) {
          this.text = this.value || EMPTY_OF_FORMAT[this.format];
          this.$emit("input", this.value || EMPTY_OF_FORMAT[this.format]);
        } else {
          if (this.isBindingEmptyString) {
            this.$emit("input", "");
          } else {
            this.$emit("input", undefined);
          }
        }
      } else if (this.text !== this.value) {
        if (moment(this.text, this.format).isValid()) {
          this.$emit("input", this.text);
        } else {
          this.errorToast("Invalid Date");
          this.text = this.value || EMPTY_OF_FORMAT[this.format];
        }
      }
    },
    focus() {
      this.$refs["input"].focus();
      this.$refs["input"].select();
    },
  },
};
</script>
