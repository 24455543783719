<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-main">
          <div class="typePos-wrap">
            <div class="typePos-box">
              <div class="typePos-header">매출 계산서</div>
              <ul class="typePos-aside">
                <li>
                  <div class="typePos-title">판매일자</div>
                  <div class="typePos-content">
                    {{ salesDate }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">판매매장</div>
                  <div class="typePos-content">
                    {{ storeName }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">전표No</div>
                  <div class="typePos-content">
                    {{ slipNo }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">락커번호</div>
                  <div class="typePos-content">
                    {{ lockerNo }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">내장객</div>
                  <div class="typePos-content">
                    {{ visitName }}
                  </div>
                </li>
                <li>
                  <div class="typePos-title">테이블No</div>
                  <div class="typePos-content">
                    {{ tableName }}
                  </div>
                </li>
              </ul>
              <div class="typePos-body">
                <ul class="typePos-list typePos-first">
                  <li>
                    <div class="typePos-name">상품명</div>
                    <div class="typePos-count">수량</div>
                    <div class="typePos-price">금액</div>
                  </li>
                </ul>
                <ul class="typePos-list">
                  <li
                    v-for="(
                      { productName, saleQty, totAmt, caddieUseFlag, isDc }, index
                    ) in salesList"
                    v-bind:key="`sales-${productName}${index}`"
                  >
                    <div class="typePos-name">
                      {{ isDc ? "&nbsp;" + productName : (productName + (caddieUseFlag ? "(캐디)" : "")) }}
                    </div>
                    <div class="typePos-count">
                      {{ saleQty }}
                    </div>
                    <div class="typePos-price">
                      {{ totAmt | numberWithCommas }}
                    </div>
                  </li>
                </ul>
                <ul class="typePos-list">
                  <li>
                    <div class="typePos-name">NET</div>
                    <div class="typePos-price">
                      {{ netAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">부가세</div>
                    <div class="typePos-price">
                      {{ vatAmt | numberWithCommas }}
                    </div>
                  </li>
                </ul>
                <ul class="typePos-list">
                  <li v-if="dcAmount">
                    <div class="typePos-name">할인금액</div>
                    <div class="typePos-price">
                      {{ dcAmount | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">결제금액</div>
                    <div class="typePos-price">
                      {{ totalAmount | numberWithCommas }}
                    </div>
                  </li>
                </ul>
              </div>
              <div class="typePos-footer">
                <div class="typePos-title">서명</div>
                <div class="typePos-content"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="ui-check" style="width: 100px; float: left;">
            <li>
              <label>
                <input type="checkbox" v-model="isProductTotal" @change="onIsProductTotalChanged"/>
                <div class="i-item">상품집계</div>
              </label>
            </li>
          </ul>
          <ul class="typePos-button">
            <li class="typePos-print">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button i-typePrimary"
                :use-syncfusion-component-style="false"
                @click="onPrintClick"
                :disabled="commonMixinIsButtonDisableByAuth('salesMngPosGet')"
              >
                <div class="i-item">출력</div>
              </erp-button>
            </li>
            <li v-if="isClientMonitorUse">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button"
                :use-syncfusion-component-style="false"
                @click="clientMonitor"
              >
                <div class="i-item">
                  모니터
                  <span
                    v-if="clientMonitorState"
                    style="color: blue; font-weight: bold"
                  >
                    OFF
                  </span>
                  <span
                    v-else
                    style="color: red; font-weight: bold"
                  >
                    ON
                  </span>
                </div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {overflow: hidden; flex: 1; box-sizing: border-box; padding: 20px;}
.typePos .typePos-wrapper-main .typePos-wrap {overflow: auto; height: 100%; border: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-box {padding: 16px 20px 20px 20px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-header {color: #222; font-size: 16px; font-family: '돋움', Dotum; font-weight: bold; line-height: 24px; text-align: center;}
.typePos .typePos-wrapper-main .typePos-box .typePos-aside {list-style: none; padding: 12px 0 10px 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-box .typePos-aside > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-box .typePos-aside > li .typePos-title {float: left; width: 40%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-aside > li .typePos-content {float: left; width: 60%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body {border-top: 1px solid #222; border-bottom: 1px solid #222;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list {list-style: none; padding: 12px 0 10px 0; margin: 0; border-top: 1px dashed #222;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list.typePos-first {border-top: none;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list > li .typePos-name {float: left; width: 60%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list > li .typePos-count {float: left; width: 20%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px; text-align: right;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list > li .typePos-price {float: right; width: 20%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px; text-align: right;}
.typePos .typePos-wrapper-main .typePos-box .typePos-footer {padding-top: 5px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-box .typePos-footer .typePos-title {float: left; width: 20%; color: #222; padding: 12px 0 10px 0; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-footer .typePos-content {float: left; width: 80%; height: 19px; padding: 12px 0 10px 0; border-bottom: 1px dashed #222;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-print .ui-button .i-item::before {background-position-x: calc(-20px * 11);}
</style>

<script>
import moment from "moment";
import { commonCodesGetComName} from '@/utils/commonCodes';
import { numberWithCommas } from "@/utils/number";
import { getDeviceInfo } from "@/utils/device";
import BillPrintUtil from "@/utils/billPrintUtil";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {getSalesReceipt} from "@/api/storePointOfSaleSystem";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "PrintBillPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ErpButton,
  },
  filters: {
    numberWithCommas,
  },
  data() {
    return {
      isProductTotal: true,
      slip: {},
      receiptInfo: {},
      imageList: [],
      clientMonitorState: false,
      currentStore: {},
    };
  },
  computed: {
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "계산서 발행",
        width: 440,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    salesDate() {
      return !this.receiptInfo || !this.receiptInfo.salesDate
        ? ""
        : moment(this.receiptInfo.salesDate).format("YYYY년 MM월 DD일");
    },
    storeName() {
      return !this.receiptInfo || !this.receiptInfo.storeCode
        ? ""
        : commonCodesGetComName("STORE_CODE", this.receiptInfo.storeCode);
    },
    slipNo() {
      return !this.receiptInfo || !this.receiptInfo.slipNo
        ? ""
        : this.receiptInfo.slipNo;
    },
    lockerNo() {
      return !this.receiptInfo || !this.receiptInfo.lockerNo
        ? ""
        : this.receiptInfo.lockerNo;
    },
    visitName() {
      return !this.receiptInfo || !this.receiptInfo.visitName
        ? ""
        : this.receiptInfo.visitName;
    },
    tableName() {
      return !this.receiptInfo || !this.receiptInfo.tableName
        ? ""
        : this.receiptInfo.tableName;
    },
    salesList() {
      return !this.receiptInfo || !this.receiptInfo.salesList
        ? []
        : this.receiptInfo.salesList.map(item => {
          if (item.isDc) {
            const sale = this.receiptInfo.salesList.find(data => data.productCode === item.productCode && !data.isDc);

            if (sale && sale.totAmt) {
              item.saleQty = Math.trunc(Math.abs(item.totAmt) / sale.totAmt * 100) + "%";
            }
          }
          return ({
            ...item,
          });
        });
    },
    netAmt() {
      return !this.receiptInfo || !this.receiptInfo.netAmt
        ? 0
        : this.receiptInfo.netAmt;
    },
    vatAmt() {
      return !this.receiptInfo || !this.receiptInfo.vatAmt
        ? 0
        : this.receiptInfo.vatAmt;
    },
    dcAmount() {
      return this.salesList.filter(item => item.isDc).map(item => item.totAmt).reduce((acc, cur) => cur + acc, 0);
    },
    totalAmount() {
      return this.netAmt + this.vatAmt;
    },
    isClientMonitorUse() {
      return getDeviceInfo().isClientMonitorUse;
    },
  },
  methods: {
    async show({ slip, imageList, currentStore }) {
      const {
        value: { receiptInfo },
      } = await getSalesReceipt(slip.id, this.isProductTotal, currentStore?.golfCabinDivision, true);

      this.slip = slip;
      this.receiptInfo = receiptInfo;
      this.imageList = imageList;
      this.currentStore = currentStore;

      this.$refs["dialog"].show();
    },
    async onIsProductTotalChanged() {
      await this.getSalesReceipt();
      if (this.isClientMonitorUse) {
        await this.clientMonitor(false);
      }
    },
    async getSalesReceipt() {
      const {
        value: { receiptInfo },
      } = await getSalesReceipt(this.slip.id, this.isProductTotal, this.currentStore.golfCabinDivision, true);

      this.receiptInfo = receiptInfo;
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
      if (this.isClientMonitorUse) {
        this.clientMonitorState = false;
        this.sendWebSocket('mode', false); // 고객 모니터 대기 모드로 전환
      }
    },
    async onPrintClick() {
      await this.print();
    },
    onCloseClick() {
      this.hide();
      if (this.isClientMonitorUse) {
        this.clientMonitorState = false;
        this.sendWebSocket('mode', false); // 고객 모니터 대기 모드로 전환
      }
    },
    async clientMonitor(isAutoChange = true) {
      if (isAutoChange) {
        this.clientMonitorState = !this.clientMonitorState;
        this.sendWebSocket('mode', this.clientMonitorState);
      }
      if (!this.clientMonitorState) {
        return;
      }
      const storeCode = 'GRILL';
      const storeName = commonCodesGetComName('STORE_CODE', storeCode);
      const result = {};
      result.feeAmt = result.rentAmt = result.shopAmt = 0;
      result.foodAmt = result.totAmt = this.totalAmount;
      result.visitInfo = {
        grpName: storeName,
        visitName: this.lockerNo
          ? `${this.visitName} (${this.lockerNo})`
          : this.visitName,
        time: this.tableName,
        course: this.salesDate,
      };
      result.output = [storeCode];
      result.detail = this.salesList
          ?.map(item => ({
            storeCode,
            storeName,
            productName: item.isDc ? "&nbsp;" + item.productName : (item.productName + (item.caddieUseFlag ? '(캐디)' : '')),
            saleQty: item.saleQty,
            totAmt: item.totAmt,
          }));
      result.imageListForGrill = this.imageList;
      result.isGrill = true;
      this.sendWebSocket('bill', result);
    },
    async print() {
      // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.
      // const deviceInfo = getDeviceInfo();


      const printSetData = {
        salesDate: this.salesDate,
        storeName: this.storeName,
        slipNo: this.slipNo,
        lockerNo: this.lockerNo,
        visitName: this.visitName,
        tableName: this.tableName,
        salesList: this.salesList?.map(item => ({
          ...item,
          productName: item.productName + (item.caddieUseFlag ? '(캐디)' : ''),
        })),
        netAmt: this.netAmt,
        vatAmt: this.vatAmt,
        totalAmount: this.totalAmount,
        dcAmount: this.dcAmount ? this.dcAmount : null,
      };
      const config = {
        "useBillPrinter": true,
        "useKitchenPrinter": false,
      };
      await BillPrintUtil.getTsConfReceiptInfo.call(this, "SALES_STATEMENT", true, printSetData, config);


    },
  },
};
</script>
