<template>
  <div>
    <ejs-dialog ref="dialog" v-bind="dialogProps" @close="onDialogClose">
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-main">
          <div class="typePos-header">메모 입력</div>
          <div class="typePos-body">
            <div class="typePos-filed">
              <textarea
                ref="input-memo"
                placeholder="메모를 입력하세요."
                v-model="memoContents"
              />
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li class="typePos-add">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                class="ui-button"
                :use-syncfusion-component-style="false"
                @click.native="onSubmitClick"
              >
                <div class="i-item">확인</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click.native="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-header {padding: 20px; border-bottom: 1px solid #bebebe;}
.typePos .typePos-wrapper-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-header .typePos-body {border-radius: 4px; background-color: #f5f5f5; text-align: center; line-height: 0;}
.typePos .typePos-wrapper-header .typePos-body .typePos-list {display: inline-block; list-style: none; padding: 17px 20px 16px 20px; margin: 0;}
.typePos .typePos-wrapper-header .typePos-body .typePos-list::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-header .typePos-body .typePos-list > li {position: relative; float: left; padding: 0 15px 0 16px; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-header .typePos-body .typePos-list > li::before {display: block; position: absolute; top: 50%; left: 0; width: 1px; height: 8px; margin-top: -4px; background-color: #e0e0e0; content: '';}
.typePos .typePos-wrapper-header .typePos-body .typePos-list > li:first-child {padding: 0 15px;}
.typePos .typePos-wrapper-header .typePos-body .typePos-list > li:first-child::before {display: none;}

.typePos .typePos-wrapper-main {border-bottom: 1px solid #bebebe;}
.typePos .typePos-wrapper-main .typePos-header {padding: 16px 20px 0 20px; color: #222; font-size: 18px; font-family: 'NanumSquare-Regular'; line-height: 27px;}
.typePos .typePos-wrapper-main .typePos-body {padding: 4px 20px 15px 20px;}
.typePos .typePos-wrapper-main .typePos-body .typePos-filed {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-body .typePos-filed textarea {box-sizing: border-box; box-shadow: none; width: 100%; height: 82px; padding: 12px 15px 11px 15px; margin: 0; border: 1px solid #e0e0e0; border-radius: 4px; background-color: #fff; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; resize: none;}
.typePos .typePos-wrapper-main .typePos-body .typePos-filed textarea::-ms-clear {display: none;}
.typePos .typePos-wrapper-main .typePos-body .typePos-filed textarea::placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-body .typePos-filed textarea::-webkit-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-body .typePos-filed textarea:-ms-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-body .typePos-filed textarea:focus {border-color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-body .typePos-filed textarea:disabled,
.typePos .typePos-wrapper-main .typePos-body .typePos-filed textarea:disabled:focus {background-color: #eee; color: #aaa; cursor: default;}

.typePos .typePos-wrapper-aside {display: flex; flex-direction: column; flex: 1; overflow: hidden;}
.typePos .typePos-wrapper-aside .typePos-header {padding: 16px 20px 9px 20px; color: #222; font-size: 18px; font-family: 'NanumSquare-Regular'; line-height: 27px; border-bottom: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-aside .typePos-body {flex: 1; overflow: auto;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li {position: relative; padding: 16px 20px 16px 20px; border-top: 1px dotted #e0e0e0;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li:first-child {border-top: none;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-information::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-information .typePos-number {float: left; padding-right: 15px; color: #18b3c9; font-size: 13px; font-family: 'NanumSquare-Bold'; line-height: 19px;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-information .typePos-date {position: relative; float: left; padding-left: 16px; color: #999; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-information .typePos-date::before {display: block; position: absolute; top: 50%; left: 0; width: 1px; height: 8px; margin-top: -4px; background-color: #e0e0e0; content: '';}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-content {padding-top: 7px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-delete {position: absolute; top: 15px; right: 20px;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-delete >>> .ui-button {box-shadow: 0 1px 1px 0 rgba(0, 0 , 0, 0.05); padding: 2px 9px; border-radius: 2px;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-delete >>> .ui-button .i-item {padding-left: 0;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-delete >>> .ui-button .i-item::before {display: none;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-delete >>> .ui-button:disabled {box-shadow: none;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-add >>> .ui-button .i-item::before {background-position-x: calc(-20px * 12);}
</style>

<script>
import GolfERPService from "@/service/GolfERPService";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { numberWithCommas } from "@/utils/number";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "SlipMemoPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ErpButton,
  },
  props: {
    /*slip: {
      type: Object,
      required: true,
    },*/
  },
  filters: {
    numberWithCommas,
  },
  data() {
    return {
      memoContents: "",
    };
  },
  computed: {
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "전표 메모",
        width: 560,
        height: 300,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }
      return dialogProps;
    },
  },
  methods: {
    show( slipMemoContents ) {
      this.memoContents = slipMemoContents;
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    async onSubmitClick() {
      if (!this.memoContents) {
        this.errorToast("내용을 입력해주세요.");
        this.$refs["input-memo"].focus();
        return;
      }
      this.$emit("submit",this.memoContents);
      this.hide();
    },
    onCloseClick() {
      this.hide();
    },
    // features
  },
};
</script>
