import { has } from "@/utils/dom/dom";
import { v4 as createUUID } from "uuid";

export default {
  created() {
    window.addEventListener("click", this.onWindowClick);
  },
  destroyed() {
    window.removeEventListener("click", this.onWindowClick);
  },
  data() {
    return {
      uid: createUUID(),
      active: false,
    };
  },
  computed: {
    $activeComponentAttrs() {
      return Object.assign({}, this.$attrs, {
        "data-vue-component-uid": this.uid,
      });
    },
  },
  methods: {
    onWindowClick(event) {
      if (
        !has(
          document
            .querySelectorAll(`[data-vue-component-uid='${this.uid}']`)
            .item(0),
          event.target
        )
      ) {
        this.deactivate();
      } else {
        this.activate();
      }
    },
    activate() {
      this.active = true;
    },
    deactivate() {
      this.active = false;
    },
    toggleActive() {
      this.active = !this.active;
    },
  },
};
