<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-main">
          <div class="typePos-wrap">
            <div class="typePos-box">
              <div class="typePos-container">
                <div class="typePos-header">판매금액</div>
                <ul class="typePos-body">
                  <li>
                    <div class="typePos-title">판매금액</div>
                    <div class="typePos-content">
                      {{ saleAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-title">할인금액</div>
                    <div class="typePos-content">
                      {{ dcAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-title">매출금액</div>
                    <div class="typePos-content">
                      {{ salesAmt | numberWithCommas }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="typePos-box">
              <div class="typePos-container">
                <div class="typePos-header">내장팀</div>
                <ul class="typePos-body">
                  <li>
                    <div class="typePos-title">내장팀</div>
                    <div class="typePos-content">{{ visitTeam }}팀</div>
                  </li>
                  <li>
                    <div class="typePos-title">정산팀</div>
                    <div class="typePos-content">{{ payTeam }}팀</div>
                  </li>
                  <li class="typePos-strong">
                    <div class="typePos-title">미정산</div>
                    <div class="typePos-content">{{ noPayTeam }}팀</div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="typePos-box">
              <div class="typePos-container">
                <div class="typePos-header">정산현황</div>
                <ul class="typePos-body">
                  <li>
                    <div class="typePos-title">현금</div>
                    <div class="typePos-content">
                      {{ cashAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-title">카드</div>
                    <div class="typePos-content">
                      {{ cardAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-title">미수</div>
                    <div class="typePos-content">
                      {{ creditAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-title">선수금</div>
                    <div class="typePos-content">
                      {{ depositAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-title">선불카드</div>
                    <div class="typePos-content">
                      {{ precardAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-title">기타</div>
                    <div class="typePos-content">
                      {{ etcAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-title">선불정산</div>
                    <div class="typePos-content">
                      {{ prepayAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-title">후불정산</div>
                    <div class="typePos-content">
                      {{ postpayAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li class="typePos-strong">
                    <div class="typePos-title">미정산액</div>
                    <div class="typePos-content">
                      {{ noPayAmt | numberWithCommas }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="typePos-box">
              <div class="typePos-container">
                <div class="typePos-header">전표상세</div>
                <ul class="typePos-body">
                  <li>
                    <div class="typePos-title">정상전표</div>
                    <div class="typePos-content">{{ okSlip }}건</div>
                  </li>
                  <li>
                    <div class="typePos-title">취소전표</div>
                    <div class="typePos-content">{{ cancelSlip }}건</div>
                  </li>
                  <li>
                    <div class="typePos-title">인원수</div>
                    <div class="typePos-content">{{ personCnt }}명</div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="typePos-box">
              <div class="typePos-container">
                <div class="typePos-header">분류별 매출</div>
                <ul class="typePos-body">
                  <li
                    v-for="({ classCode, totAmt }, index) in salesByClass"
                    v-bind:key="`salesByClass-${index}`"
                  >
                    <div class="typePos-title">
                      {{ getClassNameByClassCode(classCode) }}
                    </div>
                    <div class="typePos-content">
                      {{ totAmt | numberWithCommas }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <div class="typePos-title">
            매장
          </div>
          <ul class="typePos-multiselect">
            <li>
              <ejs-multiselect
                v-model="searchConditions.storeCodes"
                :dataSource="searchOptions.storeCodeOptions"
                :fields="commonCodeFields"
                placeHolder="매장을 선택해 주시기 바랍니다"
              />
            </li>
          </ul>
          <ul class="typePos-button">
            <li class="typePos-refresh">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button"
                :is-custom-shortcut-button="true"
                :shortcut-key="refreshShortcutButtonProps.shortcutKey"
                :shortcut="refreshShortcutButtonProps.shortcut"
                :visible-shortcut-string="false"
                :use-syncfusion-component-style="false"
                @click.native="onRefreshClick"
              >
                <div class="i-item">
                  새로고침 ({{
                    toShortcutString(refreshShortcutButtonProps.shortcut)
                  }})
                </div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                id="closeButton"
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click.native="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {overflow: auto; flex: 1; box-sizing: border-box;}
.typePos .typePos-wrapper-main .typePos-wrap {padding: 10px;}
.typePos .typePos-wrapper-main .typePos-wrap::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-box {box-sizing: border-box; float: left; width: 50%; padding: 10px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-container {border-top: 1px solid #222; border-bottom: 1px solid #222;}
.typePos .typePos-wrapper-main .typePos-box .typePos-header {padding: 11px 0 10px 0; color: #222; font-size: 16px; font-family: '돋움', Dotum; font-weight: bold; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body > li {padding: 7px 0 5px 0; border-top: 1px dashed #222;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-box .typePos-body > li .typePos-title {float: left; width: 60%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body > li .typePos-content {float: right; width: 40%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px; text-align: right;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body > li.typePos-strong .typePos-title,
.typePos .typePos-wrapper-main .typePos-box .typePos-body > li.typePos-strong .typePos-content {color: #df2929;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-refresh .ui-button .i-item::before {background-position-x: calc(-20px * 6);}

/* TODO : multi-select 퍼블리싱 작업 필요 */
.typePos .typePos-wrapper-footer .typePos-title {
  float: left; padding: 6px 0 7px 0; color: #222; font-size: 18px; font-family: 'NanumSquare-Regular'; line-height: 27px; width: 40px;
}
.typePos .typePos-wrapper-footer .typePos-multiselect {float: left; width: calc(100% - 285px); list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-multiselect::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
</style>

<script>
import moment from "moment";
import { getSalesStatus } from "@/api/storePointOfSaleSystem";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import {
  commonCodesGetComName,
  commonCodesGetCommonCodeByIdxAttrb,
  commonCodesGetCommonCodeAttrbByCodeAndIdx
} from "@/utils/commonCodes";
import { SHORTCUT_KEYS, toShortcutString } from "@/utils/KeyboardUtil";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "PosStatusPopup",
  components: {
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  props: {
    salesDate: {
      type: Date,
      required: true,
    },
    store: {
      type: Object,
      required: true,
    },
  },
  filters: {
    numberWithCommas,
  },
  data() {
    return {
      salesStatus: {},
      commonCodeFields: { text: "comName", value: "comCode" },
      searchOptions: {
        storeCodeOptions: null,
      },
      searchConditions: {
        storeCodes: []
      }
    };
  },
  created() {
    this.searchOptions.storeCodeOptions =
      commonCodesGetCommonCodeByIdxAttrb(
        "STORE_CODE",
        2,
        commonCodesGetCommonCodeAttrbByCodeAndIdx("STORE_CODE", this.store.code, 2, false)
      );

    const findIndex = this.searchOptions.storeCodeOptions.findIndex((obj) => obj.comCode === "FRONT");
    if (findIndex >= 0) {
      this.searchOptions.storeCodeOptions.splice(findIndex, 1);
    }

    this.searchConditions.storeCodes = this.searchOptions.storeCodeOptions.map(data => {
      return data.comCode;
    });
  },
  computed: {
    refreshShortcutButtonProps() {
      return {
        shortcutKey: "PosStatusPopup.shortcuts.refresh",
        shortcut: {
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          key: SHORTCUT_KEYS.F2,
        },
        visibleShortcutString: false,
      };
    },
    bsnDateLocaleString() {
      const bsnDateMoment = moment(this.salesDate);
      return bsnDateMoment.format(
        `YYYY/MM/DD (${this.toLocaleStringDayOfWeek(
          bsnDateMoment.isoWeekday()
        )})`
      );
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: `POS 영업현황 ${this.bsnDateLocaleString}`,
        width: 500,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    saleAmtInfo() {
      return this.salesStatus.saleAmtInfo || {};
    },
    visitTeamInfo() {
      return this.salesStatus.visitTeamInfo || {};
    },
    payStatusInfo() {
      return this.salesStatus.payStatusInfo || {};
    },
    slipDetail() {
      return this.salesStatus.slipDetail || {};
    },
    salesByClass() {
      return this.salesStatus.salesByClass || [];
    },
    saleAmt() {
      return this.saleAmtInfo.saleAmt || 0;
    },
    dcAmt() {
      return this.saleAmtInfo.dcAmt || 0;
    },
    salesAmt() {
      return this.saleAmtInfo.salesAmt || 0;
    },
    visitTeam() {
      return this.visitTeamInfo.visitTeam || 0;
    },
    payTeam() {
      return this.visitTeamInfo.payTeam || 0;
    },
    noPayTeam() {
      return this.visitTeamInfo.noPayTeam || 0;
    },
    cashAmt() {
      return this.payStatusInfo.cashAmt || 0;
    },
    cardAmt() {
      return this.payStatusInfo.cardAmt || 0;
    },
    creditAmt() {
      return this.payStatusInfo.creditAmt || 0;
    },
    depositAmt() {
      return this.payStatusInfo.depositAmt || 0;
    },
    precardAmt() {
      return this.payStatusInfo.precardAmt || 0;
    },
    etcAmt() {
      return this.payStatusInfo.etcAmt || 0;
    },
    prepayAmt() {
      return this.payStatusInfo.prepayAmt || 0;
    },
    postpayAmt() {
      return this.payStatusInfo.postpayAmt || 0;
    },
    noPayAmt() {
      return this.payStatusInfo.noPayAmt || 0;
    },
    okSlip() {
      return this.slipDetail.okSlip || 0;
    },
    cancelSlip() {
      return this.slipDetail.cancelSlip || 0;
    },
    personCnt() {
      return this.slipDetail.personCnt || 0;
    },
  },
  methods: {
    show() {
      this.getSalesStatus();
      this.$refs["dialog"].show();
      document.getElementById("closeButton").focus();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },

    toLocaleStringDayOfWeek(dayOfWeek) {
      switch (dayOfWeek) {
        case 1:
          return "월";
        case 2:
          return "화";
        case 3:
          return "수";
        case 4:
          return "목";
        case 5:
          return "금";
        case 6:
          return "토";
        case 7:
          return "일";
      }

      return "";
    },
    toShortcutString,

    getClassNameByClassCode(classCode) {
      return commonCodesGetComName("CLASS_CODE_1", classCode);
    },

    onRefreshClick() {
      this.getSalesStatus();
    },
    onCloseClick() {
      this.hide();
    },

    // API
    async getSalesStatus() {
      if (!(this.searchConditions.storeCodes.length > 0)) {
        this.errorToast("매장을 선택해주시기 바랍니다.");
        return;
      }
      const {
        value: { salesStatus },
      } = await getSalesStatus(
        moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
        this.searchConditions.storeCodes
      );

      this.salesStatus = salesStatus;
    },
  },
};
</script>
