<template>
  <div>
    <ejs-dialog ref="dialog" v-bind="dialogProps" @close="onDialogClose">
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-caption">
          <div class="typePos-header">
            <div class="typePos-filed typePos-code">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <f-select
                    class="ui-select"
                    :items="commonDepthOneProductCategoriesForSelect"
                    v-model="selectedCommonDepthOneProductCategoryId"
                  />
                </div>
                <div class="typePos-item">
                  <f-select
                    class="ui-select"
                    :items="commonDepthTwoProductCategoriesForSelect"
                    v-model="selectedCommonDepthTwoProductCategoryId"
                  />
                </div>
                <div class="typePos-item">
                  <f-select
                    class="ui-select"
                    :items="commonDepthThreeProductCategoriesForSelect"
                    v-model="selectedCommonDepthThreeProductCategoryId"
                  />
                </div>
                <div class="typePos-item">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input
                      ref="input-q"
                      type="text"
                      placeholder="상품명, 상품코드"
                      v-model="productNameCode"
                      @keypress.enter="onKeypressEnter"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="typePos-filed">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <ul class="ui-check">
                    <li>
                      <label>
                        <!-- 비활성(Attribute) : disabled -->
                        <input type="checkbox" v-model="optnFlag" />
                        <div class="i-item">옵션상품</div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="typePos-lookup">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button i-typePrimary"
                :use-syncfusion-component-style="false"
                :is-shortcut-button="true"
                :visible-shortcut-string="false"
                @click.native="onSearchClick"
              >
                <div class="i-item">
                  조회 ({{ searchShortcutButtonShortcutString }})
                </div>
              </erp-button>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-main">
          <ejs-grid-wrapper
            ref="productListGrid"
            v-bind="productListGridProps"
            @queryCellInfo="onProductListGridQueryCellInfo"
            @onGridDialogDoubleClickedOrEnterKeyed="
              onProductListGridRecordDoubleClick
            "
            @dataBound="onProductListGridDataBound"
          />
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                class="ui-button i-typePrimary i-iconConfirm"
                :use-syncfusion-component-style="false"
                @click="onSubmitClick"
              >
                <div class="i-item">확인</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-caption {padding: 10px; border-bottom: 1px solid #bebebe;}
.typePos .typePos-wrapper-caption::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed,
.typePos .typePos-wrapper-caption .typePos-lookup {float: left;}
.typePos .typePos-wrapper-caption .typePos-filed {padding: 0 3px;}
.typePos .typePos-wrapper-caption .typePos-lookup {padding: 0 10px;}
.typePos .typePos-wrapper-caption .typePos-filed::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content {float: left; padding: 0 7px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title {padding-top: 10px; padding-bottom: 11px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item {float: left; padding-left: 10px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item:first-child {padding-left: 0;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content {float: left; padding-top: 10px; padding-bottom: 11px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title {padding-right: 4px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content strong {color: #18b3c9; font-weight: normal;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content em {color: #df2929; font-style: normal;}
.typePos .typePos-wrapper-caption .typePos-lookup >>> .ui-button .i-item::before {background-position-x: calc(-20px * 7);}
.typePos .typePos-wrapper-caption .typePos-header {float: left;}
.typePos .typePos-wrapper-caption .typePos-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-code .typePos-filed-content .typePos-item .ui-select {width: 180px;}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-code .typePos-filed-content .typePos-item .ui-input {width: 180px;}
.typePos .typePos-wrapper-caption .typePos-footer {float: right;}
.typePos .typePos-wrapper-caption .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}

.typePos .typePos-wrapper-main {flex: 1; overflow: hidden;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
</style>

<script>
import { getProductList } from "@/api/storePointOfSaleSystem";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { commonCodesGetColorValue } from "@/utils/commonCodes";
import { ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import FSelect from "@/views/store-management/sales-registration/store-pos-renew/components/FSelect";
import { sortBy as _sortBy } from "lodash";
import GolfERPService from "@/service/GolfERPService";
import ErpButton from "@/components/button/ErpButton";
import {
  getSearchButtonShortcut,
  toShortcutString,
} from "@/utils/KeyboardUtil";

export default {
  name: "SearchProductsPopup",
  components: { FSelect, EjsGridWrapper, ErpButton },
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  watch: {
    selectedCommonDepthOneProductCategoryId(
      selectedCommonDepthOneProductCategoryId
    ) {
      this.selectedCommonDepthTwoProductCategoryId = "";
      this.selectedCommonDepthThreeProductCategoryId = "";
      this.fetchCommonDepthTwoProductCategories(
        selectedCommonDepthOneProductCategoryId
      );
    },
    selectedCommonDepthTwoProductCategoryId(
      selectedCommonDepthTwoProductCategoryId
    ) {
      this.selectedCommonDepthThreeProductCategoryId = "";
      this.fetchCommonDepthThreeProductCategories(
        selectedCommonDepthTwoProductCategoryId
      );
    },
  },
  data() {
    return {
      selectedCommonDepthOneProductCategoryId: "",
      selectedCommonDepthTwoProductCategoryId: "",
      selectedCommonDepthThreeProductCategoryId: "",
      productNameCode: "",
      optnFlag: false,

      commonDepthOneProductCategories: [],
      commonDepthTwoProductCategories: [],
      commonDepthThreeProductCategories: [],

      products: [],
    };
  },
  computed: {
    searchShortcutButtonShortcutString() {
      return toShortcutString(getSearchButtonShortcut());
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "상품코드 검색",
        width: 1160,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    commonDepthOneProductCategoriesForSelect() {
      const commonDepthOneProductCategoriesForSelect = this.commonDepthOneProductCategories.map(
        (category) => ({
          name: category.name,
          value: category.code,
        })
      );

      commonDepthOneProductCategoriesForSelect.unshift({
        name: "전체",
        value: "",
      });

      return commonDepthOneProductCategoriesForSelect;
    },
    commonDepthTwoProductCategoriesForSelect() {
      const commonDepthTwoProductCategoriesForSelect = this.commonDepthTwoProductCategories.map(
        (category) => ({
          name: category.name,
          value: category.code,
        })
      );

      commonDepthTwoProductCategoriesForSelect.unshift({
        name: "전체",
        value: "",
      });

      return commonDepthTwoProductCategoriesForSelect;
    },
    commonDepthThreeProductCategoriesForSelect() {
      const commonDepthThreeProductCategoriesForSelect = this.commonDepthThreeProductCategories.map(
        (category) => ({
          name: category.name,
          value: category.code,
        })
      );

      commonDepthThreeProductCategoriesForSelect.unshift({
        name: "전체",
        value: "",
      });

      return commonDepthThreeProductCategoriesForSelect;
    },

    productListGridProps() {
      const dataSource = !this.products
        ? []
        : this.products.map((record) => ({
            ...record,
            code: record.productCode,
            name: record.productName,
            price: record.price1,
          }));

      return {
        isSelectedRowRetain: false,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: [
          // {
          //   field: "className01",
          //   headerText: "대분류",
          //   textAlign: "Center",
          //   type: "string"
          // },
          // {
          //   field: "className02",
          //   headerText: "중분류",
          //   textAlign: "Center",
          //   type: "string"
          // },
          {
            field: "className03",
            headerText: "소분류",
            textAlign: "Center",
            type: "string",
          },
          {
            field: "name",
            headerText: "상품명",
            textAlign: "Left",
            type: "string",
          },
          {
            field: "code",
            headerText: "상품코드",
            textAlign: "Left",
            type: "string",
          },
          {
            field: "notaxCode",
            headerText: "면/과세",
            allowEditing: false,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "NOTAX_CODE",
            type: "string",
          },
          {
            field: "price",
            headerText: "판매금액",
            textAlign: "Right",
            type: "string",
            isNumericType: true,
          },
        ],
        dataSource,
        isInPopup: true,
        provides: [Resize, ForeignKey],
        selectionSettings: { type: "Single", enableToggle: false },
        useCommonStyles: false,
      };
    },
  },
  methods: {
    show() {
      this.fetchCommonDepthOneProductCategories();
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },

    onDialogClose() {
      this.$emit("close");
    },
    onKeypressEnter() {
      this.fetchProductList();
    },
    onSearchClick() {
      this.fetchProductList();
    },
    onProductListGridQueryCellInfo(args) {
      const {
        cell,
        column: { field },
        data,
      } = args;

      if (field === "notaxCode") {
        if (data[field]) {
          cell.style.color = commonCodesGetColorValue(
            "NOTAX_CODE",
            data[field]
          );
        }
      }
    },
    onProductListGridRecordDoubleClick() {
      const [rowData] = this.$refs["productListGrid"].getSelectedRecords();
      this.$emit("submit", {
        product: rowData,
      });
      this.hide();
    },
    onProductListGridDataBound() {
      if (this.products.length > 0) {
        this.$refs.productListGrid.selectRow(0);
      } else {
        this.$refs["input-q"].focus();
      }
    },
    onSubmitClick() {
      const [product] = this.$refs["productListGrid"].getSelectedRecords();
      if (!product) {
        return;
      }

      this.$emit("submit", {
        product,
      });
      this.hide();
    },
    onCloseClick() {
      this.hide();
    },

    // API
    async fetchCommonDepthOneProductCategories() {
      this.commonDepthOneProductCategories = _sortBy(
        (await GolfERPService.fetchProductClasses(1)) || [],
        "order"
      );
    },
    async fetchCommonDepthTwoProductCategories(parentCategoryId) {
      this.commonDepthTwoProductCategories = _sortBy(
        (parentCategoryId
          ? await GolfERPService.fetchProductClasses(2, parentCategoryId)
          : []) || [],
        "order"
      );
    },
    async fetchCommonDepthThreeProductCategories(parentCategoryId) {
      this.commonDepthThreeProductCategories = _sortBy(
        (parentCategoryId
          ? await GolfERPService.fetchProductClasses(3, parentCategoryId)
          : []) || [],
        "order"
      );
    },
    async fetchProductList() {
      const {
        value: { productCodes },
      } = await getProductList(
        this.store.code,
        this.selectedCommonDepthOneProductCategoryId || undefined,
        this.selectedCommonDepthTwoProductCategoryId || undefined,
        this.selectedCommonDepthThreeProductCategoryId || undefined,
        this.productNameCode || undefined,
        this.optnFlag,
        false
      );

      this.products = productCodes || [];
    },
  },
};
</script>
