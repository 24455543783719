<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div class="typePos">
        <div class="typePos-wrapper-header">
          <div class="typePos-body">
            <ul class="typePos-list">
              <li>
                {{ slip.number }}
              </li>
              <li>
                {{ slipVisitorLockerNumber }}
              </li>
              <li>
                {{ slipVisitorName }}
              </li>
              <li>
                {{ slipSalesProductNames }} :
                {{ slipSalesTotalAmounts | numberWithCommas }}원
              </li>
            </ul>
          </div>
        </div>
        <div class="typePos-wrapper-main">
          <div class="typePos-filed">
            <textarea
              ref="input-reason"
              placeholder="삭제사유를 입력하세요."
              v-model="reason"
            />
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                class="ui-button i-typePrimary i-iconConfirm"
                :is-shortcut-button="true"
                :visible-shortcut-string="false"
                :use-syncfusion-component-style="false"
                @click="onSubmitClick"
              >
                <div class="i-item">
                  확인 ({{ saveShortcutButtonShortcutString }})
                </div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-header {padding: 20px 20px 0 20px;}
.typePos .typePos-wrapper-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-header .typePos-body {border-radius: 4px; background-color: #f5f5f5; text-align: center; line-height: 0;}
.typePos .typePos-wrapper-header .typePos-body .typePos-list {display: inline-block; list-style: none; padding: 17px 20px 16px 20px; margin: 0;}
.typePos .typePos-wrapper-header .typePos-body .typePos-list::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-header .typePos-body .typePos-list > li {position: relative; float: left; padding: 0 15px 0 16px; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-header .typePos-body .typePos-list > li::before {display: block; position: absolute; top: 50%; left: 0; width: 1px; height: 8px; margin-top: -4px; background-color: #e0e0e0; content: '';}
.typePos .typePos-wrapper-header .typePos-body .typePos-list > li:first-child {padding: 0 15px;}
.typePos .typePos-wrapper-header .typePos-body .typePos-list > li:first-child::before {display: none;}

.typePos .typePos-wrapper-main {flex: 1; padding: 15px 20px;}
.typePos .typePos-wrapper-main .typePos-filed {box-sizing: border-box; height: 100%; padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-filed textarea {box-sizing: border-box; box-shadow: none; width: 100%; height: 100%; padding: 12px 15px 11px 15px; margin: 0; border: 1px solid #e0e0e0; border-radius: 4px; background-color: #fff; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; resize: none;}
.typePos .typePos-wrapper-main .typePos-filed textarea::-ms-clear {display: none;}
.typePos .typePos-wrapper-main .typePos-filed textarea::placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-filed textarea::-webkit-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-filed textarea:-ms-input-placeholder {color: #aaa;}
.typePos .typePos-wrapper-main .typePos-filed textarea:focus {border-color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-filed textarea:disabled,
.typePos .typePos-wrapper-main .typePos-filed textarea:disabled:focus {background-color: #eee; color: #aaa; cursor: default;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { numberWithCommas } from "@/utils/number";
import { getSlipStatus } from "@/store/modules/pos";
import ErpButton from "@/components/button/ErpButton";
import { getSaveButtonShortcut, toShortcutString } from "@/utils/KeyboardUtil";

export default {
  name: "ConfirmDeleteSlipPopup",
  components: { ErpButton },
  mixins: [commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  data() {
    return {
      slip: {},
      callback: () => {},
      reason: "",
    };
  },
  computed: {
    dialogProps() {
      const dialogProps = {
        enableResize: false,
        header: "전표삭제",
        width: 560,
        height: 335,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    saveShortcutButtonShortcutString() {
      return toShortcutString(getSaveButtonShortcut());
    },
    slipStatus() {
      return getSlipStatus(this.slip);
    },
    slipVisitorLockerNumber() {
      return this.slip?.visitor?.lockerNumber || "000";
    },
    slipVisitorName() {
      return this.slip?.visitor?.name || "일반고객";
    },
    slipSalesProductNames() {
      if (!this.slip?.sales || this.slip.sales.length === 0) {
        return "";
      } else if (this.slip.sales.length === 1) {
        return this.slip.sales[0].productName;
      } else {
        return `${this.slip.sales[0].productName} 외 ${
          this.slip.sales.length - 1
        }건`;
      }
    },
    slipSalesTotalAmounts() {
      if (!this.slip?.sales || this.slip.sales.length === 0) {
        return 0;
      }

      return this.slip.sales.reduce(
        (acc, { totalAmount = 0 }) => acc + totalAmount,
        0
      );
    },
  },
  methods: {
    show({ slip, callback }) {
      this.slip = slip;
      this.callback = callback;
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },

    onSubmitClick() {
      if (!this.reason) {
        this.errorToast("사유를 입력해주세요.");
        this.$refs["input-reason"].focus();
        return;
      }

      this.callback({ slip: this.slip, reason: this.reason });
      this.hide();
    },
    onCloseClick() {
      this.hide();
    },
  },
};
</script>
