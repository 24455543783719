<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-main">
          <ul class="typePos-list">
            <li>
              <div class="typePos-title">수표종류</div>
              <div class="typePos-content">
                <ul class="ui-check">
                  <li
                    v-for="item in checkInfoOptions.chkTypeOptions"
                    v-bind:key="item.comCode"
                  >
                    <label>
                      <!-- 비활성(Attribute) : disabled -->
                      <input
                        type="radio"
                        :id="'chkType' + item.comCode"
                        v-model="checkInfo.chkType"
                        :value="item.comCode"
                        @change="onChkTypeRadioChange"
                      />
                      <div class="i-item">
                        {{ item.comName }}
                      </div>
                    </label>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div class="typePos-title">권종코드</div>
              <div class="typePos-content">
                <f-select
                  class="ui-select"
                  :items="checkInfoOptions.optChkNoOptions"
                  v-model="checkInfo.optChkNo"
                />
              </div>
            </li>
            <li>
              <div class="typePos-title">수표번호</div>
              <div class="typePos-content">
                <div class="ui-input">
                  <!-- 비활성(Attribute) : disabled -->
                  <input
                    type="text"
                    placeholder="14자리"
                    v-model="checkInfo.txtChkNo"
                  />
                </div>
              </div>
            </li>
            <li>
              <div class="typePos-title">수표금액</div>
              <div class="typePos-content">
                <input-number-unusual
                  v-model="checkInfo.txtChkAmt"
                  :max="99999999"
                />
              </div>
            </li>
            <li>
              <div class="typePos-title">수표발행일</div>
              <div class="typePos-content">
                <input-date-unusual v-model="checkInfo.ChkDate" />
              </div>
            </li>
            <li>
              <div class="typePos-title">계좌번호</div>
              <div class="typePos-content">
                <div class="ui-input">
                  <!-- 비활성(Attribute) : disabled -->
                  <input
                    type="text"
                    placeholder="입력하세요."
                    v-model="checkInfo.txtChkAcc"
                  />
                </div>
              </div>
            </li>
            <li>
              <div class="typePos-title">주민등록번호</div>
              <div class="typePos-content">
                <div class="ui-input">
                  <!-- 비활성(Attribute) : disabled -->
                  <input
                    type="text"
                    placeholder="입력하세요."
                    v-model="checkInfo.txtChkId"
                    :disabled="
                      !(checkInfo.chkType !== '0' && checkInfo.chkType != null)
                    "
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li class="typePos-lookup">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button i-typePrimary"
                :disabled="checkButtonDisabled"
                :is-shortcut-button="true"
                :visible-shortcut-string="false"
                :use-syncfusion-component-style="false"
                @click.native="onCheckButtonClick"
              >
                <div class="i-item">
                  조회 ({{ searchShortcutButtonShortcutString }})
                </div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click.native="onPopupCloseButtonClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {flex: 1;}
.typePos .typePos-wrapper-main .typePos-list {list-style: none; padding: 15px 13px; margin: 0;}
.typePos .typePos-wrapper-main .typePos-list > li {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-list > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-title,
.typePos .typePos-wrapper-main .typePos-list > li .typePos-content {padding: 0 7px;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-title {position: relative; float: left; padding-top: 10px; padding-bottom: 11px; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-title::before {display: none; position: absolute; top: 9px; left: 0; width: 4px; height: 4px; border-radius: 50%; background-color: #df2929; content: '';}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-required .typePos-title::before {display: block;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-content {box-sizing: border-box; width: 314px; float: right;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-content >>> .ejs-number input {width: 100%;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-content >>> .ejs-date {width: 100%;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-lookup .ui-button .i-item::before {background-position-x: calc(-20px * 7);}

.typePos.typePos-confirm .typePos-wrapper-footer .typePos-button > li.typePos-cancel {display: none;}
</style>

<script>
import InputNumberUnusual from "@/components/common/InputNumberUnusual";
import InputDateUnusual from "@/components/common/datetime/InputDateUnusual";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { getTodayDate } from "@/utils/date";
import { validateFormRefs } from "@/utils/formUtil";
import { getStoreBsnMstInfo } from "@/api/bizCodeManagement";
import FSelect from "@/views/store-management/sales-registration/store-pos-renew/components/FSelect";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  getSearchButtonShortcut,
  toShortcutString,
} from "@/utils/KeyboardUtil";
import {getDeviceInfo} from "@/utils/device";
import {openERPPay} from "@/utils/Pay";

export default {
  name: "CheckPopup",
  components: {
    FSelect,
    InputDateUnusual,
    InputNumberUnusual,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.checkInfoOptions.chkTypeOptions = commonCodesGetCommonCode(
      "CHECK_KIND"
    ).map((commonCode) => ({
      ...commonCode,
      name: commonCode.comName,
      value: commonCode.comCode,
    }));
    this.checkInfoOptions.optChkNoOptions = commonCodesGetCommonCode(
      "CHECK_TYPE"
    ).map((commonCode) => ({
      ...commonCode,
      name: commonCode.comName,
      value: commonCode.comCode,
    }));

    const defaultFlagIndex = this.checkInfoOptions.chkTypeOptions.findIndex(
      (obj) => obj.defaultFlag === true
    );

    if (defaultFlagIndex !== -1) {
      this.checkInfo.chkType = this.checkInfoOptions.chkTypeOptions[
        defaultFlagIndex
      ].comCode;
    }

    const optChkNoIndex = this.checkInfoOptions.optChkNoOptions.findIndex(
      (obj) => obj.defaultFlag === true
    );

    if (optChkNoIndex !== -1) {
      this.checkInfo.optChkNo = this.checkInfoOptions.optChkNoOptions[
        optChkNoIndex
      ].comCode;
    }
  },
  data() {
    return {
      result: "",
      vanModule: {},
      checkInfo: {
        chkType: null,
        optChkNo: null,
        txtChkNo: null,
        ChkDate: getTodayDate(),
        txtChkDate: null,
        txtChkAmt: null,
        txtChkAcc: null,
        txtChkId: null,
      },
      commonOptions: {
        commonCodeField: { text: "comName", value: "comCode" },
      },
      checkInfoOptions: {
        chkTypeOptions: null,
        optChkNoOptions: null,
      },
      validateRefList: {
        checkInfoOptChkNo: { required: true },
        checkInfoTxtChkNo: { required: true },
        checkInfoTxtChkAmt: { required: true },
        checkInfoChkDate: { required: true },
      },
      checkButtonDisabled: false,
      van: null,
      bsnDate: null
    };
  },
  computed: {
    searchShortcutButtonShortcutString() {
      return toShortcutString(getSearchButtonShortcut());
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "수표조회",
        width: 425,
        height: 518,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
  },
  methods: {
    validateFormRefs,
    show() {
      this.initialize();
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },

    onDialogClose() {
      this.$emit("close");
    },

    async initialize() {
      try {
        this.bsnDate = getTodayDate();

        ({ value: this.bsnInfo } = await getStoreBsnMstInfo(this.store.code));

        const { vanKind } = this.bsnInfo;

        this.van = vanKind;
      } catch (error) {
        console.error("initialize.Error===>", error);
        return;
      }
    },
    onPopupCloseButtonClick() {
      this.hide();
    },
    onChkTypeRadioChange(args) {
      if (args.target.value === "0") {
        this.checkInfo.txtChkId = null;
      }
    },
    startPay() {
      this.$refs["dialog"].$el.nextElementSibling.style.zIndex = `${
        parseInt(this.$refs["dialog"].$el.nextElementSibling.style.zIndex) + 1
      }`;
    },
    endPay() {
      this.$refs["dialog"].$el.nextElementSibling.style.zIndex = `${
        parseInt(this.$refs["dialog"].$el.nextElementSibling.style.zIndex) - 1
      }`;
    },
    onCheckButtonClick() {
      // // 추후 validate 적용해야함.
      // if (this.validate()) {
      // };

      if (this.checkInfo.chkType == null) {
        this.errorToast("수표종류를 선택해 주시기 바랍니다");
        return;
      }

      if (this.checkInfo.optChkNo == null || this.checkInfo.optChkNo === "00") {
        this.errorToast("권종코드를 선택해 주시기 바랍니다");
        return;
      }

      if (this.checkInfo.ChkDate == null) {
        this.errorToast("수표발행일을 입력해 주시기 바랍니다");
        return;
      }

      if (this.checkInfo.txtChkNo == null) {
        this.errorToast("수표번호 14자리를 입력해 주시기 바랍니다");
        return;
      }

      if (this.checkInfo.txtChkNo.length !== 14) {
        this.errorToast("수표번호 14자리를 입력해 주시기 바랍니다");
        return;
      }

      if (this.checkInfo.txtChkAmt == null) {
        this.errorToast("수표금액을 입력해 주시기 바랍니다");
        return;
      }

      if (this.checkInfo.txtChkAmt === 0) {
        this.errorToast("수표금액은 0보다 큰 값을 입력해 주시기 바랍니다");
        return;
      }

      this.checkButtonDisabled = true;

      const txtChkDate = new Date(this.checkInfo.ChkDate);
      const txtYear = txtChkDate.getFullYear().toString().substr(2);
      const txtMonth = txtChkDate.getMonth() + 1;
      const txtDate = txtChkDate.getDate();

      this.checkInfo.txtChkDate =
        txtYear.toString() +
        (txtMonth.toString().length === 2
          ? txtMonth.toString()
          : "0" + txtMonth.toString()) +
        (txtDate.toString().length === 2
          ? txtDate.toString()
          : "0" + txtDate.toString());

      this.viewCheck();
    },
    async viewCheck() {
      try {
        this.startPay();
        const addTransactionRequest = {
          van: this.van,
          payDivision: "CHECK",
          approvalDivision: "OK",
          posNo: getDeviceInfo().posNo,
          termId: this.bsnInfo.termId,
          checkPubDate: this.checkInfo.txtChkDate,
          checkNo: this.checkInfo.txtChkNo,
          checkAccount: this.checkInfo.txtChkAcc,
          chkType: this.checkInfo.chkType,
          checkType: this.checkInfo.optChkNo,
          chkId: this.checkInfo.txtChkId,
          payDate: this.bsnDate,
          totalAmount: this.checkInfo.txtChkAmt,
          taxAmount: 0,
          vatAmount: 0,
          notaxAmount: 0,
          serviceAmount: 0,
          storeCode: this.store.code,
          businessId: this.bsnInfo.bsnId
        };

        const {message} = await this.payVan(
          addTransactionRequest
        );

        await this.infoToast(message);
      } catch (e) {
        if (e instanceof Error) {
          this.operationMessage = e.message;
          console.error(e);
        } else {
          this.operationMessage = e;
        }

        if (this.operationMessage !== "CONFIRM") {
          await this.errorToast(
            "조회에 실패하였습니다.<br/>" + this.operationMessage
          );
        }
      } finally {
        this.checkButtonDisabled = false;
        this.endPay();
      }
    },
    async payVan(addTransactionRequest) {
      const { tid, status, message } = await openERPPay(addTransactionRequest);

      switch (status) {
        case "APPROVED":
        case "OK":
          return { tid, status, message };
        case "READY":
        case "REQUEST":
          throw new Error("수표조회가 취소되었습니다.");
        case "FAIL":
          throw new Error(message);
      }
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
};
</script>
