<template>
  <div>
    <ejs-dialog
        ref="memoViewPopup"
        v-bind="dialogProps"
        @close="onMemoViewPopupClosed"
    >
      <div
          class="typePos"
          :style="{
          minWidth: `${dialogProps.width - 12}px`,
          minHeight: `${dialogProps.height - 86}px`,
        }"
      >
        <div class="typePos-wrapper-caption">
          <div class="typePos-header">
            <div class="typePos-filed">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="typePos-caption">메모</div>
                </div>
              </div>
            </div>
            <div class="typePos-filed typePos-store">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="typePos-title" v-if="this.memoInfo">
                    [{{ resveCourse }} {{ resveTime }}]
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-title">
            <div class="typePos-filed refresh">
              <erp-button
                button-div="GET"
                class="custom-button"
                :use-syncfusion-component-style="false"
                @click="onClickRefresh"
              />
            </div>
            <div :class="delFlag ? 'typePos-filed hide' : 'typePos-filed show'">
              <erp-button
                button-div="GET"
                class="custom-button"
                :use-syncfusion-component-style="false"
                @click="delFlag = !delFlag"
              />
            </div>
            <div :class="extendActive ? 'typePos-filed reduce' : 'typePos-filed extend'">
              <erp-button
                button-div="GET"
                class="custom-button"
                :use-syncfusion-component-style="false"
                @click="onExtendButtonClicked"
              />
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-main">
          <div class="message-body">
            <div v-for="(data, idx) in filteredMemoViews" :key="`memo_${idx}`">
              <div v-if="data.isTimeLine" class="date">
                <div class="item">
                  {{ getTimeLineFormat(data.date) }}
                </div>
              </div>
              <div
                  v-else-if="isMe(data.sendDept, data.recptnDept, data.insertId)"
                  :class="[`message`, `me`, data.delFlag ? 'disabled' : '']"
              >
                <div class="content">
                  <div class="name">
                    <ul>
                      <li :style="{ color: data.recptnDeptColor }">
                        TO.{{ data.recptnDeptName }}
                      </li>
                      <li>
                        {{ data.insertName }}
                      </li>
                    </ul>
                  </div>
                  <div class="box">
                    <div
                        v-show="data.memoNoConfirmCount > 0"
                        class="item"
                        style="width: 30px; margin: 0 0px 0 0; background-color: #fff; color: #f00; text-align: right;"
                    >
                      {{ data.memoNoConfirmCount }}
                    </div>
                    <div
                        class="item"
                        :style="data.memoNoConfirmCount > 0 ? 'max-width: calc(100% - 35px);' : ''"
                    >
                      <template
                          v-for="(line, index) in data.memoContents.split('\n')"
                      >
                        {{ line }}
                        <br
                            v-if="
                          index !== data.memoContents.split('\n').length - 1
                        "
                            v-bind:key="`memo_${idx}-linebreak-${index}`"
                        />
                      </template>
                    </div>
                    <i></i>
                  </div>
                  <div class="time">
                    {{ getMessageTime(data.insertDt) }}
                  </div>
                  <div class="delete" v-if="!data.delFlag && (userId == data.insertId)">
                    <erp-button
                      button-div="DELETE"
                      @click.native="onMemoDelete(data)"
                    >
                      삭제
                    </erp-button>
                  </div>
                </div>
              </div>
              <div
                  v-else-if="!isMe(data.sendDept, data.recptnDept, data.insertId)"
                  :class="[`message`, { disabled: data.delFlag }]"
              >
                <div
                    class="symbol"
                    :style="{ backgroundColor: data.sendDeptColor }"
                >
                  {{ data.sendDeptNameT }}
                </div>
                <div class="content">
                  <div class="name">
                    <ul>
                      <li>
                        {{ data.insertName }}
                      </li>
                    </ul>
                  </div>
                  <div class="box">
                    <div class="item">
                      <template
                          v-for="(line, index) in data.memoContents.split('\n')"
                      >
                        {{ line }}
                        <br
                            v-if="
                          index !== data.memoContents.split('\n').length - 1
                        "
                            v-bind:key="`memo_${idx}-linebreak-${index}`"
                        />
                      </template>
                    </div>
                    <i></i>
                  </div>
                  <div class="time">
                    {{ getMessageTime(data.insertDt) }}
                  </div>
                  <div class="delete">
                    <erp-button
                      button-div="DELETE"
                    >
                      삭제
                    </erp-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="message-input">
            <span class="memo_check_box">
              <p>메모 입력</p>
              <div>
                <label>
                  <input
                      type="radio"
                      v-model="grpFlag"
                      :checked="grpFlag"
                      :value="false"
                      tabindex="-1"
                      name="memo_check"
                  >
                  팀
                </label>
                <label>
                  <input
                      type="radio"
                      v-model="grpFlag"
                      :checked="grpFlag"
                      :value="true"
                      :disabled="memoInfo && memoInfo.isGrp === false"
                      name="memo_check"
                  >
                  단체
                </label>
                 <ejs-dropdownbutton
                     cssClass="sideRight-memo-add-dropdown"
                     class="drop-button"
                     :disabled="
                     commonMixinIsButtonDisableByAuth('salesMngPosGet')
                     "
                     ref="userMenuDropdownButton"
                     :items="memoAddItems"
                     :select="onMemoAddSelected"
                 >
                   추가
                 </ejs-dropdownbutton>
              </div>
            </span>
            <div class="area-box">
              <input-textarea
                  :height="200"
                  v-model="memoContents"
                  @keypress="onMemoKeypress"
              >
              </input-textarea>
            </div>
          </div>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-caption {padding: 10px; border-bottom: 1px solid #bebebe; }
.typePos .typePos-wrapper-caption::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed,
.typePos .typePos-wrapper-caption .typePos-lookup {float: left;}
.typePos .typePos-wrapper-caption .typePos-filed {padding: 0 3px;}
.typePos .typePos-wrapper-caption .typePos-lookup {padding: 0 10px;}
.typePos .typePos-wrapper-caption .typePos-filed::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content {float: left; padding: 0 7px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title {padding-top: 10px; padding-bottom: 11px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item {float: left; padding-left: 10px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item:first-child {padding-left: 0;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-caption,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content {float: left; padding-top: 10px; padding-bottom: 11px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-caption,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title {padding-right: 4px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-caption {font-size: 18px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content strong {color: #18b3c9; font-weight: normal;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content em {color: #df2929; font-style: normal;}
.typePos .typePos-wrapper-caption .typePos-lookup >>> .ui-button .i-item::before {background-position-x: calc(-20px * 7);}
.typePos .typePos-wrapper-caption .typePos-header {float: left;}
.typePos .typePos-wrapper-caption .typePos-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-store .typePos-filed-content .typePos-item .typePos-title {padding-right: 0;}
.typePos .typePos-wrapper-caption .typePos-footer {float: right;}
.typePos .typePos-wrapper-caption .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed.refresh .custom-button {background-image: url('../../../../../assets/images/common/button-icon-pos-refresh.png'); background-position: center -320px; background-size: 100%; background-repeat: no-repeat;}
.typePos .typePos-wrapper-caption .typePos-filed.hide .custom-button {background-image: url('../../../../../assets/images/layout/pos-sideRight-header-hide-button.png'); background-position: center center; background-size: 100%; background-repeat: no-repeat;}
.typePos .typePos-wrapper-caption .typePos-filed.show .custom-button {background-image: url('../../../../../assets/images/layout/pos-sideRight-header-show-button.png'); background-position: center center; background-size: 100%; background-repeat: no-repeat;}
.typePos .typePos-wrapper-caption .typePos-filed.extend .custom-button {background-image: url('../../../../../assets/images/layout/pos-sideRight-header-extend-button.png'); background-position: center center; background-size: 100%; background-repeat: no-repeat;}
.typePos .typePos-wrapper-caption .typePos-filed.reduce .custom-button {background-image: url('../../../../../assets/images/layout/pos-sideRight-header-reduce-button.png'); background-position: center center; background-size: 100%; background-repeat: no-repeat;}

.custom-button {width: 40px ; height: 40px ; border: 0 ; background-color: #fff }
.right-title { float: right; }

.typePos .typePos-wrapper-main {display: flex; flex-flow: column nowrap; flex: 1; overflow: hidden;}
.typePos .typePos-wrapper-main::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-header {flex: 1; float: left; overflow: hidden;}
.typePos .typePos-wrapper-main .typePos-main-footer {display: flex; flex-direction: column; float: left; width: 325px; border-left: 1px solid #bebebe;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header {padding: 15px 20px; border-bottom: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li {float: left; width: calc((100% / 3) + 1px); margin-left: -1px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li:first-child {width: calc(100% / 3); margin-left: 0; border-radius: 4px 0 0 4px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label {display: block; position: relative;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input {box-shadow: none; position: absolute; top: 0; left: 0; width: 100%; height: 100%; padding: 0; margin: 0; border-radius: 0; opacity: 0; z-index: 10;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input + .i-item {min-height: 19px; padding: 9px 0 10px 0; border: 1px solid #e0e0e0; background-color: #fff; color: #999; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px; text-align: center;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input:hover + .i-item {background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input:checked + .i-item,
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input:checked:hover + .i-item {background-color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li label input:checked + .i-item {color: #fff; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li:first-child  label input + .i-item {border-radius: 4px 0 0 4px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-header .typePos-filed .typePos-check > li:last-child  label input + .i-item {border-radius: 0 4px 4px 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body {flex: 1; position: relative; width: 325px; background-color: #f5f5f5;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list {list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li {position: absolute;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li button {display: block; box-sizing: border-box; box-shadow: 0 2px 2px 0 rgba(0, 0 , 0, 0.05); width: 72px; height: 45px; padding: 5px; margin: 0; border: none; border-radius: 4px; background-color: #fff; color: #666; font-size: 16px; font-family: 'NanumSquare-Bold'; line-height: 24px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li button:hover {background-color: #f9f9f9;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-1 {top: 116px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-2 {top: 116px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-3 {top: 116px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-4 {top: 68px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-5 {top: 68px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-6 {top: 68px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-7 {top: 20px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-8 {top: 20px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-9 {top: 20px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-0 {top: 164px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-00 {top: 164px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-clear {top: 164px; left: 170px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-back {top: 212px; left: 20px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-enter {top: 212px; left: 95px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-input {top: 20px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-lookup {top: 116px; left: 245px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-clear button {font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-back button {overflow: hidden; text-indent: -1000px; background-image: url('../../../../../assets/images/ui/icon-type09.png'); background-repeat: no-repeat; background-position: center center;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-enter button {width: 147px; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-input button {width: 60px; height: 93px; background-color: #4f5963; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-input button:hover {background-color: #333c44;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-lookup button {width: 60px; height: 141px; background-color: #18b3c9; color: #fff; font-size: 14px; line-height: 21px;}
.typePos .typePos-wrapper-main .typePos-main-footer .typePos-body .typePos-list > li.typePos-lookup button:hover {background-color: #0f9eb8;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.drop-button { background-image: url('../../../../../assets/images/common/button-icon-add.png');box-shadow: 0 6px 6px 0 rgb(0 0 0 / 6%); border-color: #ccc; background-position: left center; background-repeat: no-repeat; font-size: 12px; padding-left: 20px;}
.e-input-group, .e-input-group.e-control-wrapper { margin: 0px ;}
.area-box { display: flex ; border-width: 1px 1px 0px 1px ; border-style: solid; border-color: #ccc ; box-sizing: border-box }

/*scoped*/
.e-dropdown-btn .e-caret, .e-dropdown-btn.e-btn .e-caret { display: none; }
.e-input-group textarea.e-input, .e-input-group.e-control-wrapper textarea.e-input { height: 200px; }




.message-body{ width: 100%; height: calc(100% - 280px); box-sizing: border-box; padding: 10px; overflow: auto;}
.message-input{ width: 100%; height: 280px;  padding: 5px 7px 5px 7px; box-sizing: border-box; border-top: 1px solid #ccc; }
.memo_check_box{ display: flex ; flex-flow: row nowrap; align-items: center; justify-content: space-between;}
.memo_check_box > p { color: #000; font-family:'NanumSquare-Regular', Arial, Verdana, sans-serif }
.memo_check_box > div { font-size: 11px ; display: flex; flex-flow: row nowrap; align-items: center;}
.memo_check_box > div > label { margin-right: 7px }
.memo_check_box > div > label > input { vertical-align: -2.5px ; margin: 0; }

.message {display: block;}
@supports (display: flow-root) {.message {display: flow-root;}}
.message:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.message .symbol {float: left; width: 36px; height: 36px; margin: 9px 0 0 0; border-radius: 50%; background-color: #999; color: #fff; line-height: 36px; text-align: center;}
.message .content {box-sizing: border-box; float: left; width: calc(100% - 36px); padding: 6px 0 5px 14px;}
.message .content .name {line-height: 0;}
.message .content .name ul {display: inline-block; list-style: none; padding: 0; margin: 0 0 0 -5px; color: #000; line-height: 1.5;}
.message .content .name ul:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.message .content .name ul li {float: left; padding: 3px 9px 3px 10px; margin: 0 0 -1px 0; background: transparent url('../../../../../assets/images/common/division.png') no-repeat left center;}
.message .content .name ul li:first-child {padding: 3px 9px; background: none;}
.message .content .box {position: relative; width: calc(100% - 15px); margin: 3px 15px 0 0; line-height: 0;}
.message .content .box .item {display: inline-block; box-sizing: border-box; max-width: 100%; min-height: 23px; padding: 3px 9px 2px 9px; border-radius: 3px; background-color: #f9f9f9; line-height: 1.5; text-align: left; word-break: break-all;}
.message .content .box i {display: block; position: absolute; top: 4px; left: -8px; width: 8px; height: 8px; background: transparent url('../../../../../assets/images/layout/sideRight-memo-tail-other.png') no-repeat center center;}
.message .content .time {margin: 6px 0 0 4px;}
.message .content .delete {display: none;}
.message .content .delete .e-btn {display: inline-block; box-shadow: none; padding: 0; border: none; background-color: transparent; color: #f82f2f; font-size: 11px; text-decoration: underline;}
.message .content .delete .e-btn .e-ripple-element {background-color: transparent;}
.message.me .symbol {float: right;}
.message.me .content {float: right; width: 100%; padding: 6px 0 5px 0;}
.message.me .content .name {text-align: right;}
.message.me .content .name ul {margin: 0 -5px 0 0;}
.message.me .content .box {margin: 3px 0 0 15px; text-align: right;}
.message.me .content .box .item {background-color: #ecfaf0; word-break: break-all;}
.message.me .content .box i {display: none;}
.message.me .content .time {margin: 6px 4px 0 0; text-align: right;}
.message.me .content .delete {display: block; text-align: right;}
.message.disabled {opacity: 0.3;}
.date {margin-top: 3px; background: transparent url('../../../../../assets/images/layout/sideRight-memo-date.png') repeat-x center center; line-height: 0; text-align: center;}
.date .item {display: inline-block; padding: 3px 9px 2px 9px; background-color: #fff; line-height: 1.5;}
::-webkit-scrollbar,
::-webkit-scrollbar-corner {
  width: 17px;
  height: 17px;
  background-color: rgba(0, 0, 0, .05);
}
::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  border-radius: 500rem;
  background-color: rgba(0, 0, 0, .25);
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {background-color: rgba(0, 0, 0, .5)}
/*body .appSideRight >>> .sideRight-content .sideRight-memo .memo-write {border-top: 1px solid #e0e0e0;}*/
/*body .appSideRight >>> .sideRight-content .sideRight-memo .memo-write .article-section {height: auto;}*/
/*body .appSideRight >>> .sideRight-content .sideRight-memo .memo-write .article-section .section-body {overflow: visible; border: none;}*/
/*body .appSideRight >>> .sideRight-content .sideRight-memo .memo-write .article-section .body-data .field {width: calc(100% + 1px);}*/
/*body .appSideRight >>> .sideRight-content .sideRight-memo .memo-write .article-section .body-data .field .content .item .e-input-group textarea.e-input {height: 207px;}*/
/*body .appSideRight >>> .sideRight-content .sideRight-memo .memo-write .sideRight-memo-add-dropdown .e-btn-icon {display: none;}*/
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import InputTextarea from "@/components/common/text/InputTextarea";
import {getDayOfWeekCaption} from "@/utils/date";
import {deleteMemo, saveMemo} from "@/api/common";
import {mapGetters, mapState} from 'vuex';
import {mapActions} from "vuex/dist/vuex.esm.browser";
import {
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
} from "@/utils/commonCodes";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import moment from "moment";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "MemoViewPopup",
  components: {InputTextarea, ErpButton},
  mixins: [commonMixin, confirmDialogMixin],
  filters: {},
  data() {
    return {
      grpFlag:false,
      delFlag:true,
      extendActive:false,
      memoContents:"",
      resveCourse: null,
      resveTime: null,
    };
  },
  created() {},
  destroyed() {},
  computed: {
    ...mapGetters(["userId"]),
    ...mapState("memoView", [
      "memoViews",
      "currentView",
      "memoResveId",
      "memoStayId",
      "memoInfo",
    ]),
    dialogProps() {
      return {
        header: "메모 팝업",
        width: 400,
        height: 937,
        allowDragging: true,
        showCloseIcon: true,
        isModal: true,
      };
    },
    memoAddItems() {
      const dataList = commonCodesGetCommonCode("MEMO_DEPT", true).map(data => {
        return {
          text: data.comName,
          id: data.comCode
        };
      });
      return dataList;
    },
    filteredMemoViews() {
      return this.delFlag
          ? this.memoViews
              ?.filter(item => !item.delFlag)
          : this.memoViews;
    },
  },
  watch: {
    memoInfo() {
      if (this.memoInfo && Object.keys(this.memoInfo).length > 0) {
        this.memoContents = null;
        const courseList = commonCodesGetCommonCode("COURSE_CODE");
        this.resveCourse = courseList.find((data) => {
          return data.comCode === this.memoInfo.resveCourse;
        }).comName;
        this.resveTime = moment(this.memoInfo.resveTime, "HHmm").format(
            "HH:mm"
        );
        if (this.memoInfo) {
          this.grpFlag = this.memoInfo.isGrp === true ? true : false;
        }
      }
    },
    currentView(data) {
      if (data) {
        this.memoContents = null;
        this.grpFlag = false;
        this.refreshMemoView();
      }
    },
    filteredMemoViews (){
      this.memoConfirm();
    }
  },
  methods: {
    ...mapActions("memoView", ["refreshMemoView"]),
    async memoConfirm() {
      if (this.filteredMemoViews?.length > 0) {
        const memoIds =
            this.filteredMemoViews
                .filter(item =>
                    !item?.isTimeLine && // 타임라인은 제외.
                    ["%", this.currentView].includes(item?.recptnDept) && // 수신부서가 공통이거나 현재 부서.
                    item?.sendDept !== "ROOM" &&
                    (item?.sendDept !== this.currentView || (item?.sendDept === this.currentView && item?.insertId !== this.userId))) // 전달부서가 현재 부서는 제외.(현재 부서에서 공통으로 메모 입력한 경우를 위해)
                .map(item => item.memoId);
        const memoIdsByStay = this.filteredMemoViews
          .filter(item =>
            !item?.isTimeLine && // 타임라인은 제외.
            ["%", this.currentView].includes(item?.recptnDept) && // 수신부서가 공통이거나 현재 부서.
            item?.sendDept === "ROOM" &&
            (item?.sendDept !== this.currentView ||
              (item?.sendDept === this.currentView && item?.insertId !== this.userId))) // 전달부서가 현재 부서는 제외.(현재 부서에서 공통으로 메모 입력한 경우를 위해)
          .map(item => item.memoId);

        this.currentView === "ROOM" ?
          await GolfErpAPI.reservationMemoConfirmByStayId({
            stayId: this.memoStayId,
            recptnDept: this.currentView,
            request: {
              memoIds: memoIds,
              memoIdsByStay: memoIdsByStay,
            },
          }) :
          await GolfErpAPI.reservationMemoConfirm({
            resveId: this.memoResveId,
            recptnDept: this.currentView,
            request: {
              memoIds: memoIds,
              memoIdsByStay: memoIdsByStay,
            },
          });
        await  this.$emit('memoChange');
      }
    },
    onExtendButtonClicked() {
      this.extendActive = !this.extendActive;
      if(this.extendActive) {
        this.dialogProps.width = 700;
      } else {
        this.dialogProps.width = 400;
      }
    },
    onClickRefresh() {
      this.refreshMemoView();
    },
    onMemoViewPopupClosed() {
      this.$emit("close");
    },
    getTimeLineFormat(d) {
      const date = new Date(d);
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const dt = date.getDate();
      const day = getDayOfWeekCaption(date);
      return `${y}년 ${m}월 ${dt}일 ${day}요일`;
    },
    isMe(sendDept, recptnDept, insertId) {
      if (recptnDept === this.currentView && insertId.toString() !== this.userId.toString()) {
        return false;
      } else if (sendDept === this.currentView) {
        return true;
      }
      return false;
    },
    getMessageTime(d) {
      const date = new Date(d);
      let hour = date.getHours();
      const minute =
          date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
      const ampm = hour > 12 ? "오후" : "오전";
      hour = hour > 12 ? hour - 12 : hour;
      return `${ampm} ${hour}:${minute}`;
    },
    async onMemoDelete(data) {
      this.$EventBus.$emit("pos-confirm", {
        message: "삭제 하시겠습니까?",
        callback: async (callback) => {
          if (callback) {
            await deleteMemo(data.memoId, data.resveId)
                .then(() => {
                  this.refreshMemoView();
                })
                .catch((err) => {
                  console.error("deleteMemo.err.===>", err);
                  this.errorToast(err.response.data.data);
                });
          }
        },
      });
    },
    onMemoKeypress(event) {
      if (event.code === "Enter") {
        if (!event.shiftKey && event.ctrlKey && !event.altKey) {
          this.memoContents = `${this.memoContents}\n`;
        } else if(event.shiftKey) {
          return;
        } else {
          event.preventDefault();
          if (!this.memoContents?.trim()) {
            return;
          }

          this.$refs["userMenuDropdownButton"].toggle();
        }
      }
    },

    async onMemoAddSelected(args) {

      if (!this.memoContents || this.memoContents.trim() === "") {
        this.errorToast("메모를 입력바랍니다");
        return;
      }
      if ((this.memoResveId || this.memoStayId) && this.currentView) {
        const attrbIdx1 = commonCodesGetCommonCodeAttrbByCodeAndIdx('MEMO_DEPT', args.item.id, 1);
        const attrbIdx2 = commonCodesGetCommonCodeAttrbByCodeAndIdx('MEMO_DEPT', args.item.id, 2);
        if (attrbIdx1 && attrbIdx2) {
          await this.sendMemo(attrbIdx1);
          await this.sendMemo(attrbIdx2);
        } else {
          await this.sendMemo(args.item.id);
        }
      }
    },
    async sendMemo(id) {
      const body = {
        grpFlag: this.grpFlag,
        memoContents: this.memoContents,
        recptnDept: id,
        sendDept: this.currentView,
        resveId: this.memoResveId,
        stayId: this.memoStayId,
      };
      await saveMemo(body)
          .then(() => {
            this.refreshMemoView();
          })
          .catch((err) => {
            console.error("saveMemo.err.===>", err);
            // this.errorToast(err.response.data.data);
          });
    },
  }
};
</script>