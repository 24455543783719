<template>
  <div>
    <ejs-dialog ref="dialog" v-bind="dialogProps" @close="onDialogClose">
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-main">
          <ul class="typePos-list">
            <li class="typePos-required">
              <div class="typePos-title">갤러리명</div>
              <div class="typePos-content">
                <div class="ui-input">
                  <!-- 비활성(Attribute) : disabled -->
                  <input
                    type="text"
                    placeholder="입력하세요."
                    v-model="galleryName"
                  />
                </div>
              </div>
            </li>
            <li class="typePos-required">
              <div class="typePos-title">부구분</div>
              <div class="typePos-content">
                <f-select
                  class="ui-select"
                  :items="partDivisions"
                  v-model="partDiv"
                />
              </div>
            </li>
            <li class="typePos-groupName">
              <div class="typePos-title">단체명</div>
              <div class="typePos-content">
                <div class="typePos-item typePos-name">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input
                      type="text"
                      placeholder="입력하세요."
                      v-model="grpName"
                      @keydown.tab="onGroupNameKeypressEnter"
                      @keypress.enter="onGroupNameKeypressEnter"
                    />
                    <!-- 비활성(Attribute) : disabled -->
                    <erp-button
                      button-div="GET"
                      :use-syncfusion-component-style="false"
                      @click="onSearchGroupsClick"
                    >
                      <div class="i-item">찾기</div>
                    </erp-button>
                  </div>
                </div>
                <div class="typePos-item typePos-code">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input type="text" disabled :value="grpNo" />
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                :use-syncfusion-component-style="false"
                class="ui-button i-typePrimary i-iconConfirm"
                @click="onSubmitClick"
              >
                <div class="i-item">확인</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <!-- 골프 단체 검색 팝업 -->
    <search-groups-popup
      ref="search-groups-popup"
      v-if="visibleSearchGroupsPopup"
      @close="onSearchGroupsPopupClose"
    />
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {flex: 1;}
.typePos .typePos-wrapper-main .typePos-list {list-style: none; padding: 15px 13px; margin: 0;}
.typePos .typePos-wrapper-main .typePos-list > li {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-list > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-title,
.typePos .typePos-wrapper-main .typePos-list > li .typePos-content {padding: 0 7px;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-title {position: relative; float: left; padding-top: 10px; padding-bottom: 11px; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-title::before {display: none; position: absolute; top: 9px; left: 0; width: 4px; height: 4px; border-radius: 50%; background-color: #df2929; content: '';}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-required .typePos-title::before {display: block;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-content {box-sizing: border-box; width: 314px; float: right;}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName .typePos-content .typePos-item {box-sizing: border-box; float: left; padding-left: 10px;}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName .typePos-content .typePos-item:first-child {padding-left: 0;}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName .typePos-content .typePos-item.typePos-name {width: 180px;}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName .typePos-content .typePos-item.typePos-name .ui-input input {border-right-color: transparent; border-top-right-radius: 0; border-bottom-right-radius: 0;}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName .typePos-content .typePos-item.typePos-name .ui-input input:focus {border-color: #18b3c9;}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName .typePos-content .typePos-item.typePos-name .ui-input button {display: block; box-sizing: border-box; width: 40px; height: 40px; margin: 0; border: 1px solid #18b3c9; border-radius: 0 4px 4px 0; background-color: #18b3c9; line-height: 0; text-align: center;}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName .typePos-content .typePos-item.typePos-name .ui-input button:hover {border-color: #0f9eb8; background-color: #0f9eb8;}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName .typePos-content .typePos-item.typePos-name .ui-input button:disabled {border-color: #e0e0e0; background-color: #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName .typePos-content .typePos-item.typePos-name .ui-input button:disabled:hover {border-color: #e0e0e0; background-color: #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName .typePos-content .typePos-item.typePos-name .ui-input button .i-item {display: inline-block; overflow: hidden; position: relative; width: 100%; height: 100%; line-height: 0; text-indent: -1000px;}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName .typePos-content .typePos-item.typePos-name .ui-input button .i-item::before {display: block; position: absolute; top: 50%; left: 50%; width: 13px; height: 13px; margin-top: -7px; margin-left: -6px; background: transparent url('../../../../../assets/images/ui/icon-type02.png') no-repeat calc(-20px * 1) center; content: '';}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName .typePos-content .typePos-item.typePos-name .ui-input button:disabled .i-item {color: #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-groupName .typePos-content .typePos-item.typePos-code {width: calc(100% - 180px);}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
</style>

<script>
import moment from "moment";
import SearchGroupsPopup from "@/views/store-management/sales-registration/store-pos-renew/popups/SearchGroupsPopup";
import { saveGallery } from "@/api/frontManagement";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import { orderBy as _orderBy } from "lodash";
import FSelect from "@/views/store-management/sales-registration/store-pos-renew/components/FSelect";
import { TIME_FORMAT_hh_mm, TIME_FORMAT_hh_mm_ss } from "@/utils/time";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {getGroupList} from "@/api/group";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "CreateGalleryPopup",
  components: { FSelect, SearchGroupsPopup, ErpButton, },
  mixins: [commonMixin, confirmDialogMixin],
  props: {
    salesDate: {
      type: Date,
      required: true,
    },
  },
  async mounted() {
    this.partDiv = await this.getDefaultPartDivision();
  },
  data() {
    return {
      galleryName: "",
      partDiv: null,

      grpNo: "",
      grpKind: "",
      grpName: "",

      visibleSearchGroupsPopup: false,
    };
  },
  computed: {
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "갤러리 생성",
        width: 425,
        height: 318,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    partDivisions() {
      return _orderBy(
        commonCodesGetCommonCode("PART_DIV").map((commonCode) => ({
          ...commonCode,
          name: commonCode.comName,
          value: commonCode.comCode,
        })),
        "sortNo"
      );
    },
  },
  methods: {
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },

    async getDefaultPartDivision() {
      const nowTime = moment(await this.fetchNow()).format(TIME_FORMAT_hh_mm);

      const targetPartDivision = this.partDivisions.find(({ jsonData }) => {
        const attributes = (JSON.parse(jsonData) || []).map(
          ({ ATTRB }) => ATTRB
        );

        if (attributes.length !== 2) {
          return false;
        }

        return attributes[0] <= nowTime && nowTime <= attributes[1];
      });

      return targetPartDivision
        ? targetPartDivision.comCode
        : commonCodeGetComCodeDefaultValue("PART_DIV");
    },

    onDialogClose() {
      this.$emit("close");
    },

    onGroupNameBlur() {
      if (!this.grpName) {
        return;
      }

      this.onSearchGroupsClick();
    },
    onGroupNameKeypressEnter() {
      this.onSearchGroupsClick();
    },
    async onSearchGroupsClick() {
      const response = await getGroupList({ searchValue: this.grpName });
      const groupList = response.value.groupInfoList;
      let groupName = JSON.parse(JSON.stringify(this.grpName));
      let grpKind = null;
      let tempGrpName = null;
      if (groupList && groupList.length === 0) {
        grpKind = "TEMP";
        tempGrpName = this.grpName;
        groupName = null;
      }
      this.visibleSearchGroupsPopup = true;
      this.$nextTick(() => {
        this.$refs["search-groups-popup"].show({
          q: groupName,
          bsnDate: this.salesDate,
          grpKind: grpKind,
          tempGrpName: tempGrpName,
          callback: ({ number, kind, name }) => {
            this.grpNo = number;
            this.grpKind = kind;
            this.grpName = name;
          },
        });
      });
    },
    async onSubmitClick() {
      if (!this.galleryName || this.galleryName.trim() === "") {
        this.errorToast("갤러리명을 입력해 주십시오");
        this.$refs.galleryName.focusIn();
        return;
      }

      await this.createGallery();
      this.infoToast(this.$t("main.popupMessage.saved"));
      this.hide();
    },
    onCloseClick() {
      this.hide();
    },
    onSearchGroupsPopupClose(isSubmit) {
      this.visibleSearchGroupsPopup = false;

      if (!isSubmit) {
        if (!this.grpNo) {
          this.grpNo = null;
          this.grpKind = "FIT";
          this.grpName = null;
        }
      }
    },

    async fetchNow() {
      return moment(
        await GolfErpAPI.fetchNow(),
        `${DATE_FORMAT_YYYY_MM_DD} ${TIME_FORMAT_hh_mm_ss}`
      ).toDate();
    },
    async createGallery() {
      const data = {
        galleryInfo: {
          galleryName: this.galleryName, // 갤러리명
          partDiv: this.partDiv, // 부구분
        },
      };
      if (this.grpNo) {
        data.galleryInfo.grpNo = this.grpNo; // 단체번호
        data.galleryInfo.grpKind = this.grpKind; // 단체종류
        data.galleryInfo.grpName = this.grpName; // 단체명
      }

      await saveGallery(
        moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
        data
      );
    },
  },
};
</script>
