<template>
  <div>
    <ejs-dialog ref="dialog" v-bind="dialogProps" @close="onDialogClose">
      <!-- 현금영수증 취소 활성(Class) : typePos-cancel -->
      <div
        class="typePos"
        :class="{ 'typePos-cancel': visibleCancelReceiptForm }"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-box typePos-issued">
          <div class="typePos-wrapper-header">
            <div class="typePos-title">현금영수증 <strong>발급</strong></div>
            <div class="typePos-button" @click="onShowCancelReceiptFormClick">
              <i></i>
            </div>
          </div>
          <div class="typePos-wrapper-aside">
            <ul class="typePos-list">
              <li>
                <div class="typePos-title">영업일자</div>
                <div class="typePos-content">
                  <input-date-unusual
                    format="YYYY-MM-DD"
                    :disabled="true"
                    :value="readOnlyStringSalesDate"
                  />
                </div>
              </li>
              <li>
                <div class="typePos-title">영업장</div>
                <div class="typePos-content">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input
                      type="text"
                      placeholder="입력하세요."
                      disabled
                      :value="storeBusinessName"
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="typePos-title">매장명</div>
                <div class="typePos-content">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input
                      type="text"
                      placeholder="입력하세요."
                      disabled
                      :value="storeName"
                    />
                  </div>
                </div>
              </li>
              <li class="typePos-required">
                <div class="typePos-title">증명종류</div>
                <div class="typePos-content">
                  <f-select
                    class="ui-select"
                    :items="proofKindCodes"
                    v-model="approvalInfo.proofKind"
                  />
                </div>
              </li>
              <li>
                <div class="typePos-title">증빙번호</div>
                <div class="typePos-content">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input
                      type="text"
                      placeholder="입력하세요."
                      v-model="approvalInfo.proofNo"
                      :disabled="
                        approvalInfo.proofKind === 'VOL' ||
                        approvalInfo.cardProof
                      "
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="typePos-title">카드증빙</div>
                <div class="typePos-content">
                  <ul class="ui-check">
                    <li>
                      <label>
                        <!-- 비활성(Attribute) : disabled -->
                        <input
                          type="checkbox"
                          v-model="approvalInfo.cardProof"
                          :disabled="approvalInfo.proofKind === 'VOL'"
                        />
                        <div class="i-item">사용</div>
                      </label>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="typePos-required">
                <div class="typePos-title">승인금액</div>
                <div class="typePos-content">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input-number-unusual
                      ref="approvalInfoPayAmt"
                      type="text"
                      placeholder="입력하세요."
                      v-model="approvalInfo.payAmt"
                      :propMaxLength="8"
                      @keydown.enter.native="onApprovalButtonClick"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="typePos-wrapper-footer">
            <ul class="typePos-button">
              <li>
                <!-- 비활성(Attribute) : disabled -->
                <erp-button
                  button-div="SAVE"
                  class="ui-button i-typePrimary i-iconConfirm"
                  :use-syncfusion-component-style="false"
                  @click.native="onApprovalButtonClick"
                >
                  <div class="i-item">확인</div>
                </erp-button>
              </li>
              <li>
                <!-- 비활성(Attribute) : disabled -->
                <erp-button
                  button-div="CLOSE"
                  class="ui-button i-iconClose"
                  :use-syncfusion-component-style="false"
                  @click.native="onCloseClick"
                >
                  <div class="i-item">닫기</div>
                </erp-button>
              </li>
            </ul>
          </div>
        </div>
        <div class="typePos-wrapper-box typePos-cancel">
          <div class="typePos-wrapper-header">
            <div class="typePos-title">현금영수증 <em>취소</em></div>
            <div class="typePos-button" @click="onHideCancelReceiptFormClick">
              <i></i>
            </div>
          </div>
          <div class="typePos-wrapper-caption">
            <div class="typePos-header">
              <div class="typePos-filed">
                <div class="typePos-filed-title">영업일자</div>
                <div class="typePos-filed-content">
                  <div class="typePos-item">
                    <input-date-range-unusual
                      v-model="searchPayDateRange"
                      @keydown.enter.native="fetchTgPayCashList"
                    />
                  </div>
                </div>
              </div>
              <div class="typePos-hr"></div>
              <div class="typePos-filed typePos-proofNumber">
                <div class="typePos-filed-title">증빙번호</div>
                <div class="typePos-filed-content">
                  <div class="typePos-item">
                    <div class="ui-input">
                      <!-- 비활성(Attribute) : disabled -->
                      <input
                        type="text"
                        placeholder="입력하세요."
                        v-model="cancelSearchOption.proofNo"
                        @keydown.enter="fetchTgPayCashList"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="typePos-filed typePos-approvalNumber">
                <div class="typePos-filed-title">승인번호</div>
                <div class="typePos-filed-content">
                  <div class="typePos-item">
                    <div class="ui-input">
                      <!-- 비활성(Attribute) : disabled -->
                      <input
                        type="text"
                        placeholder="입력하세요."
                        v-model="cancelSearchOption.approvalNo"
                        @keydown.enter="fetchTgPayCashList"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="typePos-footer">
              <div class="typePos-lookup">
                <!-- 비활성(Attribute) : disabled -->
                <erp-button
                  button-div="GET"
                  class="ui-button i-typePrimary"
                  :is-shortcut-button="true"
                  :visible-shortcut-string="false"
                  :use-syncfusion-component-style="false"
                  @click.native="onSearchClick"
                >
                  <div class="i-item">
                    조회<br />({{ searchShortcutButtonShortcutString }})
                  </div>
                </erp-button>
              </div>
            </div>
          </div>
          <div class="typePos-wrapper-main">
            <ejs-grid-wrapper
              ref="cashReceiptGrid"
              v-bind="cashReceiptGridOptions"
              @rowSelected="onGridRowSelected"
              @keydown.enter="onCashReceiptCancelButtonClicked"
            />
          </div>
          <div class="typePos-wrapper-aside">
            <ul class="typePos-list">
              <li>
                <div class="typePos-title">취소사유</div>
                <div class="typePos-content">
                  <f-select
                    class="ui-select"
                    :items="proofCancelCodes"
                    v-model="cancelInfo.payCashProofCancelCode"
                  />
                </div>
              </li>
              <li>
                <div class="typePos-title">증빙번호</div>
                <div class="typePos-content">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input
                      type="text"
                      placeholder="입력하세요."
                      v-model="cancelInfo.proofNo"
                      :disabled="
                        cancelInfo.proofKind === 'VOL' || cancelInfo.cardProof
                      "
                    />
                  </div>
                </div>
              </li>
              <li>
                <div class="typePos-title">카드증빙</div>
                <div class="typePos-content">
                  <ul class="ui-check">
                    <li>
                      <label>
                        <!-- 비활성(Attribute) : disabled -->
                        <input
                          type="checkbox"
                          v-model="cancelInfo.cardProof"
                          :disabled="cancelInfo.proofKind === 'VOL'"
                        />
                        <div class="i-item">사용</div>
                      </label>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div class="typePos-wrapper-footer">
            <ul class="typePos-button">
              <li class="typePos-cancel">
                <!-- 비활성(Attribute) : disabled -->
                <erp-button
                  button-div="SAVE"
                  class="ui-button i-typePrimary"
                  :use-syncfusion-component-style="false"
                  @click.native="onCashReceiptCancelButtonClicked"
                >
                  <div class="i-item">현금영수증 취소</div>
                </erp-button>
              </li>
              <li>
                <!-- 비활성(Attribute) : disabled -->
                <erp-button
                  button-div="CLOSE"
                  class="ui-button i-iconClose"
                  :use-syncfusion-component-style="false"
                  @click.native="onCloseClick"
                >
                  <div class="i-item">닫기</div>
                </erp-button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-box {display: none; flex-direction: column; flex: 1;}

.typePos .typePos-wrapper-box.typePos-issued {display: flex;}

.typePos .typePos-wrapper-header {position: relative; padding: 16px 0 15px 0; border-bottom: 1px solid #bebebe; background-color: #f9f9f9;}
.typePos .typePos-wrapper-header .typePos-title {color: #222; font-size: 16px; font-family: 'NanumSquare-Bold'; line-height: 24px; text-align: center;}
.typePos .typePos-wrapper-header .typePos-title strong {color: #18b3c9; font-weight: normal;}
.typePos .typePos-wrapper-header .typePos-title em {color: #df2929; font-style: normal;}
.typePos .typePos-wrapper-header .typePos-button {position: absolute; top: 0; right: 0; width: 48px; height: 55px; cursor: pointer;}
.typePos .typePos-wrapper-header .typePos-button i {display: block; position: absolute; top: 50%; left: 50%; width: 8px; height: 15px; margin: -8px 0 0 -4px; background: transparent url('../../../../../assets/images/ui/arrow-type09.png') no-repeat left top;}
.typePos .typePos-wrapper-header .typePos-button:hover i {background-position-y: bottom;}

.typePos .typePos-wrapper-caption {padding: 10px; border-bottom: 1px solid #bebebe;}
.typePos .typePos-wrapper-caption::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed,
.typePos .typePos-wrapper-caption .typePos-lookup {float: left;}
.typePos .typePos-wrapper-caption .typePos-filed {padding: 0 3px;}
.typePos .typePos-wrapper-caption .typePos-lookup {padding: 0 10px;}
.typePos .typePos-wrapper-caption .typePos-filed::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content {float: left; padding: 0 7px; color: #666; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title {padding-top: 10px; padding-bottom: 11px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item {float: left; padding-left: 10px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item:first-child {padding-left: 0;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content {float: left; padding-top: 10px; padding-bottom: 11px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title {padding-right: 4px; color: #222; font-family: 'NanumSquare-Bold';}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content strong {color: #18b3c9; font-weight: normal;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content em {color: #df2929; font-style: normal;}
.typePos .typePos-wrapper-caption .typePos-lookup >>> .ui-button {height: 90px;}
.typePos .typePos-wrapper-caption .typePos-lookup >>> .ui-button .i-item::before {background-position-x: calc(-20px * 7);}
.typePos .typePos-wrapper-caption .typePos-header {float: left; width: calc(100% - 107px);}
.typePos .typePos-wrapper-caption .typePos-header::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-caption .typePos-header .typePos-hr {display: block; clear: both; height: 10px;}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-proofNumber .typePos-filed-content .typePos-item .ui-input {width: 180px;}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-approvalNumber .typePos-filed-content .typePos-item .ui-input {width: 180px;}
.typePos .typePos-wrapper-caption .typePos-footer {float: right; width: 107px;}
.typePos .typePos-wrapper-caption .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}

.typePos .typePos-wrapper-main {flex: 1; overflow: hidden;}

.typePos .typePos-wrapper-aside {flex: 1; overflow: auto;}
.typePos .typePos-wrapper-aside .typePos-list {list-style: none; padding: 15px 13px; margin: 0;}
.typePos .typePos-wrapper-aside .typePos-list > li {padding: 5px 0;}
.typePos .typePos-wrapper-aside .typePos-list > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-aside .typePos-list > li .typePos-title,
.typePos .typePos-wrapper-aside .typePos-list > li .typePos-content {padding: 0 7px;}
.typePos .typePos-wrapper-aside .typePos-list > li .typePos-title {position: relative; float: left; padding-top: 10px; padding-bottom: 11px; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-aside .typePos-list > li .typePos-title::before {display: none; position: absolute; top: 9px; left: 0; width: 4px; height: 4px; border-radius: 50%; background-color: #df2929; content: '';}
.typePos .typePos-wrapper-aside .typePos-list > li.typePos-required .typePos-title::before {display: block;}
.typePos .typePos-wrapper-aside .typePos-list > li .typePos-content {box-sizing: border-box; width: 649px; float: right;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-cancel .ui-button .i-item::before {background-position-x: calc(-20px * 9);}

.typePos.typePos-cancel .typePos-wrapper-box.typePos-issued {display: none;}
.typePos.typePos-cancel .typePos-wrapper-box.typePos-cancel {display: flex;}
.typePos.typePos-cancel .typePos-wrapper-header .typePos-button {left: 0; right: auto;}
.typePos.typePos-cancel .typePos-wrapper-header .typePos-button i {background-position-x: right;}
.typePos.typePos-cancel .typePos-wrapper-aside {flex: none; border-top: 1px solid #bebebe;}
</style>

<script>
import moment from "moment";
import InputDateUnusual from "@/components/common/datetime/InputDateUnusual";
import InputDateRangeUnusual from "@/components/common/datetime/InputDateRangeUnusual";
import {
  DATE_FORMAT_YYYY_MM_DD,
  getFormattedDate,
  getTodayNavigationDate,
} from "@/utils/date";
import { getStoreBsnMstInfo } from "@/api/bizCodeManagement";
import {commonCodesGetCommonCode, commonCodesGetComName} from "@/utils/commonCodes";
import InputNumberUnusual from "@/components/common/InputNumberUnusual";
import GolfERPService from "@/service/GolfERPService";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { getDeviceInfo } from "@/utils/device";
import { openERPPay } from "@/utils/Pay";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import FSelect from "@/views/store-management/sales-registration/store-pos-renew/components/FSelect";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  getSearchButtonShortcut,
  toShortcutString,
} from "@/utils/KeyboardUtil";
import { mapGetters } from "vuex";
import {getFormattedTelNumber} from "@/utils/string";
import BillPrintUtil from "@/utils/billPrintUtil";
import {getReceiptInfo} from "@/api/common";

export default {
  name: "CashReceiptPopup",
  components: {
    FSelect,
    EjsGridWrapper,
    InputNumberUnusual,
    InputDateRangeUnusual,
    InputDateUnusual,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  props: {
    salesDate: {
      type: Date,
      required: true,
    },
    store: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.fetchStoreBusinessInfo();
    getTodayNavigationDate(DATE_FORMAT_YYYY_MM_DD)
      .then((value) => {
        this.today = value;
        this.cancelSearchOption.payDateFrom = value;
        this.cancelSearchOption.payDateTo = value;
        this.fetchTgPayCashList();
      })
      .catch((reason) => {
        console.error("getTodayNavigationDate ERROR ====> ", reason);
      });
  },
  data() {
    return {
      receiptInfo: {},
      printFlag: getDeviceInfo().isBillPrinterUse,
      operationMessage: null,
      payProcessing: false,
      bsnInfo: {},
      cashReceiptList: [],

      approvalInfo: {
        proofKind: "VOL",
        payAmt: 0,
        proofNo: null,
        cardProof: false,
        salesDiv: "SALES", // 해당 팝업에서는 'SALES' 고정
      },
      van: null,
      cancelSearchOption: {
        payDateFrom: getFormattedDate(new Date()),
        payDateTo: getFormattedDate(new Date()),
        approvalNo: null,
        proofNo: null,
      },
      cancelInfo: {
        payCashProofCancelCode: "1",
        proofNo: null,
        cardProof: false,
      },

      visibleCancelReceiptForm: false,
    };
  },
  computed: {
    ...mapGetters("pos", ["visibleConfirmDialog"]),
    searchShortcutButtonShortcutString() {
      return toShortcutString(getSearchButtonShortcut());
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "현금영수증 발급/취소",
        width: 760,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    readOnlyStringSalesDate() {
      return moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD);
    },
    storeBusinessName() {
      return this.bsnInfo?.bsnName;
    },
    storeName() {
      return (
        commonCodesGetCommonCode("STORE_CODE").find(
          ({ comCode }) => comCode === this.store.code
        )?.comName || ""
      );
    },
    proofKindCodes() {
      return commonCodesGetCommonCode("PROOF_KIND").map((commonCode) => ({
        name: commonCode.comName,
        value: commonCode.comCode,
      }));
    },
    proofCancelCodes() {
      return commonCodesGetCommonCode("PROOF_CANCEL_CODE").map(
        (commonCode) => ({
          name: commonCode.comName,
          value: commonCode.comCode,
        })
      );
    },
    approvalTaxInfo() {
      return this.approvalInfo.payAmt
        ? {
            payAmt: this.approvalInfo.payAmt,
            taxAmt:
              this.approvalInfo.payAmt -
              Math.round((this.approvalInfo.payAmt / 1.1) * 0.1),
            vatAmt: Math.round((this.approvalInfo.payAmt / 1.1) * 0.1),
            notaxAmt: 0,
            svcAmt: 0,
          }
        : {
            payAmt: 0,
            taxAmt: 0,
            vatAmt: 0,
            notaxAmt: 0,
            svcAmt: 0,
          };
    },
    searchPayDateRange: {
      get() {
        return {
          from: this.cancelSearchOption.payDateFrom,
          to: this.cancelSearchOption.payDateTo,
        };
      },
      set(searchPayDateRange) {
        this.cancelSearchOption.payDateFrom = searchPayDateRange.from;
        this.cancelSearchOption.payDateTo = searchPayDateRange.to;
      },
    },
    cashReceiptGridOptions() {
      return {
        columns: [
          {
            field: "id",
            allowEditing: false,
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "businessName",
            headerText: "영업장",
            allowEditing: false,
            type: "string",
            textAlign: "left",
            minWidth: 16,
            width: 80,
          },
          {
            field: "payDate",
            headerText: "승인일자",
            allowEditing: false,
            type: "string",
            textAlign: "left",
            minWidth: 16,
            width: 90,
          },
          {
            field: "proofNo",
            headerText: "증빙번호",
            allowEditing: false,
            type: "string",
            textAlign: "left",
            minWidth: 16,
            width: 90,
          },
          {
            field: "approvalNo",
            headerText: "승인번호",
            allowEditing: false,
            type: "string",
            textAlign: "left",
            minWidth: 16,
            width: 90,
          },
          {
            field: "proofKind",
            headerText: "증빙종류",
            allowEditing: false,
            minWidth: 16,
            width: 90,
            isCommonCodeField: true,
            groupCode: "PROOF_KIND",
            type: "string",
          },
          {
            field: "approvalAmount",
            headerText: "거래금액",
            allowEditing: false,
            type: "string",
            textAlign: "right",
            isNumericType: true,
            minWidth: 16,
            width: 90,
          },
          {
            field: "approvalDateTime",
            headerText: "거래일시",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 150,
            textAlign: "left",
          },
          {
            field: "userName",
            headerText: "담당자",
            allowEditing: false,
            type: "string",
            textAlign: "left",
            minWidth: 16,
            width: 90,
          },
        ],
        dataSource: this.cashReceiptList,
        isInPopup: true,
        provides: [Resize, ForeignKey],
        selectionSettings: { checkboxOnly: false, type: "Single", mode: "Row" },
        useCommonStyles: false,
      };
    },
  },
  methods: {
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },

    getCashReceiptApprovalTransactionRequest() {
      return {
        van: this.bsnInfo.vanKind,
        payDivision: "CASH",
        approvalDivision: "OK",
        totalAmount: this.approvalTaxInfo.payAmt,
        taxAmount: this.approvalTaxInfo.taxAmt,
        vatAmount: this.approvalTaxInfo.vatAmt,
        notaxAmount: this.approvalTaxInfo.notaxAmt,
        serviceAmount: this.approvalTaxInfo.svcAmt,
        cardProof: this.approvalInfo.cardProof,
        proofKind: this.approvalInfo.proofKind,
        proofNo: this.approvalInfo.proofNo,
        storeCode: this.store.code,
        businessId: this.bsnInfo.bsnId,
        posNo: getDeviceInfo().posNo,
        termId: this.bsnInfo.termId,
      };
    },
    getCancelTransactionRequestByApprovedPayTransaction(
      approvedPayTransaction
    ) {
      return {
        van: approvedPayTransaction.van,
        payDivision: approvedPayTransaction.payDivision,
        approvalDivision: "CANCEL",
        totalAmount: approvedPayTransaction.totalAmount,
        taxAmount: approvedPayTransaction.taxAmount,
        vatAmount: approvedPayTransaction.vatAmount,
        notaxAmount: approvedPayTransaction.notaxAmount,
        serviceAmount: approvedPayTransaction.serviceAmount,
        cardProof: this.approvalInfo.cardProof,
        proofKind: approvedPayTransaction.proofKind,
        proofNo: this.approvalInfo.proofNo,
        divideTerm: approvedPayTransaction.divideTerm,
        approvalNo: approvedPayTransaction.approvalNo,
        approvalDateTime: approvedPayTransaction.approvalDateTime,
        storeCode: this.storeCode,
        businessId: approvedPayTransaction.businessId,
        posNo: getDeviceInfo().posNo,
        termId: approvedPayTransaction.termId,
      };
    },
    getCashReceiptCancelTransactionRequest(cashReceipt) {
      return {
        van: this.van,
        payDivision: "CASH",
        approvalDivision: "CANCEL",
        totalAmount: cashReceipt.approvalAmount,
        taxAmount: cashReceipt.taxAmount,
        vatAmount: cashReceipt.vatAmount,
        notaxAmount: cashReceipt.notaxAmount,
        serviceAmount: cashReceipt.serviceAmount,
        cardProof: this.cancelInfo.cardProof,
        proofKind: cashReceipt.proofKind,
        proofNo: this.cancelInfo.proofNo,
        proofCancelCode: this.cancelInfo.payCashProofCancelCode,
        approvalNo: cashReceipt.approvalNo,
        approvalDateTime: cashReceipt.approvalDateTime,
        storeCode: this.storeCode,
        businessId: cashReceipt.businessId,
        posNo: getDeviceInfo().posNo,
        termId: cashReceipt.termId,
      };
    },

    onShowCancelReceiptFormClick() {
      this.visibleCancelReceiptForm = true;
    },
    onHideCancelReceiptFormClick() {
      this.visibleCancelReceiptForm = false;
    },
    onSearchClick() {
      this.fetchTgPayCashList();
    },
    onGridRowSelected(args) {
      this.cancelInfo.cardProof = args.data.cardProof;
    },
    confirm({ message, callback }) {
      this.$EventBus.$emit("pos-confirm", {
        message,
        callback
      });
    },
    async onCashReceiptCancelButtonClicked() {
      try {
        const selectedRows = this.$refs.cashReceiptGrid.getSelectedRecords();
        if (selectedRows.length === 0) {
          this.errorToast("취소할 현금현금 영수증을 선택해 주세요");
          return;
        }

        this.$refs.cancelButton.blur();

        const row = selectedRows[0];
        const cashReceipt = this.cashReceiptList.find(
          (value) => value.id === row.id
        );

        // 디밍 처리
        this.startPay();

        // if (!(await this.confirm("현금 영수증을 취소하시겠습니까?"))) {
        //   return;
        // }

        this.confirm({
          message: "현금 영수증을 취소하시겠습니까?",
          callback: async (confirmed) => {
            if (!confirmed) {
              return;
            }

            try {
              const payDiv = "CASH";

              const payTransactionRequest = this.createPayTransactionRequestByPayCashInfo(
                cashReceipt
              );
              const {tid, status, message} = await this.payVan(payTransactionRequest);

              if (status === "SELFCANCEL") {
                this.operationMessage = message;
                this.errorToast(message);
                return;
              }

              const cancelCashId = await GolfERPService.patchPayCashReceipt(
                cashReceipt.id,
                tid
              );

              if (status === "ALREADY_CANCELED") {
                this.infoToast(this.$t("vanModule.popupMessage.alreadyCanceled"));
              } else {
                this.infoToast(this.$t("vanModule.popupMessage.cancelSuccess"));
                // this.popupData.isPayCash = true;
                // this.print(cancelCashId);
              }

              await this.paySuccess(cancelCashId, this.approvalInfo.salesDiv, payDiv);
            } catch (e) {
              if (e instanceof Error) {
                this.errorToast(
                  this.$t("vanModule.popupMessage.approveCancelFail") +
                  "<br>" +
                  e.message
                );
                this.operationMessage = e.message;
              } else {
                this.errorToast(
                  this.$t("vanModule.popupMessage.approveCancelFail") + "<br>" + e
                );
                this.operationMessage = e;
              }
            } finally {
              this.endPay();
            }
          }
        });
      } catch (e) {
        if (e instanceof Error) {
          this.errorToast(
            this.$t("vanModule.popupMessage.approveCancelFail") +
            "<br>" +
            e.message
          );
          this.operationMessage = e.message;
        } else {
          this.errorToast(
            this.$t("vanModule.popupMessage.approveCancelFail") + "<br>" + e
          );
          this.operationMessage = e;
        }
      } finally {
        // this.endPay();
      }

      // const selectedRows = this.$refs.cashReceiptGrid.getSelectedRecords();
      // if (selectedRows.length === 0) {
      //   this.errorToast("취소할 현금현금 영수증을 선택해 주세요");
      //   return;
      // }
      //
      // const row = selectedRows[0];
      // const cashReceipt = this.cashReceiptList.find(
      //   (value) => value.id === row.id
      // );
      //
      // const payTransactionRequest = this.getCashReceiptCancelTransactionRequest(
      //   cashReceipt
      // );
      // try {
      //   let isAlreadyCancel = false;
      //   const { status } = await this.payVan(payTransactionRequest);
      //   if (status === "ALREADY_CANCELED") {
      //     isAlreadyCancel = true;
      //   }
      //
      //   try {
      //     await GolfERPService.patchPayCashReceipt(row.id);
      //     if (isAlreadyCancel) {
      //       this.errorToast(this.$t("vanModule.popupMessage.alreadyCanceled"));
      //     } else {
      //       this.errorToast("현금영수증 취소가 완료되었습니다");
      //     }
      //   } catch (error) {
      //     this.errorToast(
      //       this.$t("vanModule.popupMessage.cancelSuccessButServerFail")
      //     );
      //     console.error(error);
      //     return;
      //   }
      //   await this.fetchTgPayCashList();
      // } catch (error) {
      //   if (error instanceof Error) {
      //     this.errorToast(this.$t(error.message));
      //   } else {
      //     this.errorToast(this.$t(error));
      //   }
      //   console.log(error);
      // }
    },
    createPayTransactionRequestByPayCashInfo(payCashInfo) {
      return {
        van: this.van,
        payDivision: "CASH",
        approvalDivision: "CANCEL",
        totalAmount: payCashInfo.approvalAmount,
        taxAmount: payCashInfo.taxAmount,
        vatAmount: payCashInfo.vatAmount,
        notaxAmount: payCashInfo.notaxAmount,
        serviceAmount: payCashInfo.serviceAmount,
        proofKind: payCashInfo.proofKind,
        proofNo: this.approvalInfo.proofNo,
        cardProof: this.approvalInfo.cardProof,
        proofCancelCode: this.cancelInfo.payCashProofCancelCode,
        approvalNo: payCashInfo.approvalNo,
        approvalDateTime: payCashInfo.approvalDateTime,
        storeCode: payCashInfo.storeCode,
        businessId: payCashInfo.businessId,
        posNo: getDeviceInfo().posNo,
        termId: this.bsnInfo.termId,
        payDate: this.readOnlyStringSalesDate,
        visitId: payCashInfo.visitId
      };
    },
    onDialogClose() {
      this.$emit("close");
    },
    startPay() {
      if (this.payProcessing) {
        return;
      }
      this.payProcessing = true;
      this.$refs["dialog"].$el.nextElementSibling.style.zIndex = `${
        parseInt(this.$refs["dialog"].$el.nextElementSibling.style.zIndex) + 1
      }`;
    },
    endPay() {
      if (!this.payProcessing) {
        return;
      }
      this.payProcessing = false;
      this.$refs["dialog"].$el.nextElementSibling.style.zIndex = `${
        parseInt(this.$refs["dialog"].$el.nextElementSibling.style.zIndex) - 1
      }`;
    },
    async onApprovalButtonClick() {
      if (this.approvalInfo.proofNo && 20 < this.approvalInfo.proofNo.length) {
        this.errorToast("증빙번호를 확인해 주시기 바랍니다.");
        return;
      }

      if (this.approvalInfo.payAmt < 1 || 10000000 < this.approvalInfo.payAmt) {
        this.errorToast("승인금액을 확인해 주시기 바랍니다.");
        return;
      }

      try {
        this.$refs.approvalInfoPayAmt.blur();
        // 정산 중일 진행 불가
        if (this.payProcessing) {
          return;
        }
        // 정산 진행중 flag 전환
        this.startPay();

        // 정산 진행 중에 값이 변하더라도 액션중에는 값이 변하지 않도록 DATA COPY
        const payDiv = "CASH";
        const bsnDate = this.readOnlyStringSalesDate;
        const salesDiv = this.approvalInfo.salesDiv;
        // const storeCode = this.storeCode;
        const payCashInfo = Object.assign({}, this.approvalInfo);
        let payAmt = this.approvalInfo.payAmt;

        // 현재 계산할 세금액 계산
        const calculatedTaxInfo = await GolfERPService.fetchDepositIncomeTax("TAX", payAmt);

        const addTransactionRequest = {
          van: this.van,
          payDivision: null,
          approvalDivision: "OK",
          totalAmount: payAmt,
          taxAmount: calculatedTaxInfo.taxAmount,
          vatAmount: calculatedTaxInfo.vatAmount,
          notaxAmount: calculatedTaxInfo.notaxAmount,
          serviceAmount: calculatedTaxInfo.serviceAmount,
          divideTerm: null,
          cardProof: null,
          proofKind: null,
          proofNo: null,
          approvalNo: null,
          approvalDateTime: null,
          cardNo: null,
          purchaseId: null,
          purchaseName: null,
          issueCompanyName: null,
          validTerm: null,
          storeCode: this.store.code,
          businessId: this.bsnInfo.bsnId,
          posNo: getDeviceInfo().posNo,
          termId: this.bsnInfo.termId,
          payDate: bsnDate
        };

        this.operationMessage = "정산 처리되었습니다.";

        this.updateAddTransactionRequestForCashReceipt(
          addTransactionRequest,
          payCashInfo
        );

        let cashReceiptRequest = {};
        try {
          const { tid, status, message } = await this.payVan(
            addTransactionRequest
          );

          if (status === "SELFCANCEL") {
            this.operationMessage = message;
            this.errorToast(message);
            return;
          }

          cashReceiptRequest = {
            tid: tid,
            referenceId: null,
            salesDivision: salesDiv,
            isOnlyCashData: true
          };
        } catch (e) {
          if (e instanceof Error) {
            this.pinnedToast({
              message: this.$t("vanModule.popupMessage.cashApproveFail") +
                "<br><br>" +
                e.message
            });
            this.operationMessage = e.message;
          } else {
            this.pinnedToast({
              message: this.$t("vanModule.popupMessage.cashApproveFail") + "<br><br>" + e
            });
            this.operationMessage = e;
          }

          console.error(e);

          return;
        }

        const cashId = await GolfERPService.postPayCashReceipt(cashReceiptRequest);

        await this.paySuccess(cashId, salesDiv, payDiv);
      } catch (e) {
        if (e instanceof Error) {
          this.operationMessage = e.message;
          console.error(e);
        } else {
          this.operationMessage = e;
        }

        if (this.operationMessage !== "CONFIRM") {
          await this.errorPopup(
            "정산에 실패하였습니다.<br/>" + this.operationMessage
          );
        }
      } finally {
        if (this.payProcessing) {
          this.endPay();
        }
      }

      // try {
      //   const payTransactionRequest = this.getCashReceiptApprovalTransactionRequest();
      //   const { tid } = await this.payVan(payTransactionRequest);
      //   try {
      //     await GolfERPService.postPayCashReceipt({
      //       tid,
      //       salesDivision: this.approvalInfo.salesDiv,
      //     });
      //     this.infoToast("현금영수증 발급이 정상처리 되었습니다");
      //     await this.fetchTgPayCashList();
      //   } catch (error) {
      //     this.$EventBus.$emit("pos-confirm", {
      //       message: "후불자 지정을 취소 하시겠습니까?",
      //       callback: async (confirmed) => {
      //         if (confirmed) {
      //           const approvedPayTransaction = await GolfERPService.getPayTransaction(
      //             tid
      //           );
      //           const cancelTransactionRequest = this.getCancelTransactionRequestByApprovedPayTransaction(
      //             approvedPayTransaction
      //           );
      //           await this.payVan(cancelTransactionRequest);
      //           await this.fetchTgPayCashList();
      //         }
      //       },
      //     });
      //
      //     throw error;
      //   }
      // } catch (error) {
      //   if (error instanceof Error) {
      //     this.errorToast(this.$t(error.message));
      //   } else {
      //     this.errorToast(this.$t(error));
      //   }
      // }
    },
    async paySuccess(id, salesDiv, payDiv) {
      this.endPay();

      if (id) {
        this.print(id, payDiv, salesDiv);
      }

      await this.fetchTgPayCashList();
    },
    updateAddTransactionRequestForCashReceipt(
      addTransactionRequest,
      payCashInfo
    ) {
      addTransactionRequest.cardProof = payCashInfo.cardProof;
      addTransactionRequest.proofKind = payCashInfo.proofKind;
      addTransactionRequest.proofNo = payCashInfo.proofNo;
      addTransactionRequest.payDivision = "CASH";
    },
    async print(id, payDiv, salesDiv, signYN) {
      try {
        if (!this.printFlag) {
          return;
        }

        if (id === null) {
          return;
        }

        // 디바이스 설정의 Bill 프린터 사용여부가 false이면 Bill 프린터 출력 안됨.

        let receiptCode = payDiv.toUpperCase() + "_RECEIPT";


        let printSetData = null;

        await this.getReceiptInfo(id, payDiv);

        printSetData = {
          storeName: commonCodesGetComName(
            "STORE_CODE",
            this.receiptInfo.storeCode
          ),
          bsnDate: this.receiptInfo.payDate,
          lockerNo: this.receiptInfo.lockerNo,
          slipNo: this.receiptInfo.slipNo,
          cardKind: this.receiptInfo.issuCompanyName,
          cardNo: this.receiptInfo.cardNo,
          validTerm: this.receiptInfo.validTerm,
          approvalDt: this.receiptInfo.approvalDt,
          purcName: this.receiptInfo.purcName,
          rePrintDt: null,
          totAmt: this.receiptInfo.approvalAmt,
          taxAmt: this.receiptInfo.taxAmt,
          noTaxAmt: this.receiptInfo.notaxAmt,
          svcAmt:
            this.receiptInfo.svcAmt === "0" ? null : this.receiptInfo.svcAmt,
          vatAmt: this.receiptInfo.vatAmt,
          approvalNo: this.receiptInfo.approvalNo,
          divideTerm: this.receiptInfo.divideTerm,
          merchantNo: this.receiptInfo.merchantNo,
          merchantName: this.receiptInfo.bizName,
          bizNo: this.receiptInfo.bizNo,
          rprsntvName: this.receiptInfo.rprsntvName,
          addr: this.receiptInfo.addr,
          telNo: getFormattedTelNumber(this.receiptInfo.telNo),
          faxNo: getFormattedTelNumber(this.receiptInfo.faxNo),
          proofName: this.receiptInfo.proofName,
          proofNo: this.receiptInfo.proofNo,
          cardTitle:
            this.receiptInfo.approvalDiv === "OK" ||
            this.receiptInfo.approvalDiv === "T_OK"
              ? "매출전표 (신용승인)"
              : "매출전표 (신용취소)",
          cashTitle:
            (this.receiptInfo.approvalDiv === "OK"
              ? "현금승인 "
              : "현금취소 ") +
            (this.receiptInfo.proofName
              ? "(" + this.receiptInfo.proofName + ")"
              : "(미발급)"),
          transTitle:
            (this.receiptInfo.approvalDiv === "OK"
              ? "계좌이체승인 "
              : "계좌이체취소 ") +
            (this.receiptInfo.proofName
              ? "(" + this.receiptInfo.proofName + ")"
              : "(미발급)"),
          signYn: signYN,
        };

        const config = {
          "useBillPrinter": true,
          "useKitchenPrinter": false,
        };
        await BillPrintUtil.getTsConfReceiptInfo.call(this, receiptCode, true, printSetData, config);

      } catch (e) {
        if (e instanceof Error) {
          this.operationMessage += `\n[영수증 발급 에러] ${e.message}`;
        } else {
          this.operationMessage += `\n[영수증 발급 에러] ${e}`;
        }
        await this.errorPopup(
          this.$t("vanModule.popupMessage.printReceiptFail")
        );
      }
    },
    async getReceiptInfo(payId, payDiv) {
      try {
        const {
          value: { receiptInfo },
        } = await getReceiptInfo(payId, payDiv, this.approvalInfo.salesDiv, true);

        if (payDiv === "CASH") {
          receiptInfo.proofName = commonCodesGetComName(
            "PROOF_KIND",
            receiptInfo.proofKind
          );
        }

        this.receiptInfo = receiptInfo;
      } catch (e) {
        console.error("getReceiptInfo.err.===>", e);
        throw e;
      }
    },
    onCloseClick() {
      this.hide();
    },

    async fetchStoreBusinessInfo() {
      const { value: bsnInfo } = await getStoreBsnMstInfo(this.store.code);

      this.bsnInfo = bsnInfo;
      const { vanKind } = bsnInfo;
      this.van = vanKind;
    },
    async payVan(transactionRequest) {
      const { tid, status, message } = await openERPPay(transactionRequest);
      switch (status) {
        case "APPROVED":
        case "CANCELED":
        case "ALREADY_CANCELED":
        case "SELFCANCEL":
          return { tid: tid, status: status, message: message };
        case "READY":
        case "REQUEST":
          throw new Error("결제가 취소되었습니다.");
        case "FAIL":
        case "ROLLBACK":
          throw new Error(message);
      }
    },
    async fetchTgPayCashList() {
      this.cancelInfo.cardProof = false;

      this.cashReceiptList = await GolfERPService.fetchCashReceipts(
        this.cancelSearchOption.payDateFrom,
        this.cancelSearchOption.payDateTo,
        this.cancelSearchOption.approvalNo,
        this.cancelSearchOption.proofNo
      );
    },
  },
};
</script>
