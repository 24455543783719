<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-main">
          <ul class="typePos-list">
            <li>
              <div class="typePos-title">인원</div>
              <div class="typePos-content">
                <input-number-unusual
                  :align="'right'"
                  :prop-max-length="9"
                  v-model="domesticCustomersCount"
                />
              </div>
            </li>
          </ul>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li class="typePos-enrollment">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="SAVE"
                class="ui-button i-typePrimary"
                :use-syncfusion-component-style="false"
                @click.native="onSubmitClick"
              >
                <div class="i-item">등록</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click.native="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {flex: 1;}
.typePos .typePos-wrapper-main .typePos-list {list-style: none; padding: 15px 13px; margin: 0;}
.typePos .typePos-wrapper-main .typePos-list > li {padding: 5px 0;}
.typePos .typePos-wrapper-main .typePos-list > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-title,
.typePos .typePos-wrapper-main .typePos-list > li .typePos-content {padding: 0 7px;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-title {position: relative; float: left; padding-top: 10px; padding-bottom: 11px; color: #222; font-size: 13px; font-family: 'NanumSquare-Regular'; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-title::before {display: none; position: absolute; top: 9px; left: 0; width: 4px; height: 4px; border-radius: 50%; background-color: #df2929; content: '';}
.typePos .typePos-wrapper-main .typePos-list > li.typePos-required .typePos-title::before {display: block;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-content {box-sizing: border-box; width: 314px; float: right;}
.typePos .typePos-wrapper-main .typePos-list > li .typePos-content >>> .ejs-number input {width: 100%;}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-enrollment >>> .ui-button .i-item::before {background-position-x: calc(-20px * 10);}

.typePos.typePos-confirm .typePos-wrapper-footer .typePos-button > li.typePos-cancel {display: none;}
</style>

<script>
import InputNumberUnusual from "@/components/common/InputNumberUnusual";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "SettingTableMemberCount",
  components: {
    InputNumberUnusual,
    ErpButton,
  },
  props: {
    slip: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      domesticCustomersCount: this.slip.domesticCustomersCount || 0,
    };
  },
  computed: {
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: "인원수 등록",
        width: 425,
        height: 218,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
  },
  methods: {
    show() {
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },

    onSubmitClick() {
      this.$emit("submit", {
        domesticCustomersCount: this.domesticCustomersCount,
      });
      this.hide();
    },
    onCloseClick() {
      this.hide();
    },
  },
};
</script>
