<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="dialog"
      v-bind="dialogProps"
      @close="onDialogClose"
    >
      <div
        class="typePos"
        :style="{
          minWidth: `${dialogProps.width}px`,
          minHeight: `${dialogProps.height - 66}px`,
        }"
      >
        <div class="typePos-wrapper-caption">
          <div class="typePos-header">
            <div class="typePos-filed typePos-course">
              <div class="typePos-filed-title">코스</div>
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <f-select
                    class="ui-select"
                    :items="courseCodes"
                    v-model="courseCode"
                  />
                </div>
              </div>
            </div>
            <div class="typePos-filed typePos-division">
              <div class="typePos-filed-title">부구분</div>
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <f-select
                    class="ui-select"
                    :items="partDivisions"
                    v-model="partDiv"
                  />
                </div>
              </div>
            </div>
            <div class="typePos-filed typePos-nameLocker">
              <div class="typePos-filed-title">내장객명/락카</div>
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <div class="ui-input">
                    <!-- 비활성(Attribute) : disabled -->
                    <input
                      type="text"
                      placeholder="입력하세요."
                      v-model="visitNameLockerNo"
                      @keydown.enter="onSearchClick"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="typePos-filed">
              <div class="typePos-filed-content">
                <div class="typePos-item">
                  <ul class="ui-check">
                    <li>
                      <label>
                        <!-- 비활성(Attribute) : disabled -->
                        <input type="checkbox" v-model="isOnlyNotPayingTeams" />
                        <div class="i-item">미정산팀</div>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="typePos-lookup">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="GET"
                class="ui-button i-typePrimary"
                :is-shortcut-button="true"
                :visible-shortcut-string="false"
                :use-syncfusion-component-style="false"
                @click.native="onSearchClick"
              >
                <div class="i-item">
                  조회 ({{ searchShortcutButtonShortcutString }})
                </div>
              </erp-button>
            </div>
            <div class="typePos-footer">
              <div class="typePos-filed">
                <div class="typePos-filed-content">
                  <div class="typePos-item">
                    <div class="typePos-title">내장팀 :</div>
                    <div class="typePos-content">
                      {{ countOfTeams }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="typePos-filed">
                <div class="typePos-filed-content">
                  <div class="typePos-item">
                    <div class="typePos-title">정산팀 :</div>
                    <div class="typePos-content">
                      {{ countOfPayingTeams }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="typePos-filed">
                <div class="typePos-filed-content">
                  <div class="typePos-item">
                    <div class="typePos-title">미정산팀 :</div>
                    <div class="typePos-content">
                      {{ countOfNotPayingTeams }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-main">
          <ejs-grid-wrapper
            ref="teamScheduleGrid"
            v-bind="teamScheduleGridProps"
            @rowSelected="onTeamScheduleGridRowSelected"
            @queryCellInfo="onTeamScheduleGridQueryCellInfo"
          />
        </div>
        <!-- 열기 : typePos-open -->
        <div
          class="typePos-wrapper-aside"
          :class="visibleDetailsLayer ? 'typePos-open' : ''"
        >
          <div class="typePos-header">
            <erp-button
              button-div="CLOSE"
              :use-syncfusion-component-style="false"
              @click.native="onCollapseClick"
            >
              열기 / 닫기
            </erp-button>
          </div>
          <div class="typePos-body">
            <!-- width(Style) : 자식 li의 크기를 모두 더한 값 -->
            <ul
              v-if="selectRecord"
              class="typePos-list"
              :style="{
                width: `${
                  226 * (selectRowVisitors.length + selectRowTeamCaddies.length)
                }px`,
              }"
            >
              <li
                v-for="(person, index) in selectRowPeople"
                v-bind:key="`person-${index}`"
              >
                <div class="typePos-card">
                  <div
                    class="typePos-photo"
                    :style="{
                      backgroundImage: person.imageUrl
                        ? `url('${person.imageUrl}')`
                        : undefined,
                    }"
                  >
                    사진
                  </div>
                  <div class="typePos-name">
                    {{ person.name }}
                    <template v-if="person.memberNumber">
                      ({{ person.memberNumber }})
                    </template>
                  </div>
                  <div class="typePos-content">
                    {{ person.divisionName }}
                    <template v-if="person.corporateDivisionName">
                      / {{ person.corporateDivisionName }}
                    </template>
                    <br />
                    {{ person.corporationName }}
                  </div>
                  <div class="typePos-contact">
                    {{ person.contactNumber }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li class="typePos-check">
              <ul class="ui-check">
                <li>
                  <label>
                    <!-- 비활성(Attribute) : disabled -->
                    <input
                        type="checkbox"
                        v-model="isAuto"
                        :checked="isAuto"
                        :disabled="timeInterval === 0"
                    />
                    <div class="i-item">자동</div>
                  </label>
                </li>
              </ul>
            </li>
            <li class="typePos-check">
              <div class="typePos-filed typePos-interval">
                <div class="typePos-filed-content">
                  <div class="typePos-item">
                    <div class="ui-input">
                      <!-- 비활성(Attribute) : disabled -->
                      <input
                          type="number"
                          v-model="timeInterval"
                          placeholder="초"
                          @keydown.enter="onSearchClick"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click.native="onCloseClick"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex;flex-direction: column;box-sizing: border-box;width: calc(100% + 36px);height: calc(100% + 18px);margin: 0 -18px -18px -18px;border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-caption {padding: 10px;border-bottom: 1px solid #bebebe;}
.typePos .typePos-wrapper-caption::after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
.typePos .typePos-wrapper-caption .typePos-filed,
.typePos .typePos-wrapper-caption .typePos-lookup {float: left;}
.typePos .typePos-wrapper-caption .typePos-filed {padding: 0 3px;}
.typePos .typePos-wrapper-caption .typePos-lookup {padding: 0 10px;}
.typePos .typePos-wrapper-caption .typePos-filed::after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content {float: left;padding: 0 7px;color: #666;font-size: 13px;font-family: "NanumSquare-Regular";line-height: 19px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-title {padding-top: 10px;padding-bottom: 11px;color: #222;font-family: "NanumSquare-Bold";}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content::after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item {float: left;padding-left: 10px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item:first-child {padding-left: 0;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item::after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title,
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content {float: left;padding-top: 10px;padding-bottom: 11px;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-title {padding-right: 4px;color: #222;font-family: "NanumSquare-Bold";}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content strong {color: #18b3c9;font-weight: normal;}
.typePos .typePos-wrapper-caption .typePos-filed .typePos-filed-content .typePos-item .typePos-content em {color: #df2929;font-style: normal;}
.typePos .typePos-wrapper-caption .typePos-lookup >>> .ui-button .i-item::before {background-position-x: calc(-20px * 7);}
.typePos .typePos-wrapper-caption .typePos-header {float: left;}
.typePos .typePos-wrapper-caption .typePos-header::after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
.typePos .typePos-filed.typePos-interval .typePos-filed-content .typePos-item .ui-input {width: 80px;}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-course .typePos-filed-content .typePos-item .ui-select {width: 120px;}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-division .typePos-filed-content .typePos-item .ui-select {width: 120px;}
.typePos .typePos-wrapper-caption .typePos-header .typePos-filed.typePos-nameLocker .typePos-filed-content .typePos-item .ui-input {width: 140px;}
.typePos .typePos-wrapper-caption .typePos-footer {float: right;}
.typePos .typePos-wrapper-caption .typePos-footer::after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
.typePos .typePos-wrapper-main {flex: 1;overflow: hidden;}
.typePos .typePos-wrapper-aside {position: relative;padding-top: 21px;border-top: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-aside::before {display: block;position: absolute;top: 20px;left: 0;width: 100%;height: 1px;background-color: #bebebe;content: "";}
.typePos .typePos-wrapper-aside .typePos-header {position: absolute;top: 6px;left: 50%;margin-left: -7px;}
.typePos .typePos-wrapper-aside .typePos-header >>> button {box-shadow: none;position: relative;overflow: hidden;width: 41px;height: 15px;padding: 0;margin: 0;border: none;background: transparent url("../../../../../assets/images/ui/arrow-type07.png") no-repeat left top;text-indent: -1000px;}
.typePos .typePos-wrapper-aside .typePos-header >>> button:hover {background-position-y: bottom;}
.typePos .typePos-wrapper-aside .typePos-body {box-sizing: border-box;position: relative;min-height: 20px;overflow: auto;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list {display: none;list-style: none;padding: 20px 15px;margin: 0;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list::after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li {float: left;padding: 0 5px;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-card {overflow: hidden;position: relative;box-sizing: border-box;width: 216px;height: 97px;border: 1px solid #e0e0e0;border-radius: 4px;background-color: #f5f5f5;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-card .typePos-photo {overflow: hidden;box-sizing: border-box;position: absolute;top: 10px;left: 10px;width: 56px;height: 75px;border: 1px solid #e0e0e0;border-radius: 4px;background-color: #fff;background-repeat: no-repeat;background-position: center center;background-size: cover;text-indent: -1000px;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-card .typePos-name,
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-card .typePos-content,
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-card .typePos-contact {position: absolute;left: 81px;color: #666;font-size: 12px;font-family: "NanumSquare-Regular";line-height: 18px;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-card .typePos-name {top: 13px;color: #222;font-family: "NanumSquare-Bold";}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-card .typePos-content {top: 31px;}
.typePos .typePos-wrapper-aside .typePos-body .typePos-list > li .typePos-card .typePos-contact {bottom: 10px;}
.typePos .typePos-wrapper-aside.typePos-open .typePos-header >>> button {background-position-x: right;}
.typePos .typePos-wrapper-aside.typePos-open .typePos-body {min-height: 137px;}
.typePos .typePos-wrapper-aside.typePos-open .typePos-body .typePos-list {display: block;}
.typePos .typePos-wrapper-footer {padding: 15px;border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
.typePos .typePos-wrapper-footer .typePos-button {float: right;list-style: none;padding: 0;margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block;clear: both;height: 0;font-size: 0;line-height: 0;content: "";}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left;padding: 0 5px;}
</style>

<script>
import moment from "moment";
import GolfERPService from "@/service/GolfERPService";
import { DATE_FORMAT_YYYY_MM_DD } from "@/utils/date";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { sortBy as _sortBy, orderBy as _orderBy } from "lodash";
import { TIME_FORMAT_hh_mm } from "@/utils/time";
import {
  commonCodesGetCommonCode, commonCodesGetCommonCodeAttrbByCodeAndIdx,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { ForeignKey } from "@syncfusion/ej2-vue-grids";
import FSelect from "@/views/store-management/sales-registration/store-pos-renew/components/FSelect";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  getSearchButtonShortcut,
  toShortcutString,
} from "@/utils/KeyboardUtil";

export default {
  name: "TeamOrganizationPopup",
  components: { FSelect, EjsGridWrapper, ErpButton },
  props: {
    salesDate: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      courseCode: null,
      partDiv: null,
      isAuto: true,
      timeInterval: 30,
      intervalHandler: null,
      visitNameLockerNo: "",
      isOnlyNotPayingTeams: false,
      teams: [],
      visibleDetailsLayer: false,
      selectRecord: null,
    };
  },
  watch: {
    isAuto() {
      if (this.isAuto && this.timeInterval !== 0) {
        this.intervalHandler = setInterval(() => {
          this.fetchTeams();
        }, this.timeInterval * 1000);
      } else {
        clearInterval(this.intervalHandler);
      }
    },
    timeInterval() {
      if (this.timeInterval === 0) {
        this.isAuto = false;
      }
    },
  },
  computed: {
    isTablet() {
      return commonCodesGetCommonCodeAttrbByCodeAndIdx('PC_USE_CODE ',(localStorage.getItem('pcUseCode')),1) === 'TABLET';
    },
    salesDateLocaleString() {
      const salesDateMoment = moment(this.salesDate);
      return salesDateMoment.format(
        `YYYY/MM/DD (${this.toLocaleStringDayOfWeek(
          salesDateMoment.isoWeekday()
        )})`
      );
    },
    searchShortcutButtonShortcutString() {
      return toShortcutString(getSearchButtonShortcut());
    },
    dialogProps() {
      const dialogProps = {
        enableResize: true,
        header: `팀 편성표 ${this.salesDateLocaleString}`,
        width: this.isTablet ? 1200 : 1400,
        height: 760,
        allowDragging: true,
        animationSettings: { effect: "None" },
        isModal: true,
        position: { x: "center", y: "center" },
        visible: false,
        showCloseIcon: true,
      };

      if (!dialogProps.height) {
        if (!dialogProps.cssClass) {
          dialogProps.cssClass = "dialog-non-height";
        } else {
          dialogProps.cssClass = `${dialogProps.cssClass} dialog-non-height`;
        }
      }

      return dialogProps;
    },
    countOfTeams() {
      return this.teams?.length || 0;
    },
    countOfPayingTeams() {
      return this.teams?.filter(({ isCheckOut }) => !!isCheckOut).length || 0;
    },
    countOfNotPayingTeams() {
      return this.teams?.filter(({ isCheckOut }) => !isCheckOut).length || 0;
    },
    courseCodes() {
      const courseCodes = _sortBy(
        commonCodesGetCommonCode("COURSE_CODE", true).map((commonCode) => ({
          ...commonCode,
          name: commonCode.comName,
          value: commonCode.comCode,
        })),
        "sortNo"
      );

      courseCodes.unshift({
        name: "전체",
        value: null,
      });

      return courseCodes;
    },
    partDivisions() {
      const partDivisions = _sortBy(
        commonCodesGetCommonCode("PART_DIV", true).map((commonCode) => ({
          ...commonCode,
          name: commonCode.comName,
          value: commonCode.comCode,
        })),
        "sortNo"
      );

      partDivisions.unshift({
        name: "전체",
        value: null,
      });

      return partDivisions;
    },
    teamScheduleGridProps() {
      const dataSource =
        this.teams
          ?.filter(({ isPaying }) =>
            this.isOnlyNotPayingTeams ? !isPaying : true
          )
          .map((team, index) => {
            const visitors = [];
            const originVisitors = team.visitors || [];
            for (let n = 1; n <= 5; n++) {
              visitors.push({
                visitId: originVisitors[n - 1]?.id,
                visitName: originVisitors[n - 1]?.name,
                visitLockerNo: originVisitors[n - 1]?.lockerNumber,
                visitMemberDiv: originVisitors[n - 1]?.memberDivision,
                visitSexCode: originVisitors[n - 1]?.gender,
                visitOrder: originVisitors[n - 1]?.order,
              });
            }

            const orderedVisitors = _sortBy(visitors, "visitOrder");
            return {
              ...team,
              _rid: index + 1,
              visitors: _sortBy(originVisitors, "order"),
              reserveName: team.reserveName || "",
              caddieName:
                team?.teamCaddies
                  ?.map(({ caddie: { name } }) => name)
                  .join(", ") || "",
              visitName1: orderedVisitors[0]?.visitName ? orderedVisitors[0]?.visitName + (orderedVisitors[0]?.visitLockerNo ? "(" + orderedVisitors[0]?.visitLockerNo + ")" : "") : "" || "",
              visitName2: orderedVisitors[1]?.visitName ? orderedVisitors[1]?.visitName + (orderedVisitors[1]?.visitLockerNo ? "(" + orderedVisitors[1]?.visitLockerNo + ")" : "") : "" || "",
              visitName3: orderedVisitors[2]?.visitName ? orderedVisitors[2]?.visitName + (orderedVisitors[2]?.visitLockerNo ? "(" + orderedVisitors[2]?.visitLockerNo + ")" : "") : "" || "",
              visitName4: orderedVisitors[3]?.visitName ? orderedVisitors[3]?.visitName + (orderedVisitors[3]?.visitLockerNo ? "(" + orderedVisitors[3]?.visitLockerNo + ")" : "") : "" || "",
              visitName5: orderedVisitors[4]?.visitName ? orderedVisitors[4]?.visitName + (orderedVisitors[4]?.visitLockerNo ? "(" + orderedVisitors[4]?.visitLockerNo + ")" : "") : "" || "",
              checkOutTime: !team.checkOutDateTime
                ? ""
                : moment(team.checkOutDateTime).format(TIME_FORMAT_hh_mm),
            };
          }) || [];

      return {
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: [
          {
            field: "time",
            headerText: "시간",
            textAlign: "Center",
            type: "string",
            width: 104,
          },
          {
            groupCode: "COURSE_CODE",
            field: "course",
            isCommonCodeField: true,
            headerText: "코스",
            textAlign: "Center",
            type: "string",
            width: 104,
          },
          {
            field: "reserveName",
            headerText: "예약자",
            textAlign: "Left",
            type: "string",
            width: 115,
          },
          {
            field: "recomendr",
            headerText: "추천인",
            textAlign: "Left",
            type: "string",
            width: 115,
          },
          {
            field: "visitName1",
            headerText: "내장객1",
            textAlign: "Left",
            type: "string",
            width: 122,
          },
          {
            field: "visitName2",
            headerText: "내장객2",
            textAlign: "Left",
            type: "string",
            width: 122,
          },
          {
            field: "visitName3",
            headerText: "내장객3",
            textAlign: "Left",
            type: "string",
            width: 122,
          },
          {
            field: "visitName4",
            headerText: "내장객4",
            textAlign: "Left",
            type: "string",
            width: 122,
          },
          {
            field: "visitName5",
            headerText: "내장객5",
            textAlign: "Left",
            type: "string",
            width: 122,
          },
          {
            field: "checkOutTime",
            headerText: "C/O",
            textAlign: "Center",
            type: "string",
            width: 100,
          },
          {
            field: "groupName",
            headerText: "단체명",
            textAlign: "Left",
            type: "string",
            width: 115,
          },
          {
            field: "caddieName",
            headerText: "캐디",
            textAlign: "Left",
            type: "string",
            width: 104,
          },
        ],
        dataSource,
        isInPopup: true,
        provides: [ForeignKey],
        selectionSettings: { type: "Single", enableToggle: false },
        useCommonStyles: false,
      };
    },
    selectRowVisitors() {
      return this.selectRecord?.visitors || [];
    },
    selectRowTeamCaddies() {
      return this.selectRecord?.teamCaddies || [];
    },
    selectRowPeople() {
      return this.selectRowVisitors
        .map(({ name, membership, memberDivision, contactNumber }) => ({
          imageUrl: membership?.member?.imageFile?.path,
          name,
          memberNumber: membership?.memberNumber,
          divisionName: this.getMemberDivisionName(memberDivision),
          corporateDivisionName: this.getCorporateDivisionName(
            membership?.corporateDivision
          ),
          contactNumber,
        }))
        .concat(
          this.selectRowTeamCaddies.map(
            ({ caddie: { name, imageFile }, mobilePhoneNumber }) => ({
              imageUrl: imageFile?.path,
              name,
              divisionName: "캐디",
              contactNumber: mobilePhoneNumber,
            })
          )
        );
    },
  },
  methods: {
    show() {
      this.fetchTeams();
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },

    toLocaleStringDayOfWeek(dayOfWeek) {
      switch (dayOfWeek) {
        case 1:
          return "월";
        case 2:
          return "화";
        case 3:
          return "수";
        case 4:
          return "목";
        case 5:
          return "금";
        case 6:
          return "토";
        case 7:
          return "일";
      }

      return "";
    },
    getMemberDivisionName(memberDivision) {
      return commonCodesGetComName("MEMBER_DIV", memberDivision) || "";
    },
    getCorporateDivisionName(corporateDivision) {
      return commonCodesGetComName("CPR_DIV", corporateDivision) || "";
    },

    onDialogClose() {
      this.$emit("close");
    },
    onSearchClick() {
      this.fetchTeams();
    },
    onTeamScheduleGridRowSelected({ data }) {
      this.selectRecord = data;
    },
    onCollapseClick() {
      this.visibleDetailsLayer = !this.visibleDetailsLayer;
    },
    onCloseClick() {
      this.hide();
    },

    // API
    async fetchTeams() {
      this.teams =
        _orderBy((await GolfERPService.fetchTeams(
          moment(this.salesDate).format(DATE_FORMAT_YYYY_MM_DD),
          undefined,
          this.visitNameLockerNo || undefined,
          undefined,
          this.courseCode || undefined,
          this.partDiv || undefined
        )) || [], ["time", "course"]);

      this.selectRow = null;
    },
    onTeamScheduleGridQueryCellInfo(args) {
      const {
        cell,
        column: { field },
        data,
      } = args;
      // const value = data[field];

      if (field === "reserveName") {
        if (data.isGalleryTeam) {
          cell.style.color = "#FF0000";
        }
      }
    }
  },
};
</script>
