<template>
  <div>
    <ejs-dialog
      ref="depositUseReceiptPopup"
      header="선수금 내역서"
      :allowDragging="true"
      :showCloseIcon="true"
      width="425"
      height="760"
      :animationSettings="{ effect: 'None' }"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @close="onDepositUseReceiptPopupClosed"
    >
      <div class="typePos" style="min-width: 425px; min-height: 694px">
        <div class="typePos-wrapper-main">
          <div class="typePos-wrap">
            <div class="typePos-box">
              <div class="typePos-header">선수금 내역서</div>
              <ul class="typePos-aside">
                <li>
                  <div class="typePos-title">발행일자</div>
                  <div class="typePos-content">
                    {{ depositUseReceipt.printDt }}
                  </div>
                </li>
              </ul>
              <div class="typePos-body">
                <ul class="typePos-list typePos-first">
                  <li>
                    <div class="typePos-name">입금자명</div>
                    <div
                      style="
                        float: left;
                        width: 40%;
                        color: #222;
                        font-size: 12px;
                        font-family: '돋움', Dotum;
                        line-height: 19px;
                      "
                    >
                      {{ depositUseReceipt.memberName }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">연락처</div>
                    <div class="typePos-content">
                      {{ getFormattedTelNumber(depositUseReceipt.contactTel) }}
                    </div>
                  </li>
                </ul>
                <ul class="typePos-list">
                  <li>
                    <div class="typePos-name">선수금액</div>
                    <div class="typePos-price">
                      {{ depositUseReceipt.depositAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">누적사용</div>
                    <div class="typePos-price">
                      {{ depositUseReceipt.sumUseAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">사용금액</div>
                    <div class="typePos-price">
                      {{ depositUseReceipt.useAmt | numberWithCommas }}
                    </div>
                  </li>
                  <li>
                    <div class="typePos-name">잔여금액</div>
                    <div class="typePos-price">
                      {{ depositUseReceipt.balanceAmt | numberWithCommas }}
                    </div>
                  </li>
                </ul>
                <ul class="typePos-list">
                  <li>
                    <div
                      style="
                        box-sizing: border-box;
                        float: left;
                        width: 70px;
                        text-align: center;
                        font-family: '돋움', Dotum;
                      "
                    >
                      사용일자
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        float: left;
                        width: 70px;
                        padding-left: 6px;
                        text-align: center;
                        font-family: '돋움', Dotum;
                      "
                    >
                      출발시간
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        float: left;
                        width: 60px;
                        padding-left: 6px;
                        text-align: right;
                        font-family: '돋움', Dotum;
                      "
                    >
                      사용금액
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        float: left;
                        width: 80px;
                        padding-left: 6px;
                        text-align: right;
                        font-family: '돋움', Dotum;
                      "
                    >
                      사용구분
                    </div>
                  </li>
                </ul>
                <ul class="typePos-list">
                  <li
                    v-for="item in depositUseReceipt.depositUseList"
                    :key="item.useId"
                  >
                    <div
                      style="
                        box-sizing: border-box;
                        float: left;
                        width: 70px;
                        text-align: center;
                        font-family: '돋움', Dotum;
                      "
                    >
                      {{ item.useDate }}
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        float: left;
                        width: 70px;
                        padding-left: 6px;
                        text-align: center;
                        font-family: '돋움', Dotum;
                      "
                      :style="!item.startTime ? 'min-height: 15px;' : ''"
                    >
                      {{ item.startTime }}
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        float: left;
                        width: 60px;
                        padding-left: 6px;
                        text-align: right;
                        font-family: '돋움', Dotum;
                      "
                    >
                      {{ item.useAmt | numberWithCommas }}
                    </div>
                    <div
                      style="
                        box-sizing: border-box;
                        float: left;
                        width: 80px;
                        padding-left: 6px;
                        text-align: right;
                        font-family: '돋움', Dotum;
                      "
                    >
                      {{
                        commonCodesGetComName(
                          "DEPOSIT_USE_DIV",
                          item.depositUseDiv
                        )
                      }}
                    </div>
                  </li>
                  <li style="position: relative; padding: 17px 0 0 0">
                    <div class="typePos-name">합계</div>
                    <div class="typePos-price">
                      {{ depositUseReceipt.totalUseAmt | numberWithCommas }}
                    </div>
                  </li>
                </ul>
              </div>
              <ul class="typePos-aside">
                <li>
                  <div
                    style="
                      box-sizing: border-box;
                      float: left;
                      width: 100%;
                      padding-right: 6px;
                      font-family: '돋움', Dotum;
                    "
                  >
                    찾아주셔서 감사합니다.
                  </div>
                </li>
                <li>
                  <div
                    style="
                      position: relative;
                      border-bottom: 0px;
                      padding: 17px 0 1px 0;
                    "
                  >
                    <div>
                      <div
                        style="
                          box-sizing: border-box;
                          float: left;
                          width: 50%;
                          padding-right: 6px;
                          font-family: '돋움', Dotum;
                        "
                      >
                        {{ bizMstInfo.ccName }}
                      </div>
                      <div
                        style="
                          box-sizing: border-box;
                          float: right;
                          width: 50%;
                          padding-left: 6px;
                          text-align: left;
                          font-family: '돋움', Dotum;
                        "
                      >
                        {{ bizMstInfo.bizNo }}
                      </div>
                      <div style="clear: both; height: 0"></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    style="
                      position: relative;
                      border-bottom: 0px;
                      padding: 1px 0 1px 0;
                    "
                  >
                    <div>
                      <div
                        style="
                          box-sizing: border-box;
                          float: left;
                          width: 50%;
                          padding-right: 6px;
                          font-family: '돋움', Dotum;
                        "
                      >
                        대표자
                      </div>
                      <div
                        style="
                          box-sizing: border-box;
                          float: right;
                          width: 50%;
                          padding-left: 6px;
                          text-align: left;
                          font-family: '돋움', Dotum;
                        "
                      >
                        {{ bizMstInfo.rprsntvName }}
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    style="
                      position: relative;
                      border-bottom: 0px;
                      padding: 9px 0 8px 0;
                    "
                  >
                    <div>
                      <div
                        style="
                          box-sizing: border-box;
                          float: left;
                          width: 50%;
                          padding-right: 6px;
                          font-family: '돋움', Dotum;
                        "
                      >
                        TEL {{ getFormattedTelNumber(bizMstInfo.telNo, ")") }}
                      </div>
                      <div
                        style="
                          box-sizing: border-box;
                          float: right;
                          width: 50%;
                          padding-left: 6px;
                          text-align: left;
                          font-family: '돋움', Dotum;
                        "
                      >
                        FAX {{ getFormattedTelNumber(bizMstInfo.faxNo, ")") }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="typePos-wrapper-footer">
          <ul class="typePos-button">
            <li class="typePos-print">
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="PRINT"
                class="ui-button i-typePrimary"
                :use-syncfusion-component-style="false"
                @click="print"
              >
                <div class="i-item">출력</div>
              </erp-button>
            </li>
            <li>
              <!-- 비활성(Attribute) : disabled -->
              <erp-button
                button-div="CLOSE"
                class="ui-button i-iconClose"
                :use-syncfusion-component-style="false"
                @click="closeDepositUseReceiptPopup"
              >
                <div class="i-item">닫기</div>
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.typePos {display: flex; flex-direction: column; box-sizing: border-box; width: calc(100% + 36px); height: calc(100% + 18px); margin: 0 -18px -18px -18px; border-top: 1px solid #bebebe;}

.typePos .typePos-wrapper-main {overflow: hidden; flex: 1; box-sizing: border-box; padding: 20px;}
.typePos .typePos-wrapper-main .typePos-wrap {overflow: auto; height: 100%; border: 1px solid #e0e0e0;}
.typePos .typePos-wrapper-main .typePos-box {padding: 16px 20px 20px 20px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-header {color: #222; font-size: 16px; font-family: '돋움', Dotum; font-weight: bold; line-height: 24px; text-align: center;}
.typePos .typePos-wrapper-main .typePos-box .typePos-caption {padding-top: 12px; margin-bottom: -5px; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-aside {list-style: none; padding: 12px 0 10px 0; margin: 0;}
.typePos .typePos-wrapper-main .typePos-box .typePos-aside > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-box .typePos-aside > li .typePos-title {float: left; width: 60%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-aside > li .typePos-content {float: left; width: 40%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body {border-top: 1px solid #222; border-bottom: 1px solid #222;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list {list-style: none; padding: 12px 0 10px 0; margin: 0; border-top: 1px dashed #222;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list.typePos-first {border-top: none;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list > li::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list > li .typePos-name {float: left; width: 60%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list > li .typePos-count {float: left; width: 20%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px; text-align: right;}
.typePos .typePos-wrapper-main .typePos-box .typePos-body .typePos-list > li .typePos-price {float: right; width: 20%; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px; text-align: right;}
.typePos .typePos-wrapper-main .typePos-box .typePos-footer {margin-top: -3px; color: #222; font-size: 12px; font-family: '돋움', Dotum; line-height: 19px;}
.typePos .typePos-wrapper-main .typePos-box .typePos-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}

.typePos .typePos-wrapper-footer {padding: 15px; border-top: 1px solid #bebebe;}
.typePos .typePos-wrapper-footer::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button {float: right; list-style: none; padding: 0; margin: 0;}
.typePos .typePos-wrapper-footer .typePos-button::after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
.typePos .typePos-wrapper-footer .typePos-button > li {float: left; padding: 0 5px;}
.typePos .typePos-wrapper-footer .typePos-button > li.typePos-print .ui-button .i-item::before {background-position-x: calc(-20px * 11);}
</style>

<script>
import {
  commonCodesGetComName,
  commonCodesGetBizMstInfo,
} from "@/utils/commonCodes";
import BillPrintUtil from "@/utils/billPrintUtil";
import { getFormattedTelNumber } from "@/utils/string";
import { numberWithCommas } from "@/utils/number";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "DepositUseReceiptPopup",
  mixins: [confirmDialogMixin],
  components: {
    ErpButton,
  },
  data: function () {
    return {
      depositUseReceipt: {
        useId: null,
        depositId: null,
        cardId: null,
        printDt: null,
        cardNo: null,
        depositAmt: null,
        sumUseAmt: null,
        useAmt: null,
        totalUseAmt: null,
        balanceAmt: null,
        useDate: null,
        startCourse: null,
        startTime: null,
        depositUseDiv: null,
        depositUseList: [],
        memberName: null,
        contactTel: null,
      },
      bizMstInfo: {},
    };
  },
  filters: {
    numberWithCommas,
  },
  created: function () {
    this.bizMstInfo = commonCodesGetBizMstInfo();

    this.bizMstInfo.bizNo =
      this.bizMstInfo.bizNo.substr(0, 3) +
      "-" +
      this.bizMstInfo.bizNo.substr(3, 2) +
      "-" +
      this.bizMstInfo.bizNo.substring(5);
  },
  methods: {
    commonCodesGetComName: commonCodesGetComName,
    getFormattedTelNumber: getFormattedTelNumber,
    async showDepositUseReceiptPopup(popupData) {
      const useId = popupData ? popupData : null;

      await this.fetchDepositUseReceipt(useId);
    },
    onDepositUseReceiptPopupClosed() {
      this.$emit("close");
    },
    closeDepositUseReceiptPopup: function () {
      this.$refs.depositUseReceiptPopup.hide();
    },
    async fetchDepositUseReceipt(useId) {
      this.depositUseReceipt = await GolfErpAPI.fetchDepositUseReceipt(useId);
    },
    async print() {

      let depositUseList = JSON.parse(
        JSON.stringify(this.depositUseReceipt.depositUseList)
      );

      depositUseList.forEach((data) => {
        data.depositUseDiv =
          data.depositUseDiv === undefined || data.depositUseDiv === null
            ? ""
            : commonCodesGetComName("DEPOSIT_USE_DIV", data.depositUseDiv);
      });

      const printSetData = {
        printDt: this.depositUseReceipt.printDt,
        cardNo:
          this.depositUseReceipt.cardNo === undefined ||
          this.depositUseReceipt.cardNo === null
            ? ""
            : this.depositUseReceipt.cardNo,
        depositAmt: this.depositUseReceipt.depositAmt,
        sumUseAmt: this.depositUseReceipt.sumUseAmt,
        useAmt: this.depositUseReceipt.useAmt,
        balanceAmt: this.depositUseReceipt.balanceAmt,
        totalUseAmt: this.depositUseReceipt.totalUseAmt,
        useDate: this.depositUseReceipt.useDate,
        ccName: this.bizMstInfo.ccName,
        bizNo: this.bizMstInfo.bizNo,
        rprsntvName: this.bizMstInfo.rprsntvName,
        telNo: getFormattedTelNumber(this.bizMstInfo.telNo, ")"),
        faxNo: getFormattedTelNumber(this.bizMstInfo.faxNo, ")"),
        depositUseList: depositUseList,
        memberName: this.depositUseReceipt.memberName,
        contactTel: getFormattedTelNumber(this.depositUseReceipt.contactTel),
      };
      const config = {
        "useBillPrinter": true,
        "useKitchenPrinter": false,
      };
      await BillPrintUtil.getTsConfReceiptInfo.call(this, "DEPOSIT_RECEIPT", true, printSetData, config);

    },
  },
};
</script>
